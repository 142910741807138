import React from "react";

import {
  LoginBackground,
  ElevatedLoginContainer,
  ElevatedContainerHeaderTitle,
  ElevatedContainerText1,
  ElevatedContainerText2,
  FooterButton,
  ElevatedContainerPrimaryButton,
  BorderLinearProgress,
} from "./components/LoginBackground";

import OtpField from "./components/otpField";
import { compose } from "redux";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import { subscribeToUserLogin } from "./states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import {
  REQUEST_KEY_USER_LOGIN,
  requestVerifyOtp,
  requestLoginOtp,
} from "./states/actions";
import { connect } from "react-redux";
import AlertDialog from "../../components/AlertDialog";
import RequestAction from "../../common/states/actions.request";
import withSplashScreenNavigation from "../../components/hoc/withSplashScreenNavigation";
import OtpTimer from "./components/OtpTimer";
import { createVenuePath } from "../../routes";
import FirebaseCloudMessaging from "../../data/sources/notifications/FirebaseCloudMessaging";
import { subscribeToUser } from "../venueSelection/states/reducers";
import spectator from "../../data/sources/analytics/spectator";
import { getOS } from "../../common/utils";

const createPhoneNumberMessage = (props) => {
  const {
    login: {
      phone: { phone_number },
    },
  } = props;

  return `on ${phone_number.slice(0, 5)}-${phone_number.slice(5)}`;
};

const createWelcomMessage = (props) => {
  const {
    login: { name = "User" },
  } = props;

  return `Welcome Back ${name}!`;
};

class LoginOtp extends React.Component {
  initialState = {
    signUpButtonEnabled: false,
    isLoading: false,
    dialogErrorMessage: "",
    isErrorDialogVisible: false,
    otpValue: "",
    initializeOtpField: false,
    resetTimer: false,
    timerFinished: false,
    deviceToken: undefined,
  };

  constructor(props) {
    super(props);

    this.digits = 6;

    const {
      login: { otp },
    } = props;

    this.state = {
      ...this.initialState,
      otpValue: otp,
    };

    this.onFormSubmitted = this.onFormSubmitted.bind(this);
    this.onOtpChanged = this.onOtpChanged.bind(this);
    this.onBackPressed = this.onBackPressed.bind(this);
    this.onResendOtp = this.onResendOtp.bind(this);
  }

  componentDidMount() {
    const {
      login: {
        phone: { phone_number: phoneNumber },
      },
      replacePath,
    } = this.props;

    if (phoneNumber === undefined || phoneNumber === "") {
      replacePath("/");
    }

    FirebaseCloudMessaging.getDeviceRegistrationToken().then((token) => {
      this.setState({ deviceToken: token });
    });
  }

  processApiError(props) {
    const {
      api: {
        error: { message: errorMessage },
      },
    } = props;

    this.setState({
      isLoading: false,
      dialogErrorMessage: errorMessage,
      isErrorDialogVisible: true,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      api: { request_state: newRequestState },
    } = nextProps;

    const {
      api: { request_state: oldRequestState },
    } = this.props;

    const { timerFinished: timerFinishedNew } = nextState;

    if (
      newRequestState === RequestAction.STATE.LOADING &&
      oldRequestState !== RequestAction.STATE.LOADING
    ) {
      this.setState({ isLoading: true });
      return true;
    }

    if (
      oldRequestState === RequestAction.STATE.LOADING &&
      newRequestState === RequestAction.STATE.ERROR
    ) {
      this.processApiError(nextProps);
      return true;
    }

    if (
      oldRequestState === RequestAction.STATE.LOADING &&
      newRequestState === RequestAction.STATE.STAND_BY
    ) {
      if (timerFinishedNew === true) {
        // This is a resendOtp request, don't navigate anywhere.
        // Just reset the resetTimer Flag.
        this.setState({
          resetTimer: true,
          isLoading: false,
          timerFinished: false,
        });
        return false;
      }
      this.setState({ isLoading: false });
      const { replacePath } = this.props;
      const venueRoute = createVenuePath(this.props);
      this.logEvent(this.props);
      replacePath(`${venueRoute}/dashboard`);
      return false;
    }

    return true;
  }

  logEvent(props) {
    const {
      user: {
        logged_in_user: { phone_number: phoneNumber },
      },
    } = props;

    spectator.logEvent("user_log_in", {
      logged_in_date: Date(),
    });
    spectator.addUserProperty("phone_number", phoneNumber);
    spectator.setUserId(phoneNumber);
  }

  onOtpChanged(newOtp) {
    this.setState({
      otpValue: newOtp,
      signUpButtonEnabled: newOtp.length === this.digits,
      initializeOtpField: false,
    });
  }

  onFormSubmitted() {
    const {
      login: {
        phone: { phone_number: phoneNumber, country_code: countryCode },
      },
      requestVerifyOtp,
    } = this.props;

    const { otpValue: otp } = this.state;

    if (otp.length !== this.digits) {
      return;
    }

    requestVerifyOtp({
      phoneNumber: phoneNumber,
      countryCode: countryCode,
      deviceToken: this.state.deviceToken,
      otp: otp,
    });
  }

  handleClose = () => {
    this.setState({
      isErrorDialogVisible: false,
      otpValue: this.initialState.otpValue,
      dialogErrorMessage: this.initialState.dialogErrorMessage,
      initializeOtpField: true,
    });
  };

  onBackPressed() {
    const { isLoading } = this.state;
    const { replacePath } = this.props;

    if (!isLoading) {
      replacePath("/login");
    }
  }

  onResendOtp() {
    const { requestLoginOtp } = this.props;
    const {
      login: {
        phone: { phone_number: phoneNumber, country_code: countryCode },
      },
    } = this.props;

    requestLoginOtp({ countryCode: countryCode, phoneNumber: phoneNumber });
  }

  render() {
    const {
      otpValue,
      isLoading,
      initializeOtpField,
      signUpButtonEnabled,
      resetTimer,
      timerFinished,
    } = this.state;

    return (
      <div>
        <LoginBackground>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "-70px",
            }}
          >
            <ElevatedLoginContainer>
              <ElevatedContainerHeaderTitle
                value={createWelcomMessage(this.props)}
              />
              <div
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  marginRight: "20px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ElevatedContainerText1 value="We have sent a “6 digit Code”" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ElevatedContainerText1
                    value={createPhoneNumberMessage(this.props)}
                  />
                  <FooterButton
                    value="Change"
                    disabled={isLoading}
                    onClick={this.onBackPressed}
                  />
                </div>
                <div style={{ marginTop: "20px", marginBottom: "24px" }}>
                  <OtpField
                    digits={this.digits}
                    initialOtp={otpValue}
                    initialize={initializeOtpField}
                    updateOtpCallback={this.onOtpChanged}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <OtpTimer
                    reset={resetTimer}
                    startTimeInSeconds={120}
                    startWhenReady={true}
                    onTimerFinished={() => {
                      this.setState({ timerFinished: true, resetTimer: false });
                    }}
                  />
                  {/* {timerFinished ? (
                    <ElevatedContainerText2 value={`   .`} />
                  ) : null} */}
                  {timerFinished ? (
                    <FooterButton
                      value="Resend"
                      disabled={isLoading}
                      onClick={this.onResendOtp}
                    />
                  ) : null}
                </div>
              </div>

              <ElevatedContainerPrimaryButton
                disabled={!signUpButtonEnabled || isLoading}
                value={"Sign in"}
                onPrimaryButtonClicked={this.onFormSubmitted}
              />

              {isLoading ? <BorderLinearProgress /> : false}
            </ElevatedLoginContainer>
          </div>
        </LoginBackground>
        <AlertDialog
          title={"Error"}
          description={this.state.dialogErrorMessage}
          open={this.state.isErrorDialogVisible}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  login: subscribeToUserLogin(state),
  api: subscribeToRequestState(state, REQUEST_KEY_USER_LOGIN),
});

const mapDispatchToProps = (dispatch) => ({
  requestVerifyOtp: (params) => dispatch(requestVerifyOtp({ ...params })),
  requestLoginOtp: (params) => dispatch(requestLoginOtp({ ...params })),
});

const ComposedLoginOtp = compose(withHistoryObject)(LoginOtp);

export default connect(mapStateToProps, mapDispatchToProps)(ComposedLoginOtp);
