import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import CallIcon from '@material-ui/icons/Call';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const getAvatar = (avatar) => {
    switch (avatar) {
      case "document":
        return <DescriptionIcon />;
      case "person":
        return <PersonIcon /> 
      case "call":
        return <CallIcon />
      default:
        return null;
    }
  };

const SimpleDialogListPopup = (props) => {
  const classes = useStyles();
  const { onClose, open, item } = props;
  const {title, avatar, itemList=[], contentText, actonList=[]} = item;

  const handleClose = () => {
    onClose("Na");
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  
  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {title !== undefined && title !== "" ? (<DialogTitle>{title}</DialogTitle>) : null}
      { itemList !== undefined && itemList !== [] ? (
        <List>
            {itemList.map((item, index) => (
            <ListItem button onClick={() => handleListItemClick(item.returnValue ? item.returnValue : item.value )} key={index}>
                <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {getAvatar(avatar)}
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.value} />
            </ListItem>
            ))}
        </List>) : null
       }

       {
           (contentText !== undefined && contentText !== "") ? (
            <DialogContent>
            <DialogContentText>
              {contentText}
            </DialogContentText>
          </DialogContent>
           ) : null
       }

       {
           (actonList !== undefined && actonList !== []) ? (
            <DialogActions>
                {
                    actonList.map((action, index)=>(
                    <Button autoFocus key={index} onClick={()=>{handleListItemClick(action)}} color="primary">{action}</Button>
                    ))
                }
            </DialogActions>
           ) : null
       }


    </Dialog>
  );
}

export default SimpleDialogListPopup;
// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

