import React from "react";
import {
  Typography,
  Button,
  IconButton,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { CoFlatPanel, CoNonElevatedPanel } from "./CoPanel";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: "normal",
      alignSelf: "center",
    },
    actionButtonLabel: {
      textTransform: "capitalize",
      color: "#2298DA",
      fontSize: "16px",
      fontFamily: "Brown-Bold",
      marginTop: "3px",
      lineHeight: "1.2",
    },
    actionButtonLabel2: {
      textTransform: "capitalize",
      color: "#2298DA",
      fontSize: "14px",
      fontFamily: "Brown-Bold",
      lineHeight: "1.2",
    },
  })
);

const styles = {
  listItemButton: {
    margin: "0px",
    color: "#2298DA",
    fontFamily: "Brown-Bold",
    fontSize: "16px",
    textAlign: "center",
  },
  listItemLoading: {
    margin: "0px",
    color: "black",
    fontFamily: "Brown",
    fontSize: "16px",
    textAlign: "start",
  },
};

const SectionTitle = (props) => {
  const { value, classes } = props;

  return (
    <Typography variant="body1" className={classes.title}>
      {value}
    </Typography>
  );
};

export const CoActionButton = (props) => {
  const classes = useStyles();

  const { title, onClick } = props;

  return (
    <Button
      onClick={onClick}
      classes={{
        label: classes.actionButtonLabel,
      }}
    >
      {title}
    </Button>
  );
};

export const CoDropDownButton = (props) => {
  const classes = useStyles();

  const { title, onClick } = props;

  return (
    <Button
      onClick={onClick}
      classes={{
        label: classes.actionButtonLabel2,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "3px",
        }}
      >
        {title}
        <ArrowDropDownIcon style={{ marginBottom: "3px" }} />
      </div>
    </Button>
    // <IconButton
    //   edge="start"
    //   color="inherit"
    //   aria-label="menu"
    //   classes={{
    //     label: classes.actionButtonLabel,
    //   }}
    //   onClick={onClick}
    // >
    //   <div style={{ display: "flex", alignItems: "center" }}>
    //     {title}
    //     <ArrowDropDownIcon />
    //   </div>
    // </IconButton>
  );
};

export const CoActionIcon = (props) => {
  const { icon, onClick } = props;

  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={onClick}
    >
      <img src={icon} alt="back" width="20px" height="20px" />
    </IconButton>
  );
};

export const CoListItemButton = (props) => {
  const { title, onClick } = props;

  return (
    <div onClick={onClick} style={{ marginTop: "20px", marginBottom: "20px" }}>
      <CoNonElevatedPanel>
        <p style={styles.listItemButton}>{title ?? "NA"}</p>
      </CoNonElevatedPanel>
    </div>
  );
};

export const CoListItemLoading = (props) => {
  const { style = {} } = props;

  return (
    <div style={Object.assign({}, { marginTop: "20px", marginBottom: "20px" })}>
      <CoNonElevatedPanel style={style}>
        <p style={styles.listItemLoading}>{"Please wait..."}</p>
      </CoNonElevatedPanel>
    </div>
  );
};

export const CoListItemEmpty = (props) => {
  const { style = {} } = props;

  return (
    <div style={Object.assign({}, { marginTop: "20px", marginBottom: "20px" })}>
      <CoNonElevatedPanel style={style}>
        <p style={styles.listItemLoading}>{"Nothing to display"}</p>
      </CoNonElevatedPanel>
    </div>
  );
};

const CoSection = (props) => {
  const classes = useStyles();

  const { children, title, action } = props;

  return (
    <div style={{ marginBottom: "40px" }}>
      <div
        style={{
          marginBottom: !action ? "20px" : "10px",
          display: "flex",
        }}
      >
        <SectionTitle classes={classes} value={title ?? "Section Title here"} />
        {action}
      </div>
      {children}
    </div>
  );
};

export default CoSection;
