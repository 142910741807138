import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import { subscribeToUserLogin } from "./states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import { REQUEST_KEY_USER_LOGIN, requestLogoutUser } from "./states/actions";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import { compose } from "redux";
import { connect } from "react-redux";
import withSplashScreenNavigation from "../../components/hoc/withSplashScreenNavigation";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import { subscribeToUser } from "../venueSelection/states/reducers";
import {
  REQUEST_STATE_LOADING,
  REQUEST_STATE_STAND_BY,
} from "../../common/states/actions.request";
import FirebaseCloudMessaging from "../../data/sources/notifications/FirebaseCloudMessaging";

class LogoutScreen extends React.Component {
  componentDidMount() {
    const {
      user: {
        logged_in_user: {
          auth_token: authToken = undefined,
          country_code: countryCode,
          phone_number: phoneNumber,
        },
      },
      requestLogout,
    } = this.props;

    if (authToken !== undefined) {
      console.log(countryCode, phoneNumber);

      FirebaseCloudMessaging.getDeviceRegistrationToken().then((token) => {
        requestLogout({ countryCode, phoneNumber, deviceToken: token });
      });
      // .error((err) => {
      //   console.error("Error while getting firebase token : ", err);
      //   requestLogout({ countryCode, phoneNumber });
      // });
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      api: { request_state: requestStateOld },
      replacePath,
    } = this.props;

    const {
      api: { request_state: requestStateNew },
    } = nextProps;

    if (
      requestStateOld === REQUEST_STATE_LOADING &&
      requestStateNew === REQUEST_STATE_STAND_BY
    ) {
      replacePath("/login");
    }

    return true;
  }

  render() {
    return (
      <div>
        <NavigationBar title={"Logout"} />
        <LoadingErrorEmpty {...this.props} isListEmpty={false}>
          <p>Please wait...</p>
        </LoadingErrorEmpty>
      </div>
    );
  }
}

const ComposedLogoutScreen = compose(
  withHistoryObject,
  withSplashScreenNavigation
)(LogoutScreen);

const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_USER_LOGIN),
});

const mapDispatchToProps = (dispatch) => ({
  requestLogout: (params) => dispatch(requestLogoutUser({ ...params })),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedLogoutScreen);
