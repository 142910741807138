import React, { useState } from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { DrawerRoutes as Route, getDrawerRoutes } from "./index";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CallIcon from '@material-ui/icons/Call';
import CoDrawerIcon from "../assets/icons/co_drawer_right.svg";
import CoBackIcon from "../assets/icons/arrow_back.svg";
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  MenuList,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import {
  CoDrawerHeader,
  CoDrawerHelpItem,
  CoDrawerDropDownItem,
} from "./Drawer";
import { connect } from "react-redux";
import { subscribeToUser } from "../modules/venueSelection/states/reducers";
import credentials from "../data/sources/api/credentials";
import SimpleDialogListPopup from "../components/SimpleDialogListPopup";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "white",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontFamily: "Brown-Bold",
      fontSize: "18px",
      color: "#000000",
      opacity: 0.87,
      marginTop: "5px",
    },
    drawer: {
      width: 300,
    },
    fullList: {
      width: "auto",
    },
  })
);

const DrawerMenuItem = (props) => {
  const { isSelected, itemName } = props;

  return (
    <MenuItem selected={isSelected}>
      <ListItemText
        disableTypography={true}
        style={{
          opacity: 0.87,
          fontFamily: "Brown-Bold",
          lineHeight: 1.51,
          color: "#000000",
          paddingTop: "3px",
          fontSize: "16px",
        }}
        primary={itemName}
      />
    </MenuItem>
  );
};
const DrawerMenu = (props) => {
  const { routes, isRouteActive } = props;
  return (
    <MenuList>
      {routes.map((prop, key) => {
        const { path, sidebarName } = prop;
        return (
          <NavLink key={key} to={path} style={{ textDecoration: "none" }}>
            <DrawerMenuItem
              itemName={sidebarName}
              isSelected={isRouteActive(path)}
            />
          </NavLink>
        );
      })}
    </MenuList>
  );
};
const CallingPopup = (props) => {
  const {selectedVenue} = props;
  const {
    all_cluster_heads: clusterHeads=[],
    all_city_heads: cityHeads=[],
    all_venue_managers: venueManagers=[],
  } = selectedVenue;

  const [open, setOpen] = React.useState(false);
  let contactList = []
  venueManagers.forEach(vm => {
    contactList.push({
      value: `${vm['name']} (VM)`,
      returnValue: vm['phoneNumber'],
    })
  });

  // ------------unComent if asked only

  // clusterHeads.forEach((ch) => {
  //   contactList.push({
  //     value: `${ch['name']} (CH)`,
  //     returnValue: ch['phoneNumber'],
  //   })
  // });
  // cityHeads.forEach(cth => {
  //   contactList.push({
  //     value: `${cth['name']} (CTH)`,
  //     returnValue: cth['phoneNumber'],
  //   })
  // });

  // ----------------------------

 
  contactList.push({
    value: "Team Weddingz",
    returnValue: "+917666778899"
  });

  const item= {
    avatar: "call",
    title: "Call",
    itemList: contactList,
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (selected) => {
    setOpen(false);
    if(selected != "Na"){
      console.log(selected);
      window.open(`tel:${selected}`, '_self');
    }
  };
  return(
    <div>
      <CallIcon onClick={handleClickOpen}/>
      <SimpleDialogListPopup open={open} onClose={handleClose} item={item} />
      &nbsp;
    </div>
  );
};
const AppBar = (props) => {
  const { title, classes, onMenuIconClicked, onBackButtonClicked , selectedVenue} = props;
  return (
    <div className={classes.root}>
      <MaterialAppBar
        position="sticky"
        style={{
          color: "black",
          backgroundColor: "white",
          boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.06)",
        }}
      >
        <Toolbar>
          {onBackButtonClicked != null ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={onBackButtonClicked}
            >
              <img src={CoBackIcon} alt="back" />
              {/* <MenuIcon /> */}
            </IconButton>
          ) : null}
          <Typography
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            variant="h6"
            className={classes.title}
          >
            {title}
          </Typography>
          <CallingPopup selectedVenue = {selectedVenue}/>
          {onMenuIconClicked ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={onMenuIconClicked}
            >
              <img src={CoDrawerIcon} alt="drawer" />
              {/* <MenuIcon /> */}
            </IconButton>
          ) : null}
        </Toolbar>
      </MaterialAppBar>
    </div>
  );
};
const NavigationBar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    title,
    user: { selected_venue: selectedVenue },
    hasDrawerIcon = true,
    hasBackIcon = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event == null ||
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift"))
    ) {
      return;
    }
    setIsOpen(open);
  };
  const isRequestFromDrawer = props?.location?.search?.includes("drawer=true");
  const navigateBack = () => {
    history.goBack();
  };
  const isRouteActive = (routeName) => {
    return props.location.pathname === routeName ? true : false;
  };
  return (
    <div>
      <AppBar
        title={title ?? "NA"}
        classes={classes}
        onMenuIconClicked={hasDrawerIcon ? toggleDrawer(true) : null}
        onBackButtonClicked={
          hasBackIcon === true && !isRequestFromDrawer ? navigateBack : null
        }
        selectedVenue = {selectedVenue}
      />
      {hasDrawerIcon === true ? (
        <Drawer
          anchor={"right"}
          classes={{ paper: classes.drawer }}
          open={isOpen}
          onClose={toggleDrawer(false)}
        >
          <div className={classes.fullList} role="presentation">
            <CoDrawerHeader history={history} {...props} />
            <DrawerMenu
              routes={getDrawerRoutes(props)}
              isRouteActive={isRouteActive}
              classes={classes}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <CoDrawerHelpItem
                onHelpItemClicked={() =>
                  window.open(credentials.support_contact_form)
                }
              />
              <CoDrawerDropDownItem
                title={selectedVenue?.venue_name ?? "NA"}
                onClick={() => {
                  history.push("/venues");
                }}
              />
            </div>
          </div>
        </Drawer>
      ) : null}
    </div>
  );
};
// const getDrawerRoutes = () => {
//   const updateAvailable = window["updateAvailable"];
//   if (updateAvailable === true) {
//     console.log("Updating side bar");
//     const homePaths = Routes.filter(({ path }) => path === "/home");
//     if (homePaths.length === 0) {
//       return [
//         ...Routes.slice(0, Routes.length - 1),
//         {
//           path: "/home",
//           sidebarName: "Install App",
//         },
//         ...Routes.slice(Routes.length - 1),
//       ];
//     } else {
//       console.log("Drawer already contains Install App");
//     }
//   }
//   return Routes;
// };
const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
});
const mapDispatchToProps = (dispatch) => ({});
const RoutedNavigationBar = withRouter(NavigationBar);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutedNavigationBar);