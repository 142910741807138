import { mapRecceListToTemplateSix } from "./mappings/recce.mappings";

export const mapReccesToUiTemplate = (recceList, onVoMessage=()=>{}) => {
  return {
    coTemplateSixData: mapRecceListToTemplateSix(recceList, onVoMessage),
  };
};

export const initialRecceUiTemplate = {
  coTemplateSixData: {},
};
