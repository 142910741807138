import { parseDate } from "../../common/utils/RestUtils";

export const mapOrderApiResponse = (response) => {
  const {
    bookingId: booking_id,
    name: customer_name,
    eventDate: event_date,
    bookingDate: booking_date,
    pax,
    gmv,
    eventSlots: event_slots,
    partyArea: party_area,
    additionalNotes: additional_notes,
    alcoholAllowed: is_alcohol_allowed,
    bcfStatus: bcf_status,
    customerId: customer_id,
    foodPackage: food_package,
    foodType: food_type,
    pppRental: ppp_rental,
    status: booking_status,
    ccfDocumentUrl: ccf_url,
    eventType: event_type,
    bookingAmount: booking_amount,
    voStatus: vo_status, 
    weddingServiceFee: service_fee,
    allCCF: older_CCFlist,
  } = response;

  return {
    booking_id,
    customer_name,
    customer_id,
    event_date: parseDate({ dateString: event_date }),
    booking_date: parseDate({ dateString: booking_date }),
    pax,
    gmv,
    event_slots,
    party_area,
    additional_notes,
    is_alcohol_allowed,
    bcf_status,
    food_package,
    food_type,
    ppp_rental,
    booking_status,
    ccf_url,
    event_type,
    booking_amount,
    vo_status: parse_vo_status(vo_status),
    service_fee,
    older_CCFlist,
  };
};

const parse_vo_status = (vo_status) => {
  if(vo_status == "AwaitingResponse"){
    return "Pending";
  }else if(vo_status == null){
    return "Pending"
  }else {
    return vo_status;
  }
};

export const mapOrderSummaryResponse = (orderSummary) => {
  return Object.keys(orderSummary).map((key) => {
    return {
      date: parseDate({ dateString: key }),
      event_count: orderSummary[key],
    };
  });
};

export const mapOrderListResponse = (orderList = []) => {
  return orderList.map((order) => mapOrderApiResponse(order));
};

export const mapUpcomingEventsApiResponse = (response) => {
  return {
    event_list: mapOrderListResponse(response.data.events),
    pagination: response.data.pagination,
  };
};

export const mapUpcomingEventsApiResponse2 = (response) => {
  const {
    data: { upcomingOrdersDetails },
  } = response;

  const orderList = mapOrderListResponse(upcomingOrdersDetails.events);

  return {
    event_list: orderList,
    pagination: {
      totalItems: 9,
      index: 0,
      size: 20,
      comment: "Contains Items from Index 0 to 9",
    },
  };
};

export const mapMonthWiseEventsResponse = (monthWiseEvents) => {
  const { occupancyList = [] } = monthWiseEvents;

  const occupancy_list_summary = occupancyList.map((occupancy) => {
    return {
      month: parseDate({ dateString: occupancy.startDate }),
      event_count: occupancy.totalEventCount,
      gmv_amount: occupancy.totalGmvAmount,
    };
  });

  return occupancy_list_summary;
};
