import React from "react";

import {
  LoginBackground,
  ElevatedLoginContainer,
  LoginFooter,
  ElevatedContainerHeaderTitle,
  ElevatedContainerPrimaryButton,
  PhoneNumberField,
  BorderLinearProgress,
  ElevatedContainerText2,
} from "./components/LoginBackground";
import { connect } from "react-redux";
import {
  subscribeToRequestState,
  subscribeToSharedState,
} from "../../common/states/reducers";
import { REQUEST_KEY_USER_LOGIN, requestLoginOtp } from "./states/actions";
import { subscribeToUserLogin } from "./states/reducers";
import RequestAction from "../../common/states/actions.request";
import { CoAlertDialog } from "../../components/AlertDialog";
import { compose } from "redux";
import credentials from "../../data/sources/api/credentials";
import HOC from "../../components/hoc";
import spectator from "../../data/sources/analytics/spectator";
import { isInStandaloneMode } from "../../common/utils";
class PhoneNumberForm extends React.Component {
  initialState = {
    phone: {
      countryCode: "+91",
      phoneNumber: "",
    },
    inlineErrorMessage: undefined,
    dialogErrorMessage: "",
    isErrorDialogVisible: false,
    isLoading: false,
  };

  constructor(props) {
    super(props);

    const {
      login: { phone },
    } = props;

    this.state = Object.assign({}, this.initialState, {
      phone: {
        phoneNumber: phone.phone_number,
        countryCode: phone.country_code,
      },
    });

    this.onPhoneNumberChanged = this.onPhoneNumberChanged.bind(this);
    this.onFormSubmitted = this.onFormSubmitted.bind(this);
  }

  componentDidMount() {
    spectator.logEvent("app_opened", {
      is_standalone: isInStandaloneMode(),
      app_version: credentials.app_version.semanticCode,
      app_open_date: Date(),
    });
  }

  processApiError(props) {
    const {
      api: {
        error: { message: errorMessage },
      },
    } = props;

    this.setState({
      isLoading: false,
      dialogErrorMessage: errorMessage,
      isErrorDialogVisible: true,
    });
  }

  shouldComponentUpdate(nextProps) {
    const {
      api: { request_state: newRequestState },
    } = nextProps;

    const {
      api: { request_state: oldRequestState },
    } = this.props;

    if (
      newRequestState === RequestAction.STATE.LOADING &&
      oldRequestState !== RequestAction.STATE.LOADING
    ) {
      this.setState({ isLoading: true });
      return true;
    }

    if (
      oldRequestState === RequestAction.STATE.LOADING &&
      newRequestState === RequestAction.STATE.ERROR
    ) {
      this.processApiError(nextProps);
      return true;
    }

    if (
      oldRequestState === RequestAction.STATE.LOADING &&
      newRequestState === RequestAction.STATE.STAND_BY
    ) {
      console.log("Move to next screen");
      this.setState({ isLoading: false });
      const { replacePath } = this.props;
      replacePath("/login/otp");
      return false;
    }

    return true;
  }

  onFormSubmitted() {
    const {
      phone: { phoneNumber },
    } = this.state;

    if (
      phoneNumber == null ||
      phoneNumber === this.initialState.phone.phoneNumber
    ) {
      this.setState({
        inlineErrorMessage: "Mobile Number field can not be empty",
      });
      return;
    }

    const { phone } = this.state;
    const { requestLoginOtp } = this.props;
    requestLoginOtp({ ...phone });
  }

  onPhoneNumberChanged(event) {
    const value = event.target.value;

    if (value === null || value === "" || value?.length === 0) {
      this.setState({
        phone: this.initialState.phone,
        inlineErrorMessage: "Mobile Number field can not be empty",
      });
      return;
    }

    if (value?.length !== 10) {
      this.setState((prevState) => ({
        phone: {
          ...prevState.phone,
          phoneNumber: value,
        },
        inlineErrorMessage: "Mobile Number should be 10 characters long",
      }));

      return;
    }

    if (value?.length === 10) {
      this.setState((prevState) => ({
        phone: {
          ...prevState.phone,
          phoneNumber: value,
        },
        inlineErrorMessage: undefined,
      }));
    }
  }

  handleClose = () => {
    this.setState({
      isErrorDialogVisible: false,
      dialogErrorMessage: this.initialState.dialogErrorMessage,
    });
  };

  render() {
    const {
      inlineErrorMessage,
      isLoading,
      phone: { phoneNumber },
    } = this.state;

    return (
      <>
        <div
          style={{
            display: "flex",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <PhoneNumberField
            disabled={isLoading}
            type="number"
            value={phoneNumber}
            error={inlineErrorMessage !== undefined}
            helperText={inlineErrorMessage ?? null}
            onChange={this.onPhoneNumberChanged}
          />
        </div>
        <ElevatedContainerPrimaryButton
          disabled={inlineErrorMessage !== undefined || isLoading}
          value={"Next"}
          onPrimaryButtonClicked={this.onFormSubmitted}
        />
        {isLoading ? <BorderLinearProgress /> : false}

        <CoAlertDialog
          message={this.state.dialogErrorMessage}
          open={this.state.isErrorDialogVisible}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}

const LoginPhoneNumber = (props) => {
  return (
    <div>
      <LoginBackground>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "-70px",
          }}
        >
          <ElevatedLoginContainer>
            <ElevatedContainerHeaderTitle value="Sign in to get started" />
            <PhoneNumberForm {...props} />
          </ElevatedLoginContainer>
          <LoginFooter
            onButtonOneClicked={() => {
              console.log("Button One clicked");
              window.open("https://m.weddingz.in/partner-with-us/");
            }}
            onButtonTwoClicked={() => {
              window.open(`${credentials.support_contact_form}`);
              console.log("Button Two clicked");
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <ElevatedContainerText2
            value={`v ${credentials.app_version.semanticCode}`}
          />
        </div>
      </LoginBackground>
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: subscribeToUserLogin(state),
  api: subscribeToRequestState(state, REQUEST_KEY_USER_LOGIN),
  shared: subscribeToSharedState(state),
});

const mapDispatchToProps = (dispatch) => ({
  // consumeInstallDialogRequest: (installState) =>
  // dispatch({ type: SET_INSTALL_STATE, state: installState }),
  requestLoginOtp: (params) => dispatch(requestLoginOtp({ ...params })),
});

const ComposedLoginPhoneNumber = compose(
  HOC.withHistoryObject,
  HOC.withUpdateDialog,
  HOC.withInstallDialog
)(LoginPhoneNumber);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedLoginPhoneNumber);
