import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { CoCardTemplateSix, CoCardTemplateSixList, Header } from "../../components/templates/CoCardTemplateSix";
import {
  subscribeToSharedState,
  subscribeToRequestState,
} from "../../common/states/reducers";
import {
  sendVoMessage
} from "./states/actions";
import {
  REQUEST_STATE_LOADING
} from "../../common/states/actions.request";
import { mapReccesToUiTemplate, initialRecceUiTemplate } from "../recces/index"; 
import { subscribeToVenueCalendar } from "./states/reducers";
import { REQUEST_KEY_VENUE_CALENDAR_DATA } from "./states/actions";
import { connect } from "react-redux";
import { compose } from "redux";


import HigherOrder from "../../components/hoc/index.js";

import * as moment from "moment";

const getReccesForSelectedDate = (props) => {
  const {
    venueCalendar: { calendar_data: calendarData = {} },
    selectedDate,
  } = props;

  const monthDate = moment(selectedDate, "DD-MM-yyyy").format("MMMM_yyyy");

  const monthMap = calendarData[monthDate];
  if (monthMap == null) {
    return [];
  }

  const { dayWiseSplit } = monthMap;
  const calendarDateObject = dayWiseSplit[selectedDate];
  if (calendarDateObject == null) {
    return [];
  }

  return calendarDateObject["recce_list"];
};

class VenueCalendarRecceDetails2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      recce_details: [],
    };
  }

  componentDidMount() {
    const recce_list = getReccesForSelectedDate(this.props);
    if (recce_list == null) {
      this.setState({ hasError: true });
    }

    this.setState({ recce_details: recce_list });
  }
  render() {
    const { showAsTab = false } = this.props;
    return (
      <div>
        {!showAsTab ? <NavigationBar title={"Calendar Recce Details"} /> : null}
        {this.state.hasError ? <h3>Something went wrong</h3> : null}
        <p>{JSON.stringify(this.state.recce_details)}</p>
      </div>
    );
  }
}

const VenueCalendarRecceDetails = (props) => {
  const { coTemplateSixData , showAsTab = false } = props;

  return (
    <>
      {!showAsTab ? <NavigationBar title={"Calendar Visit Details"} /> : null}
      <LoadingErrorEmpty
        {...props}
        isListEmpty={coTemplateSixData?.data?.length === 0}
        empty={{
          title: "No Visit Found",
          subtitle: "No customer has visited the venue for this event date.",
        }}
        onRetryButtonClicked={() => {
          requestRecces(props);
        }}
      >
        
        <div style={{ margin: "20px" }}>
          <CoCardTemplateSixList data={coTemplateSixData.data} />
        </div>
      </LoadingErrorEmpty>
    </>
  );
};

const onSendVoMessage = async(voMessage, receeDetails, props)=>{
  const {
    api: { request_state: requestState },
    user: {
      selected_venue: { venue_id: venueId },
    },
    sendVoMessage,
    
  } = props;

  const params = {
    venueId: venueId,
    receeId: receeDetails["recce_id"], 
    voMessage: voMessage, 
  }

  if (requestState !== REQUEST_STATE_LOADING){
    return await sendVoMessage(params)
  }

}


const requestRecces = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId, startDate: startDate, endDate: endDate },
    },
    
  } = props;

  
};

const mapUpcomingReccesToUiTemplate = (props) => {
  const recceList = getReccesForSelectedDate(props);
  const onVoMessage = (voMessage, receeDetails) => onSendVoMessage(voMessage, receeDetails, props)
  return mapReccesToUiTemplate(recceList, onVoMessage);
};


const withApiData = (WrappedComponent) =>
  HigherOrder.withSinglePageApiCall(WrappedComponent, {
    propInitialUiTemplate: initialRecceUiTemplate,
    propMakeSinglePageApiCall: requestRecces,
    propMapToUiTemplate: mapUpcomingReccesToUiTemplate,
  });


const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  shared: subscribeToSharedState(state),
  venueCalendar: subscribeToVenueCalendar(state),
  api: subscribeToRequestState(state, REQUEST_KEY_VENUE_CALENDAR_DATA),
});

const mapDispatchToProps = (dispatch) => ({
  sendVoMessage: (params) => dispatch(sendVoMessage({ ...params })),
});

const ComposedVenueCalendarRecceDetails = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withNotifcationHandler,
  withApiData
)(VenueCalendarRecceDetails);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedVenueCalendarRecceDetails);
