import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import { CoCardTemplateSixList } from "../../components/templates/CoCardTemplateSix";

import HigherOrder from "../../components/hoc/index.js";
import { initialEventUiTemplate, mapEventsToUiTemplate } from ".";
import { compose } from "redux";
import { subscribeToEvents } from "./states/reducers";
import {
  REQUEST_KEY_UPCOMING_EVENT_LIST,
  requestUpcomingEventList,
  updateBookingStatus,
} from "./states/actions";
import {
  REQUEST_STATE_LOADING
} from "../../common/states/actions.request";
import {
  BookingApproved,
  BookingRejected,
  AlreadyReplied,
  CustomerResponsePending
} from "../../common/utils/constants";
import { connect } from "react-redux";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import { createVenuePath } from "../../routes";

const UpcomingEvents = (props) => {
  const { coTemplateSixData } = props;

  return (
    <>
      <NavigationBar title={"Upcoming Events"} hasBackIcon={true} />
      <LoadingErrorEmpty
        {...props}
        isListEmpty={coTemplateSixData?.data?.length === 0}
        empty={{
          title: "No Upcoming Events found",
          subtitle: "There are no Upcoming Events right now",
        }}
        onRetryButtonClicked={() => {
          requestUpcomingEvents(props);
        }}
      >
        <div style={{ margin: "20px" }}>
          <CoCardTemplateSixList data={coTemplateSixData.data} />
        </div>
      </LoadingErrorEmpty>
    </>
  );
};

const onApproveRejectClicked = async(selected, reason, bookingDetails, bookingList, props) => {

  const {
    api: { request_state: requestState },
    user: {
      selected_venue: { venue_id: venueId },
    },
    events: {
      upcoming_events: upcomingEvents,
    },
    updateBookingStatus,
    setSnackbarData,
  } = props;

  const params = {
    venueId: venueId,
    documentUrl: bookingDetails["ccf_url"], 
    oldStatus: bookingDetails["vo_status"], 
    newStatus: selected, 
    rejection_reason: reason,
    // selectedDate,
    upcomingEvents,
    booking_id: bookingDetails['booking_id'],
  }
  if (requestState !== REQUEST_STATE_LOADING) {
      return await updateBookingStatus(params).then((response)=>{
        const ui_data = { //[message , severity , color]
          CustomerResponsePending : ['Wait for customer to approve.', 'info', 'info'],
          BookingApproved: ['Approved', 'success', 'success'],
          BookingRejected: ['Rejected', 'success', 'error'],
          AlreadyReplied: ['Already Replied', 'info', 'info'],
        }
        
        if(response)  {
          setSnackbarData({ "open": true, 
                            "message": ui_data[response][0], 
                            "severity": ui_data[response][1], 
                            "color": ui_data[response][2] })
        }
      });
  }
}


const requestUpcomingEvents = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId },
    },
    requestUpcomingEventList,
  } = props;

  if (venueId !== undefined) {
    requestUpcomingEventList({ venueId: venueId });
  }
};

const onCCFButtonClicked = (booking, props) => {
  const { pushPath } = props;
  const venuePath = createVenuePath(props);
  pushPath(`${venuePath}/events/ccf`, {
    title: "CCF",
    url: booking["ccf_url"],
  });
};

const mapUpcomingEventsToUiTemplate = (props) => {
  const {
    events: {
      upcoming_events: { event_list: upcomingEventList },
    },
  } = props;

  const onCCFClicked = (event) => onCCFButtonClicked(event, props);
  const onStatusChange= (selected, reason, bookingDetails) => onApproveRejectClicked(selected, reason, bookingDetails, upcomingEventList, props);

  return mapEventsToUiTemplate(upcomingEventList, onCCFClicked, onStatusChange);
};

const withApiData = (WrappedComponent) =>
  HigherOrder.withSinglePageApiCall(WrappedComponent, {
    propInitialUiTemplate: initialEventUiTemplate,
    propMakeSinglePageApiCall: requestUpcomingEvents,
    propMapToUiTemplate: mapUpcomingEventsToUiTemplate,
  });

const ComposedUpcomingEvents = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withNotifcationHandler,
  HigherOrder.withSnackBar,
  withApiData
)(UpcomingEvents);

const mapStateToProps = (state) => ({
  events: subscribeToEvents(state),
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_UPCOMING_EVENT_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestUpcomingEventList: (params) =>dispatch(requestUpcomingEventList({ ...params })),
  updateBookingStatus: (params) => dispatch(updateBookingStatus({ ...params })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedUpcomingEvents);
