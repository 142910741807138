import { formatDate, formatINRCurrency } from "../../../common/utils";
import { mapBusinessMetricsSummaryToTemplateOne } from "../../venueDashboard/mappings/dashboard.mapping";

export const mapMetricsToTemplateTwoItem = (id, name, monthMetrics = {}) => {
  const { metrics, month } = monthMetrics;

  return {
    id: id,
    name: name,
    items: [
      {
        title: "Month",
        value: formatDate(month, "MMM YY"),
      },
      {
        title: "Leads",
        value: metrics["lead_count"],
      },
      {
        title: "Recces",
        value: metrics["recce_count"],
      },
      {
        title: "Closures",
        value: metrics["closure_count"],
      },
      {
        title: "GMV",
        value: `${formatINRCurrency(metrics["gmv"]) ?? "NA"}`,
      },
    ],
  };
};

export const mapMetricsToTemplateOneItem = (id, name, monthMetrics = {}) => {
  const { metrics, month } = monthMetrics;

  return {
    id: id,
    name: name,
    header: { title: formatDate(month, "MMMM YYYY") },
    items: mapBusinessMetricsSummaryToTemplateOne(metrics).data,
  };
};

const mapMetricsListToTemplateTwoList = (metricsList = []) => {
  const lastIndex = metricsList.length;
  let index = 0;
  return metricsList.map((metrics) => {
    index += 1;
    return mapMetricsToTemplateTwoItem(index, index, metrics);
  });
};

const mapMetricsListToTemplateOneList = (metricsList = []) => {
  const lastIndex = metricsList.length;
  let index = 0;
  return metricsList.map((metrics) => {
    index += 1;
    return mapMetricsToTemplateOneItem(index, index, metrics);
  });
};

export const mapBusinessMetricsDataToTempateOneList = (metricsList = []) => {
  return {
    sectionTitle: "Past Data",
    data: mapMetricsListToTemplateOneList(metricsList),
  };
};

export const mapBusinessMetricsDataToTempateTwoList = (metricsList = []) => {
  return {
    sectionTitle: "Past Data",
    data: mapMetricsListToTemplateTwoList(metricsList),
  };
};
