import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CoCollapsablePanel = (props) => {
  const { header: { title: headerTitle } = {}, children } = props;

  return (
    <div>
      <Accordion
        style={{
          boxShadow: "0 5px 12px 0 rgba(217, 226, 233, 0.5)",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#000000" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Brown-Bold",
              fontSize: "14px",
              opacity: 0.87,
              lineHeight: 1.43,
            }}
          >
            {headerTitle ?? "NA"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ display: "block", paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div
            style={{
              opacity: 0.1,
              backgroundColor: "#000000",
              height: "1px",
              width: "100%",
              marginBottom: "15px",
            }}
          />
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CoCollapsablePanel;
