import React from "react";
import NavigationBar from "../../routes/NavigationBar";

import { CoCardTemplateFiveList } from "../../components/templates/CoCardTemplateFive";
import { subscribeToUser } from "./states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import {
  REQUEST_KEY_USER_VENUE_LIST,
  requestUserVenueList,
  updateSelectedVenue,
} from "./states/actions";
import { connect } from "react-redux";
import {
  REQUEST_STATE_UNDEFINED,
  REQUEST_STATE_LOADING,
  REQUEST_STATE_STAND_BY,
} from "../../common/states/actions.request";
import { mapVenueListToTemplateFiveRowList } from "./mappings/venue.mappings";
import { LoadingByRequestState } from "../../components/LoadingScreen";
import Empty from "../../components/EmptyScreen";
import { compose } from "redux";
import withSplashScreenNavigation from "../../components/hoc/withSplashScreenNavigation";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import { createVenuePath } from "../../routes";

class VenueSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templateFiveData: [],
    };

    this.mapVenueListDataToUiTemplate = this.mapVenueListDataToUiTemplate.bind(
      this
    );
    this.onVenueClicked = this.onVenueClicked.bind(this);
  }

  componentDidMount() {
    this.mapVenueListDataToUiTemplate(this.props);
  }

  mapVenueListDataToUiTemplate(newProps) {
    const {
      user: { user_venue_list: userVenueList, selected_venue: selectedVenue },
    } = newProps;

    this.setState({
      templateFiveData: mapVenueListToTemplateFiveRowList(
        userVenueList,
        selectedVenue
      ),
    });
  }

  shouldComponentUpdate(nextProps) {
    const {
      api: { request_state: newRequestState },
    } = nextProps;

    const {
      api: { request_state: oldRequestState },
    } = this.props;

    if (
      oldRequestState === REQUEST_STATE_LOADING &&
      newRequestState === REQUEST_STATE_STAND_BY
    ) {
      this.mapVenueListDataToUiTemplate(nextProps);
      return false;
    }

    return true;
  }

  onVenueClicked(venueId) {
    const {
      api: { request_state: requestState },
      user: { user_venue_list: userVenueList, selected_venue: selectedVenue },
      updateSelectedVenue,
      replacePath,
    } = this.props;

    if (venueId === selectedVenue["venue_id"]) {
      console.log(
        "ON_VENUE_CLICKED",
        `The venue with id '${venueId}' is already selected. Ignoring the call`
      );
      replacePath("/venue/dashboard");
      return;
    }

    if (requestState === REQUEST_STATE_LOADING) {
      console.log(
        "ON_VENUE_CLICKED",
        `We are already waiting for a request to be resolved. Ignoring this call`
      );
      return;
    }

    const filteredVenues = userVenueList.filter(
      (venue) => venue["venue_id"] === venueId
    );

    if (filteredVenues.length === 0) {
      console.log(
        "ON_VENUE_CLICKED",
        `We didn't find any venue with matching venueId : ${venueId}`
      );
    }
    updateSelectedVenue({ venue: filteredVenues[0] });
    setTimeout(() => {
      const venuePath = createVenuePath(this.props);
      replacePath(`${venuePath}/dashboard`);
    }, 1000);
  }

  render() {
    const {
      api: {
        request_state: requestState,
        loading: { message: loadingMessage } = {},
      },
      user: { user_venue_list: userVenueList },
    } = this.props;

    const { templateFiveData } = this.state;

    return (
      <div>
        <NavigationBar title={"Select Venue"} hasBackIcon={true} />
        <LoadingByRequestState
          message={loadingMessage}
          requestState={requestState}
        >
          <div style={{ margin: "20px" }}>
            <Empty
              isEmpty={userVenueList.length === 0}
              isLoading={requestState === REQUEST_STATE_LOADING}
            >
              <CoCardTemplateFiveList
                onClick={this.onVenueClicked}
                rows={templateFiveData}
              />
            </Empty>
          </div>
        </LoadingByRequestState>
      </div>
    );
  }
}

const ComposedVenueSelection = compose(
  withHistoryObject,
  withSplashScreenNavigation
)(VenueSelection);

const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_USER_VENUE_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestUserVenueList: (params) =>
    dispatch(requestUserVenueList({ ...params })),
  updateSelectedVenue: (params) => dispatch(updateSelectedVenue({ ...params })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedVenueSelection);
