import React from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import { Routes } from "./routes";
import { Provider } from "react-redux";
import store from "./data/sources/store/redux";
import Spectator from "./data/sources/analytics/spectator";
import FirebaseCloudMessaging from "./data/sources/notifications/FirebaseCloudMessaging";

const NotFound = (props) => {
  const location = useLocation();
  return (
    <div>
      <h1>Not Found</h1>
      <p>{location.pathname}</p>
    </div>
  );
};

// FirebaseConfig.init();
Spectator.setup();
FirebaseCloudMessaging.setup();

const App = () => {
  return (
    <div>
      <Switch>
        {Routes.map((route) => (
          <Route exact path={route.path} key={route.path}>
            <route.component />
          </Route>
        ))}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

const ReduxApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default ReduxApp;
