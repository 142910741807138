import { mapVenueListFromLoginResponse } from "./venue.mappings";

const mapUserResponse = (response) => {
  const {
    authToken: auth_token,
    data: { name, contactId: phone_number, countryCode: country_code = "+91" },
  } = response;

  return {
    name,
    phone_number,
    country_code,
    auth_token,
  };
};

export const mapLoginResponse = (response) => {
  const { data: userResponse } = response;

  const venueList = mapVenueListFromLoginResponse(userResponse);
  const user = mapUserResponse(response);

  if (venueList.length === 0) {
    throw new Error(
      JSON.stringify({
        status: "CONSTRAINT_ERROR",
        errorCode: "333",
        errorType: "CONSTRAINT_ERROR",
        message: "No Venue mapped with the given venue owner.",
      })
    );
  }

  return {
    user,
    venue_list: venueList,
    primary_venue: venueList[0],
  };
};
