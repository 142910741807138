import { CustomerReviewService } from "../../../data/VenueManagementService";
import RequestAction, {
  REQUEST_STATE_STAND_BY,
} from "../../../common/states/actions.request";

export const SET_CUSTOMER_REVIEW_LIST = "SET_CUSTOMER_REVIEW_LIST";
export const RESET_CUSTOMER_REVIEW_LIST = "RESET_CUSTOMER_REVIEW_LIST";
export const REQUEST_KEY_CUSTOMER_REVIEW_LIST = "rk_customer_review_list";

export const requestCustomerReviewList = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please don't wait",
      key: REQUEST_KEY_CUSTOMER_REVIEW_LIST,
    })
  );

  dispatch({
    type: RESET_CUSTOMER_REVIEW_LIST,
  });

  const { venueId } = params;

  try {
    const customerReviewResponse = await CustomerReviewService.getVenueReviewList(
      { venueId: venueId }
    );

    dispatch({
      type: SET_CUSTOMER_REVIEW_LIST,
      customerReviews: customerReviewResponse.review_list,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_CUSTOMER_REVIEW_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_CUSTOMER_REVIEW_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error(
      "Error while getting review list from CustomerReview Service",
      err
    );
  }
};
