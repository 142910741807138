export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const RESET_ERROR = "RESET_ERROR";

export const SET_UPDATE_AVAILABLE = "SET_UPDATE_AVAILABLE";
export const RESET_UPDATE_AVAILABLE = "RESET_UPDATE_AVAILABLE";

export const setLoading = ({ message = "Please wait" }, key = undefined) => {
  return {
    type: SET_LOADING,
    key,
    loading: {
      isLoading: true,
      message: message,
    },
  };
};

export const resetLoading = (key = undefined) => ({
  type: RESET_LOADING,
  key,
  loading: {
    isLoading: false,
    message: "",
  },
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const resetError = () => ({
  type: RESET_ERROR,
  error: {
    code: "",
    type: "",
    message: "",
  },
});
