import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import { CoCardTemplateSixList } from "../../components/templates/CoCardTemplateSix";
import {initialEventUiTemplate, mapEventsToUiTemplate} from "../events/index"
import {
  subscribeToSharedState,
  subscribeToRequestState,
} from "../../common/states/reducers";
import {
  BookingApproved,
  BookingRejected,
  AlreadyReplied,
  CustomerResponsePending
} from "../../common/utils/constants";
import {
  REQUEST_STATE_LOADING
} from "../../common/states/actions.request";
import{updateBookingStatus} from "./states/actions";
import { subscribeToVenueCalendar } from "./states/reducers";
import { REQUEST_KEY_VENUE_CALENDAR_DATA } from "./states/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import {createVenuePath} from "../../routes"

import HigherOrder from "../../components/hoc/index.js";

import * as moment from "moment";

const getBookingsForSelectedDate = (props) => {
  const {
    venueCalendar: { calendar_data: calendarData = {} },
    selectedDate,
  } = props;

  const monthDate = moment(selectedDate, "DD-MM-yyyy").format("MMMM_yyyy");

  const monthMap = calendarData[monthDate];
  if (monthMap == null) {
    return [];
  }

  const { dayWiseSplit } = monthMap;
  const calendarDateObject = dayWiseSplit[selectedDate];
  if (calendarDateObject == null) {
    return [];
  }

  return calendarDateObject["order_list"];
};

class VenueCalendarBookingDetails2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      order_details: [],
    };
  }

  componentDidMount() {
    const order_list = getBookingsForSelectedDate(this.props);
    if (order_list == null) {
      this.setState({ hasError: true });
    }

    this.setState({ order_details: order_list });
  }
  render() {
    const { showAsTab = false } = this.props;
    return (
      <div>
        {!showAsTab ? <NavigationBar title={"Calendar Recce Details"} /> : null}
        {this.state.hasError ? <h3>Something went wrong</h3> : null}
        <p>{JSON.stringify(this.state.order_details)}</p>
      </div>
    );
  }
}

const onApproveRejectClicked = async(selected, reason, bookingDetails, bookingList, props) => {

  const {
    api: { request_state: requestState },
    user: {
      selected_venue: { venue_id: venueId },
    },
    venueCalendar: { calendar_data: calendarData = {} },
    updateBookingStatus,
    selectedDate,
    setSnackbarData,
  } = props;

  const params = {
    venueId: venueId,
    documentUrl: bookingDetails["ccf_url"], 
    oldStatus: bookingDetails["vo_status"], 
    newStatus: selected, 
    rejection_reason: reason,
    selectedDate,
    calendarData,
    booking_id: bookingDetails['booking_id'],
  }
  if (requestState !== REQUEST_STATE_LOADING) {
      return await updateBookingStatus(params).then((response)=>{
        const ui_data = { //[message , severity , color]
          CustomerResponsePending : ['Wait for customer to approve.', 'info', 'info'],
          BookingApproved: ['Approved', 'success', 'success'],
          BookingRejected: ['Rejected', 'success', 'error'],
          AlreadyReplied: ['Already Replied', 'info', 'info'],
        }
        
        if(response)  {
          setSnackbarData({ "open": true, 
                            "message": ui_data[response][0], 
                            "severity": ui_data[response][1], 
                            "color": ui_data[response][2] })
        }
      });
  }
}

const VenueCalendarBookingDetails = (props) => {
  const { coTemplateSixData, showAsTab = false } = props;

  return (
    <>
      {!showAsTab ? <NavigationBar title={"Calendar Booking Details"} /> : null}
      <LoadingErrorEmpty
        {...props}
        isListEmpty={coTemplateSixData?.data?.length === 0}
        empty={{
          title: "No Event Found",
          subtitle: "No booking has been done at your venue for this date.",
        }}
        onRetryButtonClicked={() => {
          requestBookingDetails(props);
        }}
      >
        <div style={{ margin: "20px" }}>
          <CoCardTemplateSixList data={coTemplateSixData.data} />
        </div>
      </LoadingErrorEmpty>
    </>
  );
};

const requestBookingDetails = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId, startDate: startDate, endDate: endDate },
    },
    
  } = props;
};

const onCCFButtonClicked = (booking, props) => {
  const { pushPath } = props;
  const venuePath = createVenuePath(props);
  pushPath(`${venuePath}/events/ccf`, {
    title: "CCF",
    url: booking["ccf_url"],
  });
};


const mapUpcomingBookingToUiTemplate = (props) => {
  const bookingList = getBookingsForSelectedDate(props);

  const onCCFClicked = (booking) => onCCFButtonClicked(booking, props);
  const onStatusChange= (selected, reason, bookingDetails) => onApproveRejectClicked(selected, reason, bookingDetails, bookingList, props);
 
  return mapEventsToUiTemplate(bookingList, onCCFClicked, onStatusChange);
};


const withApiData = (WrappedComponent) =>
  HigherOrder.withSinglePageApiCall(WrappedComponent, {
    propInitialUiTemplate: initialEventUiTemplate,
    propMakeSinglePageApiCall: requestBookingDetails,
    propMapToUiTemplate: mapUpcomingBookingToUiTemplate,
  },
  HigherOrder.withSnackBar);




const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  shared: subscribeToSharedState(state),
  venueCalendar: subscribeToVenueCalendar(state),
  api: subscribeToRequestState(state, REQUEST_KEY_VENUE_CALENDAR_DATA),
});

const mapDispatchToProps = (dispatch) => ({
  updateBookingStatus: (params) => dispatch(updateBookingStatus({ ...params })),
});

const ComposedVenueCalendarBookingDetails = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withNotifcationHandler,
  withApiData
)(VenueCalendarBookingDetails);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedVenueCalendarBookingDetails);
