import React from "react";

import {
  makeStyles,
  SnackbarContent,
  Button,
  Snackbar,
  Slide,
  Typography,
  IconButton,
} from "@material-ui/core";

import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { CoLogo } from "./Logo";

export const AUTO_HIDE_DURATION_IN_SECONDS = 60000;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonContainedRoot: {
    fontSize: "20px",
    fontFamily: "Brown-Bold",
    textTransform: "none",
    borderRadius: "20px",
    paddingTop: "10px",
    paddingBottom: "6px",
    paddingRight: "24px",
    paddingLeft: "24px",
    opacity: 0.89,
  },
  buttonContainedPrimary: {
    backgroundColor: "#fcfdfe",
    color: "#ee2e24",
  },

  buttonContainedDisabled: {
    opacity: 0.5,
  },

  textStyle: {
    color: "#ffffff",
    fontFamily: "Brown-Bold",
    fontSize: "16px",
    lineHeight: 1.29,
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const InstallBannerAndroid = (props) => {
  const { onBackPressed, onInstallPressed, open } = props;
  const classes = useStyles();

  return (
    <div style={{}} className={classes.root}>
      <Snackbar
        style={{ display: "flex" }}
        open={open}
        onClose={onBackPressed}
        autoHideDuration={AUTO_HIDE_DURATION_IN_SECONDS}
        TransitionComponent={SlideTransition}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: "#212121",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="delete" onClick={onBackPressed}>
              <CloseOutlined
                style={{ color: "#ffffff", opacity: 0.15 }}
                size="small"
              />
            </IconButton>
          </div>
          <div
            style={{
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                flexDirection: "column",
                margin: "20px",
              }}
            >
              <CoLogo />
            </div> */}

            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  marginLeft: "15px",
                  marginBottom: "15px",
                  marginTop: "15px",
                  marginRight: "15px",
                }}
              >
                <Typography className={classes.textStyle}>
                  {`Install this application on your home screen for quick and easy access when you're on the go.`}
                </Typography>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginBottom: "15px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{ backgroundColor: "#f8f8f8", width: "100%" }}
                  classes={{
                    root: classes.buttonContainedRoot,
                    disabled: classes.buttonContainedDisabled,
                    containedPrimary: classes.buttonContainedPrimary,
                  }}
                  color="secondary"
                  size="small"
                  onClick={onInstallPressed}
                >
                  Install
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Snackbar>
    </div>
  );
};

export default InstallBannerAndroid;
