import {
  SET_CUSTOMER_REVIEW_LIST,
  RESET_CUSTOMER_REVIEW_LIST,
} from "./actions";
import { REQUEST_ACTION_RESET_ALL_STATES } from "../../../common/states/actions.request";

const initialState = {
  customer_review_list: [],
};

const customerReviews = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_REVIEW_LIST:
      return Object.assign({}, state, {
        customer_review_list: action.customerReviews,
      });

    case RESET_CUSTOMER_REVIEW_LIST:
      return Object.assign({}, state, {
        customer_review_list: initialState.customer_review_list,
      });

    case REQUEST_ACTION_RESET_ALL_STATES:
      return Object.assign({}, state, {
        ...initialState,
      });

    default:
      return state;
  }
};

export default customerReviews;

export const subscribeToCustomerReviews = (state) => state.reviews;
