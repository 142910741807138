import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import { CoAlertDialog } from "../../components/AlertDialog";

import { subscribeToCustomerReviews } from "./states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import {
  requestCustomerReviewList,
  REQUEST_KEY_CUSTOMER_REVIEW_LIST,
} from "./states/actions";
import { connect } from "react-redux";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import {
  mapReviewListToCoTable,
  mapReviewListToCoReviewCardList,
} from "./mappings/review.mappings";

import { subscribeToUser } from "../venueSelection/states/reducers";
import { compose } from "redux";

import HigherOrder from "../../components/hoc/index.js";
import UiMocks from "./mappings/ui.mock";
import { CoReviewList } from "./components/ReviewCard";
import CoTabs, { LinkTab, TabPanel } from "../../components/CoTabs";
import queryString from "query-string";

const getIntialTabIndex = (props) => {
  const { location: { search = undefined } = {} } = props;

  if (search !== undefined) {
    const query_params = queryString.parse(search);
    const selected = query_params["selected"];

    if (selected !== undefined && isNaN(selected) === false) {
      const selectedTab = parseInt(selected);

      if (selectedTab > 0 && selectedTab < 2) {
        return selectedTab;
      }
    }
  }

  return 0;
};

class CustomerReviewsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      coTableData: {},
      coReviewCardListData: {},
    };

    this.handleClose = this.handleClose.bind(this);
    this.onTableItemClicked = this.onTableItemClicked.bind(this);
    this.onCommentFieldClicked = this.onCommentFieldClicked.bind(this);
  }

  handleClose = () => {
    this.setState({ openDialog: false, description: "" });
  };

  onTableItemClicked = (rowId, itemId) => {
    console.log("Clicked : ", rowId, itemId);

    const {
      customerReviews: { customer_review_list: customerReviewList = [] },
    } = this.props;

    if (itemId === 3) {
      const review = customerReviewList[rowId];
      const description = review["customer_comment"];

      this.setState({ openDialog: true, description: description });
    }
  };

  onCommentFieldClicked = (review) => {
    this.setState({
      openDialog: true,
      description: review["customer_comment"],
    });
  };

  render() {
    const { coTableData, coReviewCardListData, tabName = "" } = this.props;

    return (
      <div>
        <LoadingErrorEmpty
          {...this.props}
          isListEmpty={Object.keys(coReviewCardListData)?.length === 0}
          emptyMessage="No Customer Reviews here."
          empty={{
            title: "No Customer Reviews here",
            subtitle: `There are no ${tabName} reviews`,
          }}
          onRetryButtonClicked={() => {
            requestVenueReviews(this.props);
          }}
        >
          <>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <CoReviewList
                data={coReviewCardListData.data}
                onCommentFieldClicked={this.onCommentFieldClicked}
              />
            </div>
            <CoAlertDialog
              message={this.state.description}
              open={this.state.openDialog}
              handleClose={this.handleClose}
            />
          </>
        </LoadingErrorEmpty>
      </div>
    );
  }
}

const CustomerReviewsScreen = (props) => {
  const getLinkTab = (index) => {
    switch (index) {
      case 0:
        return <LinkTab key={index} label="Happy" href="/venue/reviews" />;
      case 1:
        return <LinkTab key={index} label="Unhappy" href="/venue/reviews" />;
      default:
        return null;
    }
  };

  const getTabPanel = (index, selectedValue) => {
    switch (index) {
      case 0:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <CustomerReviewsTab
              {...props}
              tabName={"happy"}
              coReviewCardListData={props.coReviewCardListHappyData}
              showAsTab={true}
            />
          </TabPanel>
        );
      case 1:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <CustomerReviewsTab
              {...props}
              tabName={"Unhappy"}
              coReviewCardListData={props.coReviewCardListUnHappyData}
              showAsTab={true}
            />
          </TabPanel>
        );

      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavigationBar title={"Customer Reviews"} hasBackIcon={true} />
      <CoTabs
        tabCount={2}
        initialTabIndex={getIntialTabIndex(props)}
        getLinkTab={getLinkTab}
        getTabPanel={getTabPanel}
      />
    </div>
  );
};

const requestVenueReviews = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId },
    },
    requestCustomerReviewList,
  } = props;

  if (venueId !== undefined) {
    requestCustomerReviewList({ venueId: venueId });
  }
};

const mapVenueReviewsToUiTemplate = (props) => {
  const {
    customerReviews: { customer_review_list: newCustomerReviewList = [] },
  } = props;

  const response = { happy: [], unhappy: [] };
  newCustomerReviewList.forEach((review) => {
    if (review["rating"] <= 40) {
      response.unhappy.push(review);
    } else {
      response.happy.push(review);
    }
  });

  return {
    coTableData: mapReviewListToCoTable(newCustomerReviewList),
    coReviewCardListHappyData: mapReviewListToCoReviewCardList(response.happy),
    coReviewCardListUnHappyData: mapReviewListToCoReviewCardList(
      response.unhappy
    ),
    coReviewCardListData: mapReviewListToCoReviewCardList(
      newCustomerReviewList
    ),
  };
};

const initialUiTemplate = {
  coTableData: {},
  coReviewCardListData: {},
  coReviewCardListHappyData: {},
  coReviewCardListUnHappyData: {},
};

const withApiData = (WrappedComponent) =>
  HigherOrder.withSinglePageApiCall(WrappedComponent, {
    propInitialUiTemplate: initialUiTemplate,
    propMakeSinglePageApiCall: requestVenueReviews,
    propMapToUiTemplate: mapVenueReviewsToUiTemplate,
  });

const withMockedUi = (WrappedComponent) =>
  HigherOrder.withMockUiCall(WrappedComponent, {
    propInitialUiTemplate: UiMocks.initialUiState,
    propUiMockedTemplate: UiMocks.mockedUiState,
  });

const ComposedCustomerReviews = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withNotifcationHandler,
  // withMockedUi
  withApiData
)(CustomerReviewsScreen);

const mapStateToProps = (state) => ({
  customerReviews: subscribeToCustomerReviews(state),
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_CUSTOMER_REVIEW_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestCustomerReviewList: (params) =>
    dispatch(requestCustomerReviewList({ ...params })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedCustomerReviews);
