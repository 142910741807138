import { SET_UPCOMING_EVENT_LIST, RESET_UPCOMING_EVENT_LIST } from "./actions";
import { REQUEST_ACTION_RESET_ALL_STATES } from "../../../common/states/actions.request";

const initialState = {
  upcoming_events: {
    event_list: [],
    pagination: {},
  },
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPCOMING_EVENT_LIST:
      return Object.assign({}, state, {
        ...state.events,
        upcoming_events: {
          event_list: action.upcomingEventList,
          pagination: action.pagination,
        },
      });

    case RESET_UPCOMING_EVENT_LIST:
      return Object.assign({}, state, {
        ...state.events,
        upcoming_events: initialState.events.upcoming_events,
      });

    case REQUEST_ACTION_RESET_ALL_STATES:
      return Object.assign({}, state, {
        ...initialState,
      });

    default:
      return state;
  }
};

export default events;

export const subscribeToEvents = (state) => state.events;
