import moment from "moment";

export const formatJsDate = ({ dateObject, format }) => {
  if (dateObject === null || dateObject === "") {
    return null;
  }

  const date = moment(dateObject);
  return date.format(format);
};

export const formatJsDateForRecee = (dateObject) => {
  return formatJsDate({ dateObject: dateObject, format: "Do MMM" });
};

export const formatJsDateForReview = (dateObject) => {
  return formatJsDate({ dateObject: dateObject, format: "Do MMM yyyy" });
};

export const capitalize = (string) => {
  string = string.trim();
  const capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
  return capitalizedString;
};

export const getCurrentMonthById = () => {
  return moment().format("M");
};

export const getCurrentDate = (format) => {
  return moment().format(format);
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const previousYear = (format) => {
  return moment().subtract(1, "year").format(format);
};

export const addDays = (startDate, difference) => {
  return moment(startDate).add(difference, "days").toDate();
};

export const getDifferenceInDays = (date1, date2) => {
  return moment(date1).diff(date2, "days");
};

export const getValidYearByMonthId = (monthId) => {
  const currentDateMonth = getCurrentDate("M");
  if (monthId > currentDateMonth) {
    return previousYear("yyyy");
  } else {
    return getCurrentDate("yyyy");
  }
};

export const getStartOfMonth = ({
  month,
  year = null,
  format = "yyyy-MM-DD",
}) => {
  const yr = year !== null ? year : getCurrentDate("yyyy");
  return moment({ month: month, year: yr }).startOf("month").format(format);
};

export const getEndOfMonth = ({
  month,
  year = null,
  format = "yyyy-MM-DD",
}) => {
  const yr = year !== null ? year : getCurrentDate("yyyy");
  return moment({ month: month, year: yr }).endOf("month").format(format);
};

export const formatINRCurrency = (value) => {
  if (value === undefined) return null;

  return (
    value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }) ?? "NA"
  );
};

export const hasDecimalPlaces = (val) => val % 1 !== 0;

// Detects if device is on iOS
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
// Detects if device is in standalone mode
export const isInStandaloneMode = () =>
  isIos()
    ? "standalone" in window.navigator && window.navigator.standalone
    : window.matchMedia("(display-mode: standalone)").matches;

export const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};
