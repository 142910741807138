import { RecceService } from "../../../data/VenueManagementService";
import RequestAction from "../../../common/states/actions.request";

export const SET_UPCOMING_RECCE_LIST = "SET_UPCOMING_RECCE_LIST";
export const RESET_UPCOMING_RECCE_LIST = "RESET_UPCOMING_RECCE_LIST";
export const REQUEST_KEY_UPCOMING_RECCE_LIST = "rk_upcoming_recce_list";

export const requestUpcomingRecceList = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait while we fetch upcoming visits.",
      key: REQUEST_KEY_UPCOMING_RECCE_LIST,
    })
  );

  const { venueId } = params;

  try {
    const upcomingRecceList = await RecceService.getUpcomingRecceList({
      venueId,
    });

    dispatch({
      type: SET_UPCOMING_RECCE_LIST,
      upcomingRecceList: upcomingRecceList,
      pagination: {},
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_UPCOMING_RECCE_LIST,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_UPCOMING_RECCE_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while getting upcoming visits from RecceService", err);
  }
};

export const sendVoMessage = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait while we fetch upcoming visits.",
      key: REQUEST_KEY_UPCOMING_RECCE_LIST,
    })
  );

  const { venueId, receeId, voMessage } = params;

  try {
    const upcomingRecceList = await RecceService.sendVoMessage({
      venueId,
      receeId,
      voMessage,
    });
    console.log(upcomingRecceList)
    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_UPCOMING_RECCE_LIST,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
    return upcomingRecceList.data;
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_UPCOMING_RECCE_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while updating upcoming visits from RecceService", err);
  }
};