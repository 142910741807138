import React from "react";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

import Iframe from "react-iframe";
import NavigationBar from "../../routes/NavigationBar";
import {
  ElevatedContainerPrimaryButton,
  ElevatedContainerText2,
} from "../login/components/LoginBackground";
import Credentials from "../../data/sources/api/credentials";

class UserManualScreen extends React.Component {
  componentDidMount() {
    this.openManual();
  }

  openManual() {
    window.open(Credentials.user_manual_url);
  }

  render() {
    return (
      <div>
        <NavigationBar
          title={"User Manual"}
          hasBackIcon={true}
          hasDrawerIcon={true}
        />

        <div
          style={{
            margin: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div style={{ marginLeft: "10px" }}>
            <ElevatedContainerText2
              value={
                "Click on the button below to access user manual for the app"
              }
            />
          </div>
          <div style={{ alignSelf: "flex-start" }}>
            <ElevatedContainerPrimaryButton
              value={"User Manual"}
              onPrimaryButtonClicked={() => {
                this.openManual();
              }}
            />
          </div>
        </div>
        {/* <Iframe
          position="absolute"
          url={
            "https://docs.google.com/presentation/d/1gcVeJkPTlp2GxXr5aUijTGKOxVA-Ve6Y1pNjjXghx2s/edit?usp=sharing"
          }
          width="100%"
          id="myId"
          className="myClassname"
          height="100%"
          styles={{ height: "25px" }}
        /> */}
      </div>
    );
  }
}

const ComposedUserManualScreen = compose(withRouter)(UserManualScreen);
export default ComposedUserManualScreen;
