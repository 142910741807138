import * as firebase from "firebase/app";
import FirebaseConfig from "../../../config/firebaseConfig";
import "firebase/messaging";

let firebaseMessaging;
const notificationListeners = {};

function setup() {
  FirebaseConfig.setup();

  try {
    console.log("NOTIFICATION : Initialising messaging from notification");
    firebaseMessaging = firebase.messaging();
    firebaseMessaging.usePublicVapidKey(FirebaseConfig.firebaseVapidKey);
    console.log(
      "NOTIFICATION :",
      "Is firebaseMessaging null ?",
      firebaseMessaging === undefined
    );
  } catch (err) {
    console.error("NOTIFICATION : Initialising messaging threw an error", err);
    firebaseMessaging = null;
  }
}

const getDeviceRegistrationToken = async () => {
  if (firebaseMessaging === undefined) {
    console.log("NOTIFICATION : Firebase Cloud Messaging not initialised");
    return undefined;
  }

  try {
    const currentToken = await firebaseMessaging?.getToken();
    // console.log("NOTIFICATION : Registration Token", currentToken);
    return currentToken;
  } catch (err) {
    console.error("NOTIFIICATION : ", "Error while calling getToken", err);
    return undefined;
    //   if (err.code === "messaging/token-unsubscribe-failed") {
    //     throw Error({
    //       code: err.code,
    //       message: `Something went wrong. Please contact support team. ${err.code}`,
    //     });
    //   }

    //   throw Error({
    //     code: err.code,
    //     message: `Something went wrong. Please contact support team. ${err.code}`,
    //   });
  }
};

const onMessageReceived = (payload) => {
  console.log(
    "NOTIFICATION :",
    "Message Received",
    payload,
    notificationListeners
  );
  Object.keys(notificationListeners).forEach((key) => {
    notificationListeners[key].onMessageReceived(payload);
  });
};

const onUpdateToken = (token) => {
  console.log("NOTIFICATION :", "token updated", token, notificationListeners);
  Object.keys(notificationListeners).forEach((key) => {
    notificationListeners[key].onUpdateToken(token);
  });
};

const registerNotificationListener = (id, listener) => {
  if (firebaseMessaging === undefined) {
    console.log(
      "NOTIFICATION : registerNotificationListener Firebase Cloud Messaging not initialised"
    );
    return;
  }

  const prevListenerCount = Object.keys(notificationListeners).length;
  notificationListeners[id] = listener;

  //  We have just added our first listener. Start listening to notifications
  const currentListenerCount = Object.keys(notificationListeners).length;
  if (prevListenerCount === 0 && currentListenerCount === 1) {
    // console.log(
    //   "NOTIFICATION :",
    //   "adding cloud message foreground listener as we have some listeners"
    // );
    if (firebaseMessaging !== undefined && firebaseMessaging !== null) {
      firebaseMessaging.onMessage(onMessageReceived);
      firebaseMessaging.onTokenRefresh(() => {
        firebaseMessaging
          .getToken()
          .then(onUpdateToken)
          .catch((err) => {
            console.log("Unable to retrieve refreshed token ", err);
          });
      });
    }
  }
};

const unregisterNotificationListener = (id) => {
  const prevListenerCount = Object.keys(notificationListeners).length;
  delete notificationListeners[id];
  const currentListenerCount = Object.keys(notificationListeners).length;

  if (prevListenerCount === 1 && currentListenerCount === 0) {
    console.log(
      "NOTIFICATION :",
      "removing cloud message foreground listener as no one is listening"
    );
    firebaseMessaging.onMessage(null);
  }
};

export default {
  setup: setup,
  getDeviceRegistrationToken: getDeviceRegistrationToken,
  registerNotificationListener: registerNotificationListener,
  unregisterNotificationListener: unregisterNotificationListener,
};
