import React from "react";

import { Typography, createStyles, makeStyles } from "@material-ui/core";
import { CoElevatedPanel, CoNonElevatedPanel } from "../CoPanel";

import DoneIcon from "@material-ui/icons/Done";
import CoHotelIcon from "../../assets/icons/co_hotel_icon_2.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.11,
    },
    subTitle: {
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.54,
      lineHeight: 1.25,
      marginTop: "4px",
    },
  })
);

const styles = {
  circleImage: {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  selectedCircleImage: {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    backgroundColor: "#1ab64f",
  },
};

export const CoCircleImage = (props) => {
  return (
    <div style={styles.circleImage}>
      <img
        src={CoHotelIcon}
        color="#000000"
        style={{ color: "#000000", width: "22px", height: "22px" }}
        alt="venue"
      />
    </div>
  );
};

const CoSelectedCircleImage = (props) => {
  return (
    <div
      style={Object.assign({}, styles.selectedCircleImage, {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <DoneIcon style={{ color: "white" }} />
    </div>
  );
};

const PanelWrapper = (props) => {
  const { elevated = false, children, onClick } = props;

  return elevated ? (
    <CoElevatedPanel onClick={onClick} style={{ marginBottom: "20px" }}>
      {children}
    </CoElevatedPanel>
  ) : (
    <CoNonElevatedPanel onClick={onClick} style={{ marginBottom: "20px" }}>
      {children}
    </CoNonElevatedPanel>
  );
};

export const CoCardTemplateFive = (props) => {
  const { id, title, subTitles = [], elevated } = props;
  const classes = useStyles();

  let index = 0;

  return (
    <PanelWrapper {...props} elevated={false}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          <CoCircleImage {...props} classes={classes} />
        </div>
        <div style={{ flex: 6 }}>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {subTitles.map((subTitle) => {
            index += 1;
            return (
              <Typography
                key={`${subTitle}${index}`}
                variant="h6"
                className={classes.subTitle}
              >
                {subTitle}
              </Typography>
            );
          })}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {elevated ? <DoneIcon style={{ color: "#1ab64f" }} /> : null}
        </div>
      </div>
    </PanelWrapper>
  );
};

export const CoCardTemplateFiveList = (props) => {
  const { rows = [], onClick } = props;

  const classes = useStyles();
  let index = 0;

  return rows.map((row) => {
    index += 1;
    return (
      <CoCardTemplateFive
        onClick={() => {
          onClick(row.id);
        }}
        id={index}
        key={index}
        {...row}
        classes={classes}
      />
    );
  });
};
