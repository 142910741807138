import { parseDate } from "../../common/utils/RestUtils";

export const mapVenueReviewResponse = (response) => {
  const {
    event,
    rating,
    bookingDate: booking_date,
    eventDate: event_date,
    name: customer_name,
    review: customer_comment,
    pax,
  } = response;

  return {
    event,
    rating,
    customer_name,
    customer_comment,
    pax,
    event_date: parseDate({ dateString: event_date }),
    booking_date: parseDate({ dateString: booking_date }),
  };
};

export const mapVenueReviewListRespone = (responseList = []) => {
  return responseList.map((reviewResponse) =>
    mapVenueReviewResponse(reviewResponse)
  );
};

export const mapVenueOverallRatingApiResponse = (ratings = {}) => {
  const {
    average: average_rating,
    delights: delighted_user_percentage,
    nonDelights: unhappy_user_percentage,
  } = ratings;

  return {
    average_rating,
    delighted_user_percentage,
    unhappy_user_percentage,
  };
};

export const mapVenueReviewApiResponse = (response) => {
  const {
    data: { totalCount: total_count, reviews, venueId: venue_id },
  } = response;

  return {
    venue_id,
    total_count,
    review_list: mapVenueReviewListRespone(reviews),
  };
};
