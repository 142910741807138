import React from "react";

import { subscribeToUser } from "../venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import { connect } from "react-redux";

import RequestState from "../../common/states/actions.request";

import { requestUserState, REQUEST_KEY_SPLASH_SCREEN } from "./states/actions";
import { CoLogo } from "../../components/Logo";
import { CoCircularProgressBar } from "../../components/CoCircularProgressBar";
import { compose } from "redux";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import SplashTimer from "./components/SplashTimer";
import { getVenueIdFromPath } from "../../routes";

export const TIMEOUT_IN_SECONDS = 1000;

const styles = {
  container: {
    // backgroundImage: "linear-gradient(151deg, #d11450 0%, #ef4123 100%)",
    backgroundColor: "#212121",
  },
};

const getTargetRouteProps = (props) => {
  const {
    location: {
      state: {
        target: {
          pathname = undefined,
          search = undefined,
          params = undefined,
        } = {},
      } = {},
    } = undefined,
  } = props;

  return {
    pathname: pathname ?? getDefaultVenueDashboardPath(props),
    search: search,
    params: params,
  };
};

const isUserStateInitialized = (props) => {
  const {
    user: { selected_venue: selectedVenue },
    api: { request_state: requestState },
  } = props;

  const { pathname, search, params } = getTargetRouteProps(props);

  const isInitialized =
    "venue_id" in (selectedVenue === null ? {} : selectedVenue) &&
    requestState !== RequestState.STATE.LOADING;

  if (!isInitialized) return false;

  const { venue_id: selectedVenueId } = selectedVenue;
  const pathVenueId = getVenueIdFromPath(pathname);

  return isInitialized && pathVenueId === selectedVenueId;
};

const getDefaultVenueDashboardPath = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId },
    },
  } = props;

  return `/venue/${venueId}/dashboard`;
};

//  Checks if all steps have been completed. Returns true
//  if all completed, false otherwise
const hasAllStepsCompleted = (state) => {
  const { steps } = state;

  return Object.keys(steps)
    .map((key) => steps[key].completed)
    .reduce((step1, step2) => step1 && step2);
};

const initializeSteps = {
  steps: {
    timeout: {
      completed: false,
      success: undefined,
    },
    userState: {
      completed: false,
      success: undefined,
    },
  },
};

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //  Contains a list of entries for every step that must be performed
      //  to initialize the app. We will redirect from Splash screen when
      //  all these steps are completed.
      ...initializeSteps,
      message: undefined,
    };
  }

  componentDidMount() {
    const { requestUserState } = this.props;
    const targetProps = getTargetRouteProps(this.props);

    //  if user state is not present in redux, get it from local storage
    if (isUserStateInitialized(this.props) === false) {
      requestUserState(targetProps);
    } else {
      this.setState(this.updateUserStateStepInState);
    }

    const updateTimeoutStepInState = (prevState) => ({
      steps: {
        ...prevState.steps,
        timeout: {
          completed: true,
          success: true,
        },
      },
    });

    //  Create a timer for x seconds. When completed, mark it as completed
    //  in local state
    SplashTimer.createSplashTimer({
      seconds: TIMEOUT_IN_SECONDS,
      onComplete: () => this.setState(updateTimeoutStepInState),
    });
  }

  updateUserStateStepInState = (prevState) => ({
    steps: {
      ...prevState.steps,
      userState: {
        completed: true,
        success: true,
      },
    },
  });

  shouldComponentUpdate(nextProps, nextState) {
    if (RequestState.hasCompletedSuccessfully(this.props, nextProps)) {
      this.setState(this.updateUserStateStepInState);
      return false;
    }

    if (hasAllStepsCompleted(nextState)) {
      this.navigate(this.props);
      return false;
    }

    return true;
  }

  navigate(props) {
    const { replacePath } = props;
    const { pathname, search, params } = getTargetRouteProps(props);

    if (isUserStateInitialized(props)) {
      replacePath(pathname, {
        search: search,
        params: params,
      });
      return;
    }

    replacePath("/login");
  }

  render() {
    const isLoading = !hasAllStepsCompleted(this.state);
    const { message } = this.state;

    return (
      <div
        style={Object.assign({}, styles.container, {
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
        })}
      >
        <div>
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              width: "90px",
              height: "90px",
              borderRadius: "0 0 0px 90px",
              backgroundColor: "#303030",
            }}
          />

          <div
            style={{
              bottom: "0px",
              left: "0",
              position: "absolute",
              width: "90px",
              height: "90px",
              borderRadius: "0 90px 0 0",
              backgroundColor: "#303030",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "100px",
          }}
        >
          <CoLogo />
        </div>
        {message ? (
          <p
            style={{
              position: "absolute",
              bottom: 0,
              marginBottom: "20px",
              color: "white",
            }}
          >
            {message}
          </p>
        ) : null}
        {isLoading ? <CoCircularProgressBar /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_SPLASH_SCREEN),
});

const mapDispatchToProps = (dispatch) => ({
  requestUserState: (params) => dispatch(requestUserState(params)),
});

const ComposedSplashScreen = compose(withHistoryObject)(SplashScreen);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedSplashScreen);
