import { VenueOrderService } from "../../../data/VenueManagementService";
import RequestAction from "../../../common/states/actions.request";
import {
  BookingApproved, 
  BookingRejected
} from "../../../common/utils/constants"

export const SET_UPCOMING_EVENT_LIST = "SET_UPCOMING_EVENT_LIST";
export const RESET_UPCOMING_EVENT_LIST = "RESET_UPCOMING_EVENT_LIST";
export const REQUEST_KEY_UPCOMING_EVENT_LIST = "rk_upcoming_event_list";

export const requestUpcomingEventList = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait while we fetch upcoming events",
      key: REQUEST_KEY_UPCOMING_EVENT_LIST,
    })
  );

  const { venueId } = params;

  try {
    const upcomingEventsResponse = await VenueOrderService.getUpcomingEvents({
      venueId: venueId,
    });

    dispatch({
      type: SET_UPCOMING_EVENT_LIST,
      upcomingEventList: upcomingEventsResponse.event_list,
      pagination: upcomingEventsResponse.pagination,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_UPCOMING_EVENT_LIST,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_UPCOMING_EVENT_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error(
      "Error while getting upcoming events from VenueOrderService",
      err
    );
  }
};

export const updateBookingStatus = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait while we update booking status",
      key: REQUEST_KEY_UPCOMING_EVENT_LIST,
    })
  );

  const { venueId, documentUrl, oldStatus, newStatus, rejection_reason,  upcomingEvents, booking_id } = params;

  try {
    const bookingUpdateResponse = await VenueOrderService.updateBookingStatus({
      venueId,
      documentUrl,
      oldStatus,
      newStatus,
      rejection_reason,
    });

    if([BookingApproved, BookingRejected].includes(bookingUpdateResponse.data)){
      upcomingEvents["event_list"].forEach((booking)=>{
        if(booking['booking_id'] == booking_id){
          booking['vo_status'] = (newStatus == "Approve") ? "Approved" : "Rejected" ;
        } 
      });

      dispatch({
        type: SET_UPCOMING_EVENT_LIST,
        upcomingEventList: upcomingEvents.event_list,
        pagination: upcomingEvents.pagination,
      });

    }

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_UPCOMING_EVENT_LIST,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
   return bookingUpdateResponse.data;
    
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_UPCOMING_EVENT_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error(
      "Error while getting upcoming events from VenueOrderService",
      err
    );
  }
};