import keyValueStore from "../../../data/sources/store/keyValue";
import { UserLoginService } from "../../../data/VenueManagementService";

import RequestAction, {
  REQUEST_STATE_STAND_BY,
  REQUEST_STATE_UNDEFINED,
  REQUEST_ACTION_RESET_ALL_STATES,
  setRequestStateFor,
} from "../../../common/states/actions.request";
import {
  RESET_USER_STATE,
  SET_USER_STATE,
  REQUEST_KEY_USER_VENUE_LIST,
} from "../../venueSelection/states/actions";
import { getVenueIdFromPath } from "../../../routes";
import { addDays, getDifferenceInDays } from "../../../common/utils";
import spectator from "../../../data/sources/analytics/spectator";

export const REQUEST_KEY_SPLASH_SCREEN = "rk_splash_screen_ops";
const sessionDurationInDays =
  process.env.REACT_APP_USER_SESSION_DURATION_IN_DAYS;

const getVenueByIdFromState = (venueId, state) => {
  const { user_venue_list: venueList = [] } = state;

  const filteredVenueList = venueList.filter(
    (venue) => venue["venue_id"] === venueId
  );

  if (filteredVenueList.length !== 1) return null;
  return filteredVenueList[0];
};

const hasSessionExpired = (userState) => {
  console.log("SPLASH", "checking if session has expired", userState);

  if (userState === undefined) return true;

  const {
    logged_in_user: { visit_date: lastVisitDate },
  } = userState;

  if (lastVisitDate === undefined) return false;

  const sessionExpiryDate = addDays(lastVisitDate, sessionDurationInDays);
  const daysToSessionExpiry =
    getDifferenceInDays(sessionExpiryDate, new Date()) < 0;

  console.log("SPLASH", "hasSessionExpired : ", daysToSessionExpiry);
  return daysToSessionExpiry;
};

export const requestUserState = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_SPLASH_SCREEN,
    })
  );

  const { pathname } = params;
  const venueId = getVenueIdFromPath(pathname);

  let userState = await keyValueStore.getValue("USER_STATE");
  //  Check if the sessiion has expired.
  //  If it has then reset user state
  const sessionExpired = hasSessionExpired(userState);

  if (userState === undefined || sessionExpired === true) {
    dispatch({
      type: RESET_USER_STATE,
    });
  } else {
    const {
      logged_in_user: { auth_token: authToken },
    } = userState;

    UserLoginService.setAuthTokenInAllRequests({ authToken: authToken });

    //  If venueId in path is not undefined then update venue.
    if (venueId !== undefined) {
      const venue = getVenueByIdFromState(venueId, userState);

      dispatch(
        setRequestStateFor({
          request_state: REQUEST_STATE_UNDEFINED,
          update_for_except: [
            REQUEST_KEY_USER_VENUE_LIST,
            REQUEST_KEY_SPLASH_SCREEN,
          ],
        })
      );

      dispatch({
        type: REQUEST_ACTION_RESET_ALL_STATES,
      });
      userState = Object.assign({}, userState, { selected_venue: venue });
    }

    userState = Object.assign({}, userState, {
      logged_in_user: {
        ...userState.logged_in_user,
        visit_date: new Date(),
      },
    });

    const {
      logged_in_user: { phone_number: phoneNumber },
    } = userState;
    spectator.addUserProperty("phone_number", phoneNumber);
    spectator.setUserId(phoneNumber);

    dispatch({
      type: SET_USER_STATE,
      user: userState,
    });
  }

  dispatch(
    RequestAction.setRequestState({
      key: REQUEST_KEY_SPLASH_SCREEN,
      request_state: REQUEST_STATE_STAND_BY,
    })
  );
};

export const requestSelectedVenueById = (venueId) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_SPLASH_SCREEN,
    })
  );

  const userState = await keyValueStore.getValue("USER_STATE");
  if (userState === undefined) {
    dispatch({
      type: RESET_USER_STATE,
    });
  }
};
