import React from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import ContainerDimensions from "react-container-dimensions";

export function AlertDialog(props) {
  const { open, handleClose, title = "NA", description = "NA" } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;

export const CoAlertDialog = (props) => {
  const {
    handleClose,
    handleButton1Clicked,
    open,
    message,
    buttonText1 = undefined,
    buttonText2 = "Close",
  } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent style={{ padding: "0px" }}>
        <DialogContentText
          style={{
            padding: "20px",
            fontFamily: "Brown-Bold",
            opacity: 0.87,
            color: "#242b2e",
            lineHeight: 1.5,
            textAlign: "center",
          }}
          id="alert-dialog-description"
        >
          {message}
        </DialogContentText>
        <div
          style={{
            backgroundColor: "#000000",
            opacity: 0.06,
            height: "1px",
          }}
        />
      </DialogContent>
      <DialogActions style={{ padding: "0px" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          {buttonText1 !== undefined && handleButton1Clicked !== undefined ? (
            <Button
              style={{
                fontFamily: "Brown-Bold",
                color: "#000000",
                opacity: 0.54,
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={handleButton1Clicked}
              color="primary"
            >
              {buttonText1}
            </Button>
          ) : null}
        </div>
        <ContainerDimensions>
          {({ height }) => (
            <div
              style={{
                height: height,
                backgroundColor: "#000000",
                opacity: 0.06,
                width: "1px",
              }}
            />
          )}
        </ContainerDimensions>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          {buttonText2 !== undefined && handleClose !== undefined ? (
            <Button
              style={{
                fontFamily: "Brown-Bold",
                color: "#000000",
                opacity: 0.54,
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={handleClose}
              color="primary"
            >
              {buttonText2}
            </Button>
          ) : null}
        </div>
      </DialogActions>
    </Dialog>
  );
};
