import { BrowserRouter } from "react-router-dom";
import React from "react";
import VenueDashboard from "../modules/venueDashboard";
import CustomerReviews from "../modules/reviews";
import Splash from "../modules/splash";
import LoginPhoneNumber from "../modules/login";
import LoginOtp from "../modules/login/LoginOtp";
import VenueSelection from "../modules/venueSelection";
import BusinessMetrics from "../modules/businessMetrics";
import BMBookingDetails from "../modules/businessMetrics/bookingDetails";
import BMRecceDetails from "../modules/businessMetrics";
import PastBusinessMetrics from "../modules/businessMetrics/pastBusinessMetrics";
import UpcomingEvents from "../modules/events/upcomingEvents";
import UpcomingReccees from "../modules/recces/upcomingRecces";
import LogoutScreen from "../modules/login/LogoutUser";
import Settings from "../modules/settings";
import CCfScreen from "../modules/events/ccfScreen";
import UserManualScreen from "../modules/faq/userManualScreen";
import VenueCalendarScreen from "../modules/calendar";
import VenueCalendarDetails from "../modules/calendar/venueCalendarDetails";

export const getDrawerRoutes = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId },
    },
  } = props;

  const venueRoute = createVenuePath(props);

  return [
    {
      path: `${venueRoute}/dashboard?drawer=true`,
      sidebarName: "Venue Dashboard",
    },
    {
      path: `${venueRoute}/metrics?drawer=true`,
      sidebarName: "Performance Report",
    },
    {
      path: `${venueRoute}/recces/upcoming?drawer=true`,
      sidebarName: "Upcoming Visits",
    },
    {
      path: `${venueRoute}/events/upcoming?drawer=true`,
      sidebarName: "Upcoming Events",
    },
    {
      path: `${venueRoute}/calendar?drawer=true`,
      sidebarName: "Event Calendar",
    },
    {
      path: `${venueRoute}/reviews?drawer=true`,
      sidebarName: "Customer Reviews",
    },
    // {
    //   path: "/home",
    //   sidebarName: "Install App",
    // },
    {
      path: "/faq/manual?drawer=true",
      sidebarName: "User Manual",
    },
    {
      path: `/logout?drawer=true`,
      sidebarName: "Logout",
    },
  ];
};

export const Routes = [
  {
    path: "/",
    component: Splash,
  },
  {
    path: "/login",
    component: LoginPhoneNumber,
  },
  {
    path: "/login/phone",
    component: LoginPhoneNumber,
  },
  {
    path: "/login/otp",
    component: LoginOtp,
  },
  {
    path: "/venue/dashboard",
    component: VenueDashboard,
  },
  {
    path: "/venue/:venueId/dashboard",
    component: VenueDashboard,
  },
  {
    path: "/venue/:venueId/recces/upcoming",
    component: UpcomingReccees,
  },
  {
    path: "/venue/:venueId/events/upcoming",
    component: UpcomingEvents,
  },
  {
    path: "/venue/:venueId/reviews",
    component: CustomerReviews,
  },
  {
    path: "/venue/:venueId/metrics",
    component: BusinessMetrics,
  },
  {
    path: "/venue/:venueId/metrics/bookings",
    component: BMBookingDetails,
  },
  {
    path: "/venue/:venueId/metrics/recces",
    component: BMRecceDetails,
  },
  {
    path: "/venue/:venueId/metrics/past",
    component: PastBusinessMetrics,
  },
  {
    path: "/logout",
    component: LogoutScreen,
  },
  {
    path: "/venues",
    component: VenueSelection,
  },
  {
    path: "/faq/manual",
    component: UserManualScreen,
  },
  // {
  //   path: "/settings",
  //   component: Settings,
  // },
  {
    path: "/venue/:venueId/events/ccf",
    component: CCfScreen,
  },
  {
    path: "/venue/:venueId/calendar",
    component: VenueCalendarScreen,
  },
  {
    path: "/venue/:venueId/calendar/:date/details",
    component: VenueCalendarDetails,
  },
];

const Router = ({ children }) => <BrowserRouter>{children}</BrowserRouter>;

export const getVenueIdFromPath = (relativePath) => {
  const PATH_STRING_VENUE = "venue";
  const PATH_STRING_BACKSLASH = "/";

  if (!relativePath) {
    return undefined;
  }

  if (!relativePath.includes(PATH_STRING_VENUE)) {
    return undefined;
  }

  const venueIdStartingIndex =
    relativePath.indexOf(PATH_STRING_VENUE + PATH_STRING_BACKSLASH) +
    PATH_STRING_VENUE.length +
    PATH_STRING_BACKSLASH.length;

  const newPath = relativePath.substr(venueIdStartingIndex);
  const venueIdEndingIndex = newPath.indexOf("/");

  const venueId = newPath.substr(0, venueIdEndingIndex);
  var regex = /\d/g;
  if (!regex.test(venueId)) return undefined;

  return venueId !== "" ? venueId : undefined;
};

export const getCalendarDateFromPath = (relativePath) => {
  const PATH_STRING_CALENDAR = "calendar";
  const PATH_STRING_BACKSLASH = "/";

  if (!relativePath) {
    return undefined;
  }

  if (!relativePath.includes(PATH_STRING_CALENDAR)) {
    return undefined;
  }

  const calendarDateStartingIndex =
    relativePath.indexOf(PATH_STRING_CALENDAR + PATH_STRING_BACKSLASH) +
    PATH_STRING_CALENDAR.length +
    PATH_STRING_BACKSLASH.length;

  const newPath = relativePath.substr(calendarDateStartingIndex);
  const calendarDateEndingIndex = newPath.indexOf("/");

  const calendarDate = newPath.substr(0, calendarDateEndingIndex);
  var regex = /\d/g;
  if (!regex.test(calendarDate)) return undefined;

  return calendarDate !== "" ? calendarDate : undefined;
};

export const createVenuePath = (props) => {
  const {
    user: { selected_venue: { venue_id: venueId = undefined } = {} } = {},
  } = props;

  return venueId === undefined ? `/venue` : `/venue/${venueId}`;
};

export default Router;
