import * as firebase from "firebase/app";
import FirebaseConfig from "../../../config/firebaseConfig";
import "firebase/analytics";

let firebaseAnalytics;

function setup() {
  FirebaseConfig.setup();
  try {
    console.log("SPECTATOR : Initialising analytics from spectator");
    firebaseAnalytics = firebase.analytics();
  } catch (err) {
    console.error("SPECTATOR : Initialising analytics threw an error", err);
    firebaseAnalytics = null;
  }
}

function setUserId(userId) {
  if (firebaseAnalytics === null) {
    console.log("SPECTATOR : Firebase analytics not initialised");
    return;
  }
  firebaseAnalytics.setUserId(userId);
}

function logEvent(eventName, params = undefined) {
  if (firebaseAnalytics === null) {
    console.log("SPECTATOR : Firebase analytics not initialised");
    return;
  }
  firebaseAnalytics.logEvent(eventName, params);
}

function logError(error) {
  // todo : add sentry or crashlytics error logging here
}

function addUserProperty(name, value) {
  if (firebaseAnalytics === null) {
    console.log("SPECTATOR : Firebase analytics not initialised");
    return;
  }
  firebaseAnalytics.setUserProperties({ [name]: value });
}

function setCurrentScreen(screenName) {
  if (firebaseAnalytics === null) {
    console.log("SPECTATOR : Firebase analytics not initialised");
    return;
  }
  firebaseAnalytics.setCurrentScreen(screenName);
}

export default {
  setup: setup,
  logEvent: logEvent,
  logError: logError,
  setUserId: setUserId,
  addUserProperty: addUserProperty,
  setCurrentScreen: setCurrentScreen,
};
