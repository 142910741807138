import {
  formatJsDateForRecee,
  capitalize,
  formatJsDateForReview,
  formatINRCurrency,
  formatJsDate,
} from "../../../common/utils";
import SimpleDialogListPopup from "../../../components/SimpleDialogListPopup";
import React from 'react';

const formatEventSlots = (slotString, defaultValue = null) => {
  //   console.log("slots", slotString);

  if (slotString == null) return defaultValue;

  slotString = slotString.replace("[", "");
  slotString = slotString.replace("]", "");

  const updatedSlotString = slotString
    .split(", ")
    // .map((slot) => slot.toLowerCase())
    .map((slot) => capitalize(slot.split("-")[0]) )
    .join(", ");

  return updatedSlotString;
};

const formatEventDate = (eventDate) => {
  return formatJsDateForRecee(eventDate);
};

const getFromattedBookingStatus = (event_date) => {
  // switch (status) {
  //   case "CANCELED":
  //     return "Cancelled";

  //   default:
  //     return capitalize(status?.toLowerCase());
  // }
  return isEventExpired(event_date) ? "Consumed" : "Active" ;
};

const splitEventSlotsIntoLabels = (slotsString) => {
  return slotsString.split(",").map((slot) => {
    return {
      type: "bodyLabel1",
      value: slot,
    };
  });
};

const mapBookingToTemplateSixRowItem = (
  { id, name, onCCfButtonClicked, onStatusChange },
  booking,
  showApproveReject
) => {
  return {
    id: id,
    name: name,
    data: {
      type: "template_six",
      data: {
        header: {
          title: `${booking["customer_name"]?.replace("null", "")}`,
          title2: `${formatINRCurrency(booking["gmv"])}`,
          title3: `${booking["pax"]} Guests`,
        },
        footer: {
          value1: `Event date: ${formatJsDate({
            dateObject: booking["event_date"],
            format: "DD MMM yyyy",
          })}`,
          value2: `${getFromattedBookingStatus(booking["event_date"])}`,
        },
        body: {
          items: [
            // {
            //   type: "bodyRow1",
            //   kind: "label",
            //   items: [
            //     {
            //       type: "bodyText2",
            //       value: `GMV : ₹ ${booking["gmv"]}`,
            //     },
            //   ],
            // },
            // {
            //   type: "bodyRow1",
            //   kind: "label",
            //   items: [
            //     {
            //       type: "bodyText2",
            //       value: `Booking Date : ${formatJsDateForReview(
            //         booking["booking_date"]
            //       )}`,
            //     },
            //   ],
            // },
            // {
            //   type: "bodyRow1",
            //   kind: "label",
            //   items: [
            //     {
            //       type: "bodyText2",
            //       value: `Booked On ${formatJsDateForReview(
            //         booking["booking_date"]
            //       )}`,
            //     },
            //     // ...splitEventSlotsIntoLabels(
            //     //   formatEventSlots("[MORNING, EVENING]")
            //     // ),
            //   ],
            // },
            // {
            //   type: "bodyRow1",
            //   kind: "label",
            //   items: [
            //     {
            //       type: "bodyText2",
            //       value: `${booking["pax"]} Guests`,
            //     },
            //   ],
            // },
            {
              type: "bodyRow1",
              kind: "label",
              items: [
                booking["event_type"]
                  ? {
                      type: "bodyLabel1",
                      value: booking["event_type"]?.toUpperCase(),
                    }
                  : null,
                {
                  type: "bodyLabel1",
                  value: `${formatEventSlots(booking["event_slots"])}`,
                },
                booking["food_type"]
                  ? {
                      type: "bodyLabel1",
                      value: booking["food_type"]
                        ?.replace("[", "")
                        ?.replace("]", "")
                        ?.toUpperCase(),
                    }
                  : null,
                booking["is_alcohol_allowed"]
                  ? {
                      type: "bodyLabel1",
                      value: "ALCOHOL",
                    }
                  : null,
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                {
                  type: "bodyText1",
                  value: `Booking Amount → ${
                    formatINRCurrency(booking["booking_amount"]) ?? "NA"
                  }`,
                },
                // ...splitEventSlotsIntoLabels(
                //   formatEventSlots("[MORNING, EVENING]")
                // ),
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                {
                  type: "bodyText1",
                  value: `Booking Date → ${formatJsDateForReview(
                    booking["booking_date"]
                  )}`,
                },
                // ...splitEventSlotsIntoLabels(
                //   formatEventSlots("[MORNING, EVENING]")
                // ),
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                {
                  type: "bodyText1",
                  value: `Party Area → ${booking["party_area"]}`,
                },
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                booking["food_package"]
                  ? {
                      type: "bodyText1",
                      value: `Food Package → ${formatEventSlots(
                        booking["food_package"],
                        "NA"
                      )}`,
                    }
                  : null,
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                booking["service_fee"]
                  ? {
                      type: "bodyText1",
                      value: `Weddingz Service Fee → ${Math.round(booking["service_fee"])}`,
                    }
                  : null,
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                booking["ccf_url"]
                  ? {
                      type: "bodyButton1",
                      value: `Show CCF`,
                      item: booking,
                      onButtonClicked: onCCfButtonClicked,
                    }
                  : null,
                (booking["ccf_url"] && booking["older_CCFlist"]?.length > 1)
                ? {
                    type: "popupButton",
                    value: `Show Older`,
                    item: {
                      avatar: "document",
                      title: "Older CCFs",
                      itemList: getCCFlist(booking["older_CCFlist"]),
                    },
                    onButtonClicked: onCCfButtonClicked,
                }: null,
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                ( booking["vo_status"].toLowerCase() != "approved" && isEventExpired(booking["event_date"]) )?
                { 
                  type: 'bodyText1', 
                  value: `Booking Status → Date Passed`, 
                  }
                : {
                  type: "bodyText1",
                  value: `Booking Status → `,
                },

                { 
                  type: getBookingLabel(booking["vo_status"]), 
                  value: `${booking["vo_status"]}`, 
                },
              ],
            },
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                (showApproveReject && booking["vo_status"].toLowerCase() != "approved" && booking["ccf_url"] ) //&& !isEventExpired(booking["event_date"]))
                  ? ({
                      type: "approveRejectPopupButton",
                      value: `Change`,
                      item: {
                        title: "Choose Action",
                        contentText: "By approving, you undertake to conduct the event as per the attached Customer commitment form (CCF) containing the event details booked by the customer and agree to deliver the commitments mentioned in the CCF. You shall not accept any other commitment(s)/ request made by the customer either in written or verbal other than those mentioned in CCF. In the event of any deviation from CCF and /or under-delivery of the commitments, you shall be solely liable for all claims of the Customer and shall adequately compensate the customer. ",
                        radioList: ["Data is not correct", "No such booking made"],
                      },
                      onStatusChange: onStatusChange,
                      bookingDetails: booking,
                    }) 
                  : null,
              ],
            },
          ],
        },
      },
    },
  };
};

const getCCFlist= (older_CCFlist = []) => {
  let version = older_CCFlist.length;
  return older_CCFlist.map((CCF, index)=> {
    version-- ;
    if(index == 0){
      return {
        value: "Latest",
        returnValue: CCF,
      }
    }else{
      return{
        value: `Version ${version}`,
        returnValue: CCF,
      }
    }
  });
}

const getBookingLabel= (status='pending') =>{
  switch (status.toLowerCase()){
    case "approved":
      return "bodySuccessLable"
    case "rejected":
      return "bodyRejectLable"
    default:
      return "bodyPendingLable"
  }
}

const isEventExpired= (eventDate) => {
  const currentDate = new Date();
  return (currentDate > eventDate);
}

const getBookingList= (
  bookingList = [],
  status,
  count = false
)=>{
  if(status==="Na"){return count ? bookingList?.length : bookingList ;}
  const filteredBookingList = bookingList.filter((booking)=> booking['vo_status'].toLowerCase() == status);
  return count ? filteredBookingList.length : filteredBookingList ;
}

export const mapBookingListToTemplateSix = (
  bookingList = [],
  onCCfButtonClicked = () => {},
  onStatusChange = () => {},
  status="Na",
) => {
  let index = 0;
  const onStatusChangeUndefined = ()=>{}
  const showApproveReject = onStatusChange.toString() !== onStatusChangeUndefined.toString() 
  const filteredBookingList = getBookingList(bookingList, status, false);
  const templateData = filteredBookingList?.map((booking) => {
    const bookingId = booking["booking_id"];

    const templateSixRowItem = mapBookingToTemplateSixRowItem(
      {
        id: bookingId ?? index,
        name: bookingId ?? index,
        onCCfButtonClicked: onCCfButtonClicked,
        onStatusChange: onStatusChange,
      },
      booking,
      showApproveReject
    );

    index += 1;
    return templateSixRowItem;
  });

  return {
    type: "template_six",
    data: templateData,
  };
};

export const mapBookingSummaryToBookingDashboardCard = (bookingSummary = {} ) => {
  return {
    approved: bookingSummary['approved']?? "NA",
    rejected: bookingSummary['rejected']?? "NA",
    pending: bookingSummary['pending']?? "NA", 
  };
};

export const mapBookingListToBookingTabs = (
  bookingList = [],
  onClick = () => {},
) => {
  const onTabchange= (index) => {
    switch(index){
      case 0: {onClick("pending");break};
      case 1: {onClick("approved");break};
      case 2: {onClick("rejected");break};
      default: console.log(`tab don't exists for id => ${index}`); 
    };
    
  };
  return {
    type: "template_two",
    data: {
        onClick:onTabchange,
        items: [
          {
            id: 0,
            title: "Pending",
            value: getBookingList(bookingList, "pending", true),
          },
          {
            id: 1,
            title: "Approved",
            value: getBookingList(bookingList, "approved", true),
          },
          {
            id: 2,
            title: "Rejected",
            value: getBookingList(bookingList, "rejected", true),
          },
        ],
      },
  };
};