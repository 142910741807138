import React, {useState} from "react";
import ShowSnackbar from "../ShowSnackbar"

const withSnackBar = (WrappedComponent) => (props) => {
    const [snackbarData, setSnackbarData] = useState({ "open": false, "message": "", "severity": "success", "color": "success" });
    const handleSnackbarClose= ()=>{
      const {severity, color} = snackbarData;
      setSnackbarData({ "open": false, "message": "", "severity": severity, "color": color });
    }
  return (
      <>
        <WrappedComponent
        {...props}
        setSnackbarData={setSnackbarData}
        />
        <ShowSnackbar data={snackbarData} onClose={handleSnackbarClose}/>
    </>
  );
};

export default withSnackBar;
