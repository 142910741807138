import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import CoSection from '../../../components/CoSection';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[50],
    color: blue[600],
  },
  approveContainedRoot: {
    fontSize: "14",
    fontFamily: "Brown-Bold",
    textTransform: "none",
    borderRadius: "6px",
    paddingTop: "10px",
    paddingBottom: "6px",
    paddingRight: "25px",
    paddingLeft: "25px",
    border: "solid 1px #c8cacf",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
    marginLeft: "10px",
  },
  approveContainedPrimary: {
    backgroundColor: "#D80666",
    color: "#ffffff",
  },

  aproveContainedDisabled: {
    opacity: 0.5,
  },
  rejectOutlinedRoot: {
    fontSize: "14",
    fontFamily: "Brown-Bold",
    textTransform: "none",
    borderRadius: "6px",
    paddingTop: "10px",
    paddingBottom: "6px",
    paddingRight: "25px",
    paddingLeft: "25px",
    border: "solid 1px #D80666",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
  },
  rejectOutlinedPrimary: {
    backgroundColor: "#ffffff",
    color: "#D80666",
  },

  rejectOutlinedDisabled: {
    opacity: 0.5,
  },
});

const ApproveRejectPopup = (props) => {
  const classes = useStyles();
  const { onApproveReject, currentStatus, item } = props;
  const {title, contentText, radioList=[]} = item;

  const [step2approve, setStep2approve] = React.useState(false);
  const [step2reject, setStep2reject] = React.useState(false);
  const [reason, setReason] = React.useState("Data is not correct");
  const [open, setOpen] = React.useState(false);
  const rejectDisabled = currentStatus.toLowerCase() === "pending" ? false : true;
  const approveDisabled = currentStatus.toLowerCase() === "approved" ? true : false;

  const handleClickOpen = (selected) => {
    console.log(selected)
    selected === "Reject" ? setStep2reject(true) : setStep2approve(true)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStep2reject(false);
    setStep2approve(false);
  };

  const handleActionClick = (action) => {
      if(action == "Approve" || action == "Reject"){
        onApproveReject(action, reason);
      }
      setOpen(false);
      setStep2reject(false);
      setStep2approve(false);
  }

  const handleRadioChange = (event) => {
    setReason(event.target.value);
  }

  return (
    <>
    <div style={{width: "100%"}}>
      <div style={{width: "48%", display: "inline-block"}}>
        <Button fullWidth 
                variant="outlined" 
                color="primary" 
                onClick={()=> {handleClickOpen("Reject")}}
                classes={{
                  root: classes.rejectOutlinedRoot,
                  disabled: classes.rejectOutlinedDisabled,
                  outlinedPrimary: classes.rejectOutlinedPrimary,
                }}
                disabled = {rejectDisabled}
                > Reject </Button>
      </div>
      <div style={{width: "48%", display: "inline-block"}}>
        <Button fullWidth 
                variant="contained" 
                color="primary" 
                onClick={()=> {handleClickOpen("Approve")}} 
                classes={{
                  root: classes.approveContainedRoot,
                  disabled: classes.aproveContainedDisabled,
                  containedPrimary: classes.approveContainedPrimary,
                }}
                disabled = {approveDisabled}
                > Approve </Button>
      </div>
    </div>
    <Dialog fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {title !== undefined && title !== "" ? (<DialogTitle>{title}</DialogTitle>) : null}

       {    
           (contentText !== undefined && contentText !== "" && step2approve) ? (
            <>
            <DialogContent>
                <DialogContentText>
                {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={()=>{handleActionClick("Cancel")}} color="primary">Cancel</Button>
                <Button autoFocus onClick={()=>{handleActionClick("Approve")}} color="primary">Yes, I agree</Button>
            </DialogActions>
            </>
           ) : null
       }

       {
           (radioList !== undefined && radioList !== [] && step2reject) ? (
            <>
            <DialogContent dividers>
                <RadioGroup
                    // ref={radioGroupRef}
                    aria-label="rejection_reason"
                    name="rejection_reason"
                    value={reason}
                    onChange={handleRadioChange}
                >
                {radioList.map((option) => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={()=>{handleActionClick("Cancel")}} color="primary">Cancel</Button>
                <Button autoFocus onClick={()=>{handleActionClick("Reject")}} color="primary">Submit</Button>
            </DialogActions>
        </>
           ) : null 
       }


    </Dialog>
    </>
  );
}

export default ApproveRejectPopup;

