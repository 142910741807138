import React, { useEffect, useState } from "react";

import AlertDialog from "../AlertDialog";
import FirebaseCloudMessaging from "../../data/sources/notifications/FirebaseCloudMessaging";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  requestUpdateToken,
  REQUEST_KEY_USER_LOGIN,
} from "../../modules/login/states/actions";
import { subscribeToUser } from "../../modules/venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import { connect } from "react-redux";
import { REQUEST_STATE_LOADING } from "../../common/states/actions.request";
import spectator from "../../data/sources/analytics/spectator";

const NOTIFICATION_REGISTRATION_ID = "notification-registration-id-1";
const NOTIFICATION_TYPE_URL_NAVIGATION = "APP_LINK_NAVIGATION";

const createNavigationUrl = (payload) => {
  let { target_url, query_params } = payload;

  //  In case the url does not contain host info, we get that from the
  //  window location object. This helps us to pass just the relative
  //  url from NotificationService.
  try {
    new URL(target_url);
  } catch (err) {
    // console.log("Failed to parse target_url into a URL object", target_url);

    const { host, protocol } = window.location;
    target_url = `${protocol}//${host}${target_url}`;
    console.log("Updated Target url : ", target_url);
  }
  const { pathname, search, host } = new URL(target_url);
  const queryParams = Object.assign(queryString.parse(search), query_params);

  const updatedQueryString = queryString.stringify(queryParams);
  const destinationUrl = `${host}${pathname}?${updatedQueryString}`;
  const destinationRelativeUrl = `${pathname}?${updatedQueryString}`;

  return { dest: destinationUrl, destRel: destinationRelativeUrl };
};

const withNotificationHandler = (WrappedComponent) => (props) => {
  const [notification, setNotification] = useState({ openDialog: false });
  const history = useHistory();

  const onMessageReceived = (notification) => {
    const {
      data: { type, payload },
    } = notification;

    spectator.logEvent("on_notification_clicked");
    let navigationUrl;
    switch (type) {
      case NOTIFICATION_TYPE_URL_NAVIGATION:
        navigationUrl = createNavigationUrl(JSON.parse(payload));
        break;
      default:
        navigationUrl = undefined;
    }

    if (!navigationUrl) {
      console.log(
        "NH",
        "We don't identify the type of this notification. Can not consume it"
      );
      return;
    }

    setNotification(
      Object.assign(notification.data, navigationUrl, {
        openDialog: true,
      })
    );
  };

  const onUpdateToken = (token) => {
    console.log("onUpdateToken", "New Token", token);

    const {
      myUser: {
        logged_in_user: {
          country_code: countryCode = undefined,
          phone_number: phoneNumber = undefined,
        },
      },
      requestUpdateDeviceToken,
      apiLogin: { request_state: requestState },
    } = props;

    if (countryCode === undefined || phoneNumber === undefined) {
      console.log("onUpdateToken", "user is not initialised, return", props);
      return;
    }

    if (requestState !== REQUEST_STATE_LOADING) {
      requestUpdateDeviceToken({
        phoneNumber: phoneNumber,
        countryCode: countryCode,
        deviceToken: token,
      });
    }
  };

  useEffect(() => {
    FirebaseCloudMessaging.registerNotificationListener(
      NOTIFICATION_REGISTRATION_ID,
      {
        onUpdateToken: onUpdateToken,
        onMessageReceived: onMessageReceived,
      }
    );

    // return () => {
    //   FirebaseCloudMessaging.unregisterNotificationListener(
    //     NOTIFICATION_REGISTRATION_ID
    //   );
    // };
  });

  // Check if user has granted permission for notification.
  // If not not show the dialog
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications");
    } else {
      if (Notification.permission !== "granted") {
        if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              console.log(
                "We asked for notification permission and user granted it"
              );
            } else {
              console.log(
                "We asked for notification permission but user didn't grant it" +
                  permission
              );
            }
          });
        }
      }
    }
  });

  const handleClose = () => {
    history.push(notification.destRel);
    setNotification({ openDialog: false });
  };

  return (
    <>
      <WrappedComponent {...props} />
      <AlertDialog
        title={notification?.title}
        description={notification?.body}
        open={notification.openDialog}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  myUser: subscribeToUser(state),
  apiLogin: subscribeToRequestState(state, REQUEST_KEY_USER_LOGIN),
});

const mapDispatchToProps = (dispatch) => ({
  requestUpdateDeviceToken: (params) =>
    dispatch(requestUpdateToken({ ...params })),
});

export default (WrappedComponent) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNotificationHandler(WrappedComponent));
