import withHistoryObject from "./withHistoryObject";
import withSinglePageApiCall from "./withSinglePageApiCall";
import withSplashScreenNavigation from "./withSplashScreenNavigation";
import withSinglePageMockCall from "./withSinglePageMockCall";
import withMockUiCall from "./withMockUiCall";
import withUpdateDialog from "./withUpdateDialog";
import withInstallDialog from "./withInstallDialog";
import withNotificationHandler from "./withNotificationHandler";
import withSnackBar from "./withSnackBar";

const HOC = {
  withInstallDialog: withInstallDialog,
  withUpdateDialog: withUpdateDialog,
  withMockUiCall: withMockUiCall,
  withHistoryObject: withHistoryObject,
  withSinglePageApiCall: withSinglePageApiCall,
  withSinglePageMockCall: withSinglePageMockCall,
  withSplashScreenNavigation: withSplashScreenNavigation,
  withNotifcationHandler: withNotificationHandler,
  withSnackBar: withSnackBar,
};

export default HOC;
