import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";

import WedzVenueIcon from "../assets/images/dark_bg_logo.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      fontFamily: "Brown-Bold",
      fontSize: "32px",
      color: "#ffffff",
      opacity: 1.0,
      textAlign: "center",
      lineHeight: "0.9",
    },
    title2: {
      flexGrow: 1,
      fontFamily: "Brown-Bold",
      fontSize: "32px",
      color: "#ffffff",
      opacity: 1.0,
      textAlign: "center",
      lineHeight: "0.9",
    },
  })
);

export const CoLogo = (props) => {
  const classes = useStyles();

  return (
    <div>
      <img src={WedzVenueIcon} alt="Logo" />;
    </div>
  );
};
