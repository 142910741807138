import React, { useEffect } from "react";
import NavigationBar from "../../routes/NavigationBar";
import CoSection, { CoListItemButton } from "../../components/CoSection";

import CoCardTemplateOne from "../../components/templates/CoCardTemplateOne";

import {
  REQUEST_KEY_VENUE_DASHBOARD,
  requestVenueDashboardData,
} from "./states/actions";
import { subscribeToVenueDashboard } from "./states/reducers";
import {
  subscribeToRequestState,
  subscribeToSharedState,
} from "../../common/states/reducers";
import { connect } from "react-redux";
import { REQUEST_STATE_LOADING } from "../../common/states/actions.request";
import {
  mapCustomerReviewsData,
  venueDashboardInitialState,
  mapUpcomingEventSummary,
  mapUpcomingRecceSummary,
  mapBusinessMetricsSummary,
  mapCustomerReviewsDataV2,
  mapOccupancySummary,
  mapOccupancySummary2, 
  mapBookingSummaryData,
} from "./mappings/dashboard.mapping";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { compose } from "redux";

import HigherOrder from "../../components/hoc/index.js";
import CoCustomerReviewsCard from "../reviews/components/index";
import { SET_INSTALL_STATE } from "../../common/states/actions.install";
import { createVenuePath } from "../../routes";
import spectator from "../../data/sources/analytics/spectator";
import { isInStandaloneMode } from "../../common/utils";
import credentials from "../../data/sources/api/credentials";
import {
  CoCardTemplateSix,
  CoCardTemplateSixList,
} from "../../components/templates/CoCardTemplateSix";
import { mapBookingListToTemplateSix } from "../events/mappings/order.mapping";
import BookingSummaryCard from "../businessMetrics/bookingComponents/bookingDashboardCard"

const requestDashboardData = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId },
    },
    requestVenueDashboardData,
  } = props;

  if (venueId !== undefined) {
    spectator.logEvent("app_opened", {
      is_standalone: isInStandaloneMode(),
      app_version: credentials.app_version.semanticCode,
      app_open_date: Date(),
    });
    requestVenueDashboardData({ venueId: venueId });
  }
};

const mapDashboardDataToUiTemplate = (props) => {
  const {
    venueDashboard: {
      dashboard_data: {
        venue_rating: venueRating,
        upcoming_events_summary: upcomingEventSummary = [],
        upcoming_recces_summary: upcomingRecceSummary = [],
        business_metrics_summary: businessMetricsSummary,
        occupancy_summary: occupancySummary,
        booking_summary: bookingSummary = {},
      },
    },
  } = props;

  return {
    customerReviews: mapCustomerReviewsDataV2(venueRating),
    businessMetricsSummary: mapBusinessMetricsSummary(businessMetricsSummary),
    upcomingEventSummary: mapUpcomingEventSummary(
      upcomingEventSummary?.slice(0, 5)
    ),
    upcomingRecceSummary: mapUpcomingRecceSummary(
      upcomingRecceSummary?.slice(0, 5)
    ),
    occupancySummary: mapOccupancySummary(occupancySummary),
    occupancySummary2: mapOccupancySummary2(occupancySummary),
    bookingSummary: mapBookingSummaryData(bookingSummary),
  };
};

const BusinessMerticsSection = (props) => {
  const { businessMetricsSummary, isLoading, pushPath, children } = props;

  const onCardClicked = (id) => {
    const venuePath = createVenuePath(props);
    pushPath(`${venuePath}/metrics`);
  };

  return (
    <CoSection title={businessMetricsSummary?.sectionTitle}>
      <CoCardTemplateOne
        isLoading={isLoading}
        items={businessMetricsSummary?.data}
        onClick={onCardClicked}
      />
      {isLoading || businessMetricsSummary?.data?.length === 0 ? null : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
      {children}
    </CoSection>
  );
};

const UpcomingRecceSection = (props) => {
  const { upcomingRecceSummary, isLoading, pushPath } = props;

  const onCardClicked = () => {
    if (!isLoading) {
      const venuePath = createVenuePath(props);
      pushPath(`${venuePath}/recces/upcoming`);
    }
  };

  return (
    <CoSection title={upcomingRecceSummary.sectionTitle}>
      <CoCardTemplateOne
        isLoading={isLoading}
        onClick={onCardClicked}
        items={upcomingRecceSummary.data}
      />
      {isLoading || upcomingRecceSummary.data?.length === 0 ? null : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
    </CoSection>
  );
};

const CustomerReviewsSection = (props) => {
  const { customerReviews, isLoading, pushPath } = props;

  const onCardClicked = () => {
    if (!isLoading) {
      const venuePath = createVenuePath(props);
      pushPath(`${venuePath}/reviews`);
    }
  };

  return (
    <CoSection
      title={customerReviews.sectionTitle}
      // action={<CoActionIcon icon={CoBackIcon} onClick={() => {}} />}
    >
      <CoCustomerReviewsCard {...customerReviews} />

      {isLoading ? null : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
    </CoSection>
  );
};

const BookingSummarySection = (props) => {
  const { bookingSummary, isLoading, pushPath } = props;

  const onCardClicked = () => {
    if (!isLoading) {
      const venuePath = createVenuePath(props);
      pushPath(`${venuePath}/metrics/bookings`);
    }
  };

  return (
    <CoSection
      title={bookingSummary.sectionTitle}
      // action={<CoActionIcon icon={CoBackIcon} onClick={() => {}} />}
    >
      <BookingSummaryCard {...bookingSummary} />

      {isLoading ? null : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
    </CoSection>
  );
};

const UpcomingEventsSection = (props) => {
  const { upcomingEventSummary, isLoading, pushPath } = props;

  const onCardClicked = () => {
    if (!isLoading) {
      const venuePath = createVenuePath(props);
      pushPath(`${venuePath}/events/upcoming`);
    }
  };

  return (
    <CoSection title={upcomingEventSummary.sectionTitle}>
      <CoCardTemplateOne
        isLoading={isLoading}
        items={upcomingEventSummary.data}
        onClick={onCardClicked}
      />
      {isLoading || upcomingEventSummary.data?.length === 0 ? null : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
    </CoSection>
  );
};

const OccupancySection2 = (props) => {
  const { occupancySummary2, isLoading, pushPath } = props;

  const onCardClicked = () => {
    if (!isLoading) {
      const venuePath = createVenuePath(props);
      pushPath(`${venuePath}/events/upcoming`);
    }
  };

  return (
    <CoSection title={occupancySummary2.sectionTitle}>
      <CoCardTemplateOne
        isLoading={isLoading}
        items={occupancySummary2.data}
        onClick={onCardClicked}
      />
      {isLoading || occupancySummary2.data?.length === 0 ? null : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
    </CoSection>
  );
};

const OccupancySection = (props) => {
  const { occupancySummary, isLoading, pushPath } = props;

  const onCardClicked = () => {
    if (!isLoading) {
      const venuePath = createVenuePath(props);
      pushPath(`${venuePath}/calendar/`);
    }
  };

  return (
    <CoSection title={occupancySummary.sectionTitle}>
      <CoCardTemplateSixList
        isLoading={isLoading}
        data={
          occupancySummary.templateData === undefined
            ? []
            : occupancySummary.templateData.data
        }
      />
      {isLoading ||
      occupancySummary.templateData === undefined ||
      occupancySummary.templateData.data?.length === 0 ? (
        <CoCardTemplateOne isLoading={isLoading} items={[]} />
      ) : (
        <CoListItemButton title={"Details"} onClick={onCardClicked} />
      )}
    </CoSection>
  );
};

const VenueDashboard = (props) => {
  const {
    api: { request_state: requestState },
    user: { selected_venue: { venue_name: venueName } = {} },
  } = props;
  
  useEffect(() => {
    document.body.style.backgroundColor = "#f8f8f8";
  }, []);

  const isLoading = requestState === REQUEST_STATE_LOADING;

  return (
    <>
      <NavigationBar title={venueName ?? "Venue"} />
      <LoadingErrorEmpty
        {...props}
        isListEmpty={false}
        onRetryButtonClicked={() => {
          requestDashboardData(props);
        }}
        emptyCards={[1, 2]}
      >
        <div
          style={{ marginLeft: "20px", marginRight: "20px", marginTop: "40px" }}
        >
          <BusinessMerticsSection {...props} isLoading={isLoading} />
          <UpcomingRecceSection {...props} isLoading={isLoading} />
          <UpcomingEventsSection {...props} isLoading={isLoading} />
          <OccupancySection {...props} isLoading={isLoading} />
          {/* <BookingSummarySection {...props} isLoading={isLoading} /> */}
          <CustomerReviewsSection {...props} isLoading={isLoading} />
        </div>
      </LoadingErrorEmpty>
    </>
  );
};

const withApiData = (WrapppedComponent) =>
  HigherOrder.withSinglePageApiCall(WrapppedComponent, {
    propInitialUiTemplate: venueDashboardInitialState,
    propMakeSinglePageApiCall: requestDashboardData,
    propMapToUiTemplate: mapDashboardDataToUiTemplate,
  });

const ComposedVenueDashboard = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withUpdateDialog,
  HigherOrder.withInstallDialog,
  HigherOrder.withNotifcationHandler,
  withApiData
)(VenueDashboard);

const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  shared: subscribeToSharedState(state),
  venueDashboard: subscribeToVenueDashboard(state),
  api: subscribeToRequestState(state, REQUEST_KEY_VENUE_DASHBOARD),
});

const mapDispatchToProps = (dispatch) => ({
  consumeInstallDialogRequest: (installState) =>
    dispatch({ type: SET_INSTALL_STATE, state: installState }),
  requestVenueDashboardData: (params) =>
    dispatch(requestVenueDashboardData({ ...params })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedVenueDashboard);
