import React from "react";
import { ElevatedContainerText2 } from "./LoginBackground";

let timer;

const formatTimer = (timerInSeconds) => {
  let value = "";

  const minutes = Math.trunc(timerInSeconds / 60);
  const seconds = timerInSeconds - minutes * 60;

  value += minutes;
  value += ":";

  if (seconds < 10) {
    value += "0";
  }
  value += seconds;

  return value;
};

class OtpTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: props.startTimeInSeconds,
    };
  }

  componentDidMount() {
    const { startWhenReady } = this.props;

    if (startWhenReady) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  shouldComponentUpdate(nextProps) {
    const { reset: resetOld } = this.props;
    const { reset: resetNew } = nextProps;

    if (resetOld === false && resetNew === true) {
      this.startTimer();
      return false;
    }

    return true;
  }

  startTimer() {
    const { startTimeInSeconds, onTimerFinished } = this.props;

    this.setState({ startTime: startTimeInSeconds });
    console.log("Starting Timer");
    clearInterval(timer);

    timer = setInterval(() => {
      const { startTime } = this.state;

      if (startTime < 1) {
        clearInterval(timer);
        onTimerFinished();
      } else {
        this.setState((prevState) => ({
          startTime: prevState.startTime - 1,
        }));
      }
    }, 1000);
  }

  stopTimer() {
    console.log("Stopping Timer");
    clearInterval(timer);
  }

  render() {
    const { startTime } = this.state;
    return (
      <ElevatedContainerText2
        value={`Waiting for  OTP …  ${formatTimer(startTime)} sec`}
      />
    );
  }
}

export default OtpTimer;
