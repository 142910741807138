import {
  formatJsDate,
  formatJsDateForRecee,
  capitalize,
  formatINRCurrency,
} from "../../../common/utils";

const getFromattedRecceStatus = (status) => {
  switch (status) {
    case "Done":
      return "Dropped";

    default:
      return status;
  }
};

const mapRecceToTemplateSixRowItem = ({ id, name, onVoMessage }, recce) => {
  const voMessageUndefined= ()=>{};
  return {
    id: id,
    name: name,
    data: {
      type: "template_six",
      data: {
        header: {
          title: `${capitalize(recce["lead_name"])}`,
          title2: `${formatINRCurrency(recce["potential_gmv"])}`,
          // title3: `${recce["status"]}`,
          title3: `${recce["pax"]} Guests`,
        },
        body: {
          items: [
            {
              type: "bodyRow1",
              kind: "title",
              items: [
                recce["event_type"]
                  ? {
                      type: "bodyLabel1",
                      value: `${recce["event_type"]}`,
                    }
                  : null,
                recce["visited_date"]
                  ? {
                      type: "bodyText1",
                      value: `Visit Date:  ${formatJsDateForRecee(
                        recce["visited_date"]
                      )}`,
                    }
                  : null,

                (recce["visited_date"] === null ||
                  recce["visited_date"] === undefined) &&
                recce["visit_scheduled_date"]
                  ? {
                      type: "bodyText1",
                      value: `Visit Scheduled Date:  ${formatJsDateForRecee(
                        recce["visit_scheduled_date"]
                      )}`,
                    }
                  : null,
              ],
            },
            // {
            //   type: "bodyRow1",
            //   kind: "text",
            //   items: [
            //     {
            //       type: "bodyText1",
            //       value: `Potential GMV: ${formatINRCurrency(
            //         recce["potential_gmv"]
            //       )}`,
            //     },
            //   ],
            // },
            // {
            //   type: "bodyRow1",
            //   kind: "text",
            //   items: [
            //     { type: "bodyText1", value: `${recce["pax"]} Guests` },
            //     {
            //       type: "bodyText1",
            //       value: `Event Date: ${
            //         formatJsDateForRecee(recce["event_date"]) ?? "NA"
            //       }`,
            //     },
            //   ],
            // },
            {
              type: "bodyRow1",
              kind: "text",
              items: [
                {
                  type: "bodyText1",
                  value:
                    recce["drop_reason_key"] !== null
                      ? `Drop Reason: ${recce["drop_reason_key"]?.replace(
                          "_",
                          " "
                        )}`
                      : null,
                },
              ],
            },
            {
              type: "bodyRow1",
              kind: "text",
              items: [
                {
                  type: "bodyText1",
                  value:
                    recce["vm_notes"] !== null
                      ? `Notes: ${recce["vm_notes"]}`
                      : null,
                },
              ],
            },
            onVoMessage.toString() !== voMessageUndefined.toString() ?
            ({
              type: "voMessageToVmButton",
              onVoMessage: onVoMessage,
              receeDeatails: recce,
            }) : null,
            // {
            //   type: "bodyRow1",
            //   kind: "text",
            //   items: [
            //     {
            //       type: "bodyText1",
            //       value: `Expected Visit Date:  ${formatJsDateForRecee(
            //         recce["visit_date"]
            //       )}`,
            //     },
            //   ],
            // },
          ],
        },
        footer: {
          value1: `Event Date: ${
            formatJsDate({
              dateObject: recce["event_date"],
              format: "DD MMM yyyy",
            }) ?? "NA"
          }`,
          value2: `${getFromattedRecceStatus(recce["status"])}`,
        },
      },
    },
  };
};

const getRecceCountByStatus = (recceList = [], statusList = []) => {
  return recceList.filter((recce) => {
    return statusList.includes(recce.status);
  });
};

const getActiveRecces = (recceList) =>
  getRecceCountByStatus(recceList, ["Hot", "SuperHotLead", "Active", "null"]);

const getDroppedRecces = (recceList) =>
  getRecceCountByStatus(recceList, ["Done"]);

const getBookedRecces = (recceList) =>
  getRecceCountByStatus(recceList, ["Booked"]);

export const mapRecceListToTemplateSix = (recceList = [], onVoMessage = ()=> {} ) => {
  let index = 0;
  const templateData = recceList?.map((recce) => {
    const recce_id = recce["recce_id"];

    const templateSixRowItem = mapRecceToTemplateSixRowItem(
      {
        id: recce_id ?? index,
        name: recce_id ?? index,
        onVoMessage: onVoMessage,
        
      },
      
      recce
    );

    index += 1;
    return templateSixRowItem;
  });

  return {
    type: "template_six",
    data: templateData,
  };
};

export const mapRecceListMetaToTemplateTwo = (recceList = []) => {
  return {
    type: "template_two",
    data: [
      {
        id: 1,
        name: "n1",
        items: [
          {
            title: "Active",
            value: getActiveRecces(recceList).length,
          },
          {
            title: "Dropped",
            value: getDroppedRecces(recceList).length,
          },
          {
            title: "Booked",
            value: getBookedRecces(recceList).length,
          },
        ],
      },
    ],
  };
};
