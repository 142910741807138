import RequestAction from "../../../common/states/actions.request";
import { VenueDashboardService } from "../../../data/VenueManagementService";

export const SET_VENUE_DASHBOARD_DATA = "SET_VENUE_DASHBOARD_DATA";
export const RESET_VENUE_DASHBOARD_DATA = "RESET_VENUE_DASHBOARD_DATA";
export const REQUEST_KEY_VENUE_DASHBOARD = "rk_venue_dashboard";

export const requestVenueDashboardData = (params) => async (dispatch) => {
  const { venueId } = params;

  dispatch(
    RequestAction.setLoading({
      message: "Please wait while we populate the dashboard",
      key: REQUEST_KEY_VENUE_DASHBOARD,
    })
  );

  try {
    const venueDashboardResponse = await VenueDashboardService.getVenueDashboard(
      { venueId: venueId }
    );

    dispatch({
      type: SET_VENUE_DASHBOARD_DATA,
      venueDashboardData: venueDashboardResponse,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_VENUE_DASHBOARD,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_VENUE_DASHBOARD,
        error: JSON.parse(err.message),
      })
    );

    console.error(
      "Error while getting dashboard data from VenueDashboard Service",
      err
    );
  }
};
