import React from "react";
import { CoElevatedPanel } from "../../../components/CoPanel";
import {
  Header,
  Divider,
  BodyTitle1,
} from "../../../components/templates/CoCardTemplateSix";
import { Typography } from "@material-ui/core";

import CoUserIcon from "../../../assets/icons/co_user_icon_comment.svg";
import Rating from "@material-ui/lab/Rating";

const Comment = (props) => {
  const { item, commentText, rating, onCommentFieldClicked } = props;

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            backgroundColor: "#65c3d3",
            marginRight: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={CoUserIcon} alt="user" />
        </div>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Rating
            name="read-only"
            size={"medium"}
            precision={0.5}
            value={rating}
            readOnly
          />
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "15px" }}>
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            marginRight: "15px",
          }}
        />
        <div
          onClick={
            onCommentFieldClicked !== undefined
              ? () => onCommentFieldClicked(item)
              : null
          }
          style={{
            flex: 1,
            display: "inline-block",
            borderRadius: "10px",
            border: "solid 1px #d8dce2",
            padding: "10px",
          }}
        >
          <Typography
            style={{
              opacity: 0.54,
              fontFamily: "Brown",
              fontSize: "14px",
              textAlign: "start",
              lineHeight: 1.43,
              color: "#000000",
            }}
          >
            {commentText}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const ReviewCard = (props) => {
  const { data: { header, body } = {}, ...otherProps } = props;

  return (
    <CoElevatedPanel
      hasHorizontalMargin={false}
      hasVerticalMargin={false}
      isLoading={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {<Header {...header} />}
        {header != null && body != null ? <Divider /> : null}
        <div style={{ margin: "20px" }}>
          <Comment {...body} {...otherProps} />
        </div>
      </div>
    </CoElevatedPanel>
  );
};

export const CoReviewList = (props) => {
  const { data = [], ...otherProps } = props;

  const lastIndex = data.length - 1;
  let index = 0;

  return data.map((row) => {
    const { id } = row;
    if (index === lastIndex) {
      index += 1;
      return <ReviewCard key={id ?? index} data={row.data} {...otherProps} />;
    }

    index += 1;
    return (
      <div key={id ?? index}>
        <ReviewCard data={row.data} {...otherProps} />
        <div style={{ height: "16px" }} />
      </div>
    );
  });
};

export default ReviewCard;
