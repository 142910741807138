import VenueManagementUrls from "../sources/api/urls";
import * as VenueManagementClient from "../sources/api/clients/vendorManagement";
import { mapUpcomingEventsApiResponse } from "../mappings/order.mappings";

const getUpcomingEvents = async ({ venueId }) => {
  const url = `${VenueManagementUrls.getOrders}`;

  const body = {
    venueId: venueId,
    tense: "FUTURE",
    page: 0,
    size: 100,
    eventType: "ORDER",
  };

  try {
    const upcomingOrders = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => mapUpcomingEventsApiResponse(response));

    console.log(
      "%c Upcoming Events Response : ",
      "color: yellow",
      upcomingOrders
    );

    return upcomingOrders;
  } catch (err) {
    console.error("UpcomingEvents Error : ", err);
    throw err;
  }
};

const getBookingListByDateRange = async ({ venueId, startDate, endDate }) => {
  const url = `${VenueManagementUrls.getOrders}`;

  const body = {
    venueId: venueId,
    startDate: startDate,
    endDate: endDate,
    page: 0,
    size: 100,
    eventType: "BOOKING",
  };

  try {
    const upcomingOrders = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => mapUpcomingEventsApiResponse(response));

    console.log(
      "%c getBookingListByDateRange : ",
      "color: yellow",
      upcomingOrders
    );

    return upcomingOrders;
  } catch (err) {
    console.error("getBookingListByDateRange Error : ", err);
    throw err;
  }
};

const updateBookingStatus = async ({ venueId, documentUrl, oldStatus, newStatus, rejection_reason}) => {
  const url = `${VenueManagementUrls.updateBookingStatus}`;

  const body = {
    venueId: venueId,
    documentUrl: documentUrl,
    oldStatus: oldStatus,
    newStatus: newStatus,
    rejection_reason: rejection_reason,
  };

  try {
    const updateResponse = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data);
      // .then((response) => mapUpdateBookingStatusApiResponse(response));

    console.log(
      "%c  : updateBookingStatus ",
      "color: yellow",
      updateResponse
    );

    return updateResponse;
  } catch (err) {
    console.error("updateBookingStatus Error : ", err);
    throw err;
  }
};

export default {
  getUpcomingEvents: getUpcomingEvents,
  getBookingListByDateRange: getBookingListByDateRange,
  updateBookingStatus: updateBookingStatus,
};
