import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import Calendar from "react-calendar";
import { makeStyles } from "@material-ui/styles";
import { formatDate } from "../../common/utils";
import { Typography } from "@material-ui/core";
import CalendarService from "../../data/services/CalendarService";
import { createCalendarDateMap } from "../../data/mappings/calendar.mappings";
import { subscribeToUser } from "../venueSelection/states/reducers";
import {
  subscribeToSharedState,
  subscribeToRequestState,
} from "../../common/states/reducers";
import {
  REQUEST_KEY_VENUE_CALENDAR_DATA,
  requestVenueCalendarData,
} from "./states/actions";
import { subscribeToVenueCalendar } from "./states/reducers";
import { requestVenueDashboardData } from "../venueDashboard/states/actions";
import { compose } from "redux";

import HigherOrder from "../../components/hoc/index.js";
import { connect } from "react-redux";

import * as moment from "moment";
import { CoCardTemplateSix } from "../../components/templates/CoCardTemplateSix";
import { createVenuePath } from "../../routes";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";

const styles = {
  priceText: {
    fontSize: "10px",
    fontFamily: "Brown-Bold",
    color: "#242b2e",
    opacity: 0.87,
  },

  occupancyText: {
    color: "#242b2e",
    fontFamily: "Brown-Bold",
    opacity: "0.7",
    fontSize: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  calendar: {
    "& .react-calendar__month-view__weekdays": {
      backgroundColor: "#f7f7f7",
      paddingTop: "14px",
      paddingBottom: "14px",
    },

    "& .react-calendar__month-view__weekdays__weekday": {
      color: "#000000",
      fontSize: "10px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      fontFamily: "Brown-Bold",
      textDecoration: "none",
    },

    "& .react-calendar__month-view__days": {
      marginLeft: "3px",
      marginRight: "3px",
      marginTop: "3px",
    },

    "& .react-calendar__month-view__days__day": {
      border: "1px solid #ffffff",
      borderRadius: "2px",
      backgroundColor: "#f7f7f7",
      minHeight: "75px",
    },

    "& .react-calendar__navigation__arrow": {
      border: "none",
      backgroundColor: "#ffffff",
      fontFamily: "Brown-Bold",
      color: "#2b2d2e",
      opacity: 0.7,
      fontSize: "14px",
    },

    "& .react-calendar__navigation__label": {
      border: "none",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      opacity: 0.7,
      fontFamily: "Brown-Bold",
      color: "#2b2d2e",
    },
  },
}));

const PriceComponent = ({ value }) => {
  return (
    <div style={{ marginTop: "8px" }}>
      <Typography variant="body1" style={styles.priceText}>
        {value}
      </Typography>
    </div>
  );
};

const RecceAndEventComponent = ({ recceCount, eventCount }) => {
  if (recceCount === 0 && eventCount === 0) {
    return <div style={{ marginTop: "4px" }} />;
  }

  return (
    <div style={{ marginTop: "4px", display: "flex", flexDirection: "row" }}>
      <div
        style={{
          marginRight: "1px",
          borderRadius: "0px 0px 0px 0px",
          backgroundColor: "#ffce67",
          opacity: 0.7,
          flex: 1,
        }}
      >
        <Typography variant="body1" style={styles.occupancyText}>
          {`${recceCount}V`}
        </Typography>
      </div>
      <div
        style={{
          marginLeft: "1px",
          borderRadius: "0px 0px 0px 0px",
          backgroundColor: "#4ec685",
          opacity: 0.7,
          flex: 1,
        }}
      >
        <Typography variant="body1" style={styles.occupancyText}>
          {`${eventCount}E`}
        </Typography>
      </div>
    </div>
  );
};

const OccupancyComponent = ({ value }) => {
  return (
    <div
      style={{
        marginTop: "4px",
        borderRadius: "8px",
        backgroundColor: "#4ec685",
        opacity: 0.7,
      }}
    >
      <Typography variant="body1" style={styles.occupancyText}>
        {value}
      </Typography>
    </div>
  );
};

const getCalendarRange = (date) => {
  const startDate = moment(date, "DD-MM-yyyy")
    .subtract("3", "months")
    .startOf("month")
    .toDate();
  const endDate = moment(date, "DD-MM-yyyy")
    .add("11", "months")
    .endOf("month")
    .toDate();

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

const getMonthWiseRange = (date) => {
  const startDate = moment(date, "MMMM_yyyy").startOf("month").toDate();
  const endDate = moment(date, "MMMM_yyyy").endOf("month").toDate();

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

const getCalendarDetailsForDate = (calendarData, date, monthKey) => {
  if (calendarData.length === 0) {
    return null;
  }
  const formattedDate = moment(date).format("DD-MM-YYYY");

  const monthData = calendarData[monthKey];
  if (monthData == null) {
    return null;
  }

  const dateData = monthData.dayWiseSplit[formattedDate];

  if (dateData == null) {
    return null;
  }
  let eventCount=0
  
  eventCount = !(dateData.order_list) ? 0 : dateData.order_list.length ;
  const recceCount = dateData.recce_list.length;

  return { eventCount: eventCount, recceCount: recceCount };
};

const getMonthlyCalendarDetails = (calendarData, monthKey) => {
  if (calendarData.length === 0) {
    return null;
  }

  if (calendarData[monthKey] == null) {
    return null;
  }

  return {
    eventCount: calendarData[monthKey].eventCount,
    gmv: calendarData[monthKey].gmv,
    monthName: calendarData[monthKey].monthName,
  };
};

class VenueCalendar extends React.Component {
  constructor(props) {
    super(props);

    const visibleDate = moment();
    this.state = {
      visibleDate: visibleDate.format("MMMM_YYYY"),
      calendarRange: getCalendarRange(visibleDate.format("DD-MM-YYYY")),
    };

    this.onCalendarDateClicked = this.onCalendarDateClicked.bind(this);
  }

  componentDidMount() {
    this.getVenueCalendarData();
  }

  getVenueCalendarData() {
    const {
      user: {
        selected_venue: { venue_id: venueId },
      },
    } = this.props;

    const { startDate, endDate } = getMonthWiseRange(this.state.visibleDate);

    const {
      venueCalendar: { calendar_data: calendarData = [] },
    } = this.props;

    const startMonth = moment(startDate).format("MMMM_YYYY");
    const endMonth = moment(endDate).format("MMMM_YYYY");

    if (
      calendarData[startMonth] !== undefined &&
      calendarData[endMonth] !== undefined
    ) {
      console.log("We have already fetched bookings for this month. Return");
      return;
    }

    // "5c9dbee7238f4c000193eb2d"
    this.props.requestVenueCalendarData({
      venueId: /*venueId*/ venueId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  }

  onCalendarDateClicked(selectedDate) {
    const { pushPath } = this.props;

    const formattedDate = moment(selectedDate).format("DDMMYYYY");
    const venuePath = createVenuePath(this.props);
    pushPath(`${venuePath}/calendar/${formattedDate}/details/`);
  }
  onClickDecade1=() => alert('Clicked month: ', 10);
  render() {
    const { startDate, endDate } = this.state.calendarRange;
    const {
      venueCalendar: { calendar_data: calendarData = [] },
    } = this.props;

    const monthWiseDetails = getMonthlyCalendarDetails(
      calendarData,
      this.state.visibleDate
    );

    return (
      <div style={{}}>
        <NavigationBar
          title={"Venue Calendar"}
          hasBackIcon={true}
          hasDrawerIcon={true}
        />

        <LoadingErrorEmpty
          {...this.props}
          isListEmpty={Object.keys(calendarData).length === 0}
          empty={{
            title: "No Calendar Events found",
            subtitle: "There are no Calendar Events",
          }}
          onRetryButtonClicked={this.getVenueCalendarData}
        >
          <>
            <div
              style={{
                margin: "14px",
                flex: 1,
              }}
            >
              <CoCardTemplateSix
                elevated={false}
                data={{
                  header: {
                    title: `${
                      monthWiseDetails === null
                        ? ""
                        : monthWiseDetails.monthName
                    }`,
                    title2: `${
                      monthWiseDetails === null
                        ? ""
                        : monthWiseDetails.eventCount
                    } Events`,
                    title3: `₹${ 
                      monthWiseDetails === null ? "" : Math.round( monthWiseDetails.gmv )
                    }`,
                  },
                }}
              />
            </div>

            <div
              style={{
                margin: "14px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                backgroundColor: "#ffffff",
              }}
            >
              <Calendar
                minDate={startDate}
                maxDate={endDate}
                prev2Label={null}
                next2Label={null}
                onClickMonth={this.onClickDecade1}
                onChange={this.onCalendarDateClicked}
                
                formatShortWeekday={(locale, date) =>
                  formatDate(date, "dd").toLocaleUpperCase().substr(0, 1)
                }
                nextLabel={<p>Next</p>}
                prevLabel={<p>Previous</p>}
                showNeighboringMonth={false}
                className={this.props.classes.calendar}
                value={moment(this.state.visibleDate, "MMMM_YYYY").toDate()}
                onActiveStartDateChange={({ activeStartDate, value, view }) => {
                  if (view !== "month") {
                    return;
                  }

                  this.setState(
                    {
                      visibleDate: moment(activeStartDate).format("MMMM_YYYY"),
                    },
                    () => {
                      this.getVenueCalendarData();
                    }
                  );
                }}
                tileContent={({ activeStartDate, date, view }) => {
                  if (view !== "month") {
                    return <div />;
                  }

                  const calendarDetails = getCalendarDetailsForDate(
                    calendarData,
                    date,
                    moment(date).format("MMMM_YYYY")
                  );

                  if (calendarDetails == null) {
                    return <div />;
                  }

                  const { recceCount, eventCount } = calendarDetails;

                  return (
                    <div>
                      <RecceAndEventComponent
                        recceCount={recceCount}
                        eventCount={eventCount}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </>
        </LoadingErrorEmpty>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: subscribeToUser(state),
  shared: subscribeToSharedState(state),
  venueCalendar: subscribeToVenueCalendar(state),
  api: subscribeToRequestState(state, REQUEST_KEY_VENUE_CALENDAR_DATA),
});

const mapDispatchToProps = (dispatch) => ({
  requestVenueCalendarData: (params) =>
    dispatch(requestVenueCalendarData({ ...params })),
});

const VenueCalendarScreen = (props) => {
  const classes = useStyles();
  return <VenueCalendar classes={classes} {...props} />;
};

const ComposedVenueCalendar = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withUpdateDialog,
  HigherOrder.withNotifcationHandler
)(VenueCalendarScreen);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedVenueCalendar);
