import React from "react";
import { compose } from "redux";
import {
  INSTALL_STATE_STANDBY,
  INSTALL_STATE_SUCCESS,
} from "../../common/states/actions.install";
import InstallBannerIOS from "../InstallBannerIOS";
import InstallBannerAndroid from "../InstallBannerAndroid";
import { isInStandaloneMode, isIos } from "../../common/utils";
import spectator from "../../data/sources/analytics/spectator";
import AlertDialog from "../AlertDialog";

export const INSTALL_TIMEOUT_IN_SECONDS = 2000;

let timer;
let deferredPrompt;

const alert_dialog = {
  title: "Installation Instructions",
  description:
    "Tap option button (appearing as 3 vertical dots) on top right and select add to home screen",
};

const showDeferrdPrompt = (props, deferredPrompt) => {
  const { consumeInstallDialogRequest } = props;

  deferredPrompt.prompt();

  deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "accepted") {
      console.log("SW : User accepted the install prompt.");
      consumeInstallDialogRequest(INSTALL_STATE_SUCCESS);
      spectator.logEvent("app_installed", {
        app_installed_date: Date(),
      });
    } else {
      console.log("SW : User dismissed the install prompt.");
      consumeInstallDialogRequest(INSTALL_STATE_STANDBY);
    }
  });
};

const withInstallDialog = (WrappedComponent, openDialog) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        platform: "",
        isDialogVisible: openDialog ?? false,
        isAlertDialogVisible: false,
      };

      this.handleInstallButtonClicked = this.handleInstallButtonClicked.bind(
        this
      );
      this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
      console.log("SW : cdm withInstallDialog");

      //  In case of iOS, directly show the prompt.
      //  isStandalone check is made on showInstallationBanner() as well
      if (isIos()) {
        this.showInstallationBanner();
        return;
      }

      console.log(
        "SW : Is deferredPrompt undefined : ",
        deferredPrompt === undefined
      );

      // if (deferredPrompt !== undefined) {
      //   console.log(
      //     "SW : deffered  Prompt is not null. Looks like we have already added an event listener. Directly show the banner"
      //   );
      //   this.showInstallationBanner();
      //   return;
      // }

      this.showInstallationBanner();

      //  In case of android, start listening to beforeinstallprompt signal
      window.addEventListener("beforeinstallprompt", (e) => {
        console.log("SW : got beforeinstallprompt event");

        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        console.log("SW : promptEvent, is it null ? ", e === undefined);
        deferredPrompt = e;
        console.log(
          "SW : Is deferredPrompt undefined : ",
          deferredPrompt === undefined
        );
        this.showInstallationBanner();
      });
    }

    showInstallationBanner() {
      const { shared: { installApp } = {} } = this.props;

      // if (installApp !== INSTALL_STATE_UNDEFINED) {
      //   console.log(
      //     `SW : installApp state ${installApp}.Looks like we have already shown the banner once.`
      //   );
      //   return;
      // }

      if (isInStandaloneMode()) {
        console.log(
          `SW : App is running in standalone mode. Don't show prompt`
        );
        return;
      }

      const onTimerFinished = () =>
        spectator.logEvent("on_install_app_banner_visible");

      this.setState({
        isDialogVisible: true,
        platform: isIos() ? "ios" : "android",
      });

      clearTimeout(timer);
      timer = setTimeout(onTimerFinished, INSTALL_TIMEOUT_IN_SECONDS);
    }

    handleInstallButtonClicked() {
      this.setState({ isDialogVisible: false, platform: "" });

      if (deferredPrompt === undefined) {
        console.log("SW : DeferredPrompt is null. Can not show install prompt");
        this.setState({ isAlertDialogVisible: true });
        return;
      }

      showDeferrdPrompt(this.props, deferredPrompt);
    }

    handleClose(event, reason) {
      const {
        shared: { installApp } = {},
        consumeInstallDialogRequest,
      } = this.props;

      // Ignore the call if the user simply clicked
      // outside the window.
      if (reason === "clickaway") {
        return;
      }

      if (
        consumeInstallDialogRequest !== undefined &&
        installApp !== INSTALL_STATE_STANDBY
      ) {
        consumeInstallDialogRequest(INSTALL_STATE_STANDBY);
      }

      this.setState({
        isDialogVisible: false,
      });
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} />
          {this.state.platform === "android" ? (
            <div style={{ position: "fixed", bottom: "0px" }}>
              <InstallBannerAndroid
                open={this.state.isDialogVisible}
                onBackPressed={this.handleClose}
                onInstallPressed={this.handleInstallButtonClicked}
              />
            </div>
          ) : null}
          {this.state.platform === "ios" ? (
            <div style={{ position: "fixed", bottom: "0px" }}>
              <InstallBannerIOS
                open={this.state.isDialogVisible}
                onBackPressed={this.handleClose}
                action={this.action}
              />
            </div>
          ) : null}
          <AlertDialog
            title={alert_dialog.title}
            description={alert_dialog.description}
            open={this.state.isAlertDialogVisible}
            handleClose={() => {
              spectator.logEvent("on_install_instruction_alert_dialog_visible");

              this.setState({
                isAlertDialogVisible: false,
              });
            }}
          />
        </>
      );
    }
  };
};

export default compose(withInstallDialog);
