import { get, set } from "idb-keyval";

export const DEFAULT_VALUE = "NA";

const setValue = (key, value) => {
  return set(key, value);
};

const getValue = (key) => {
  return get(key);
};

const keyValueStore = {
  setValue: setValue,
  getValue: getValue,
};
export default keyValueStore;
