import VenueManagementUrls from "../sources/api/urls";
import * as VenueManagementClient from "../sources/api/clients/vendorManagement";
import {
  mapResponseAsCalendarEntries,
  mapCalendarEntriesByDate,
  mapCalendarDateMapByMonth,
} from "../mappings/calendar.mappings";

const getVenueCalendar = async ({ venueId, startDate, endDate }) => {
  const url = `${VenueManagementUrls.allCalendarEvents}`;

  const body = {
    page: 0,
    size: 20,
    venueId: venueId,
    eventType: "ORDER",
    startDate: startDate,
    endDate: endDate,
  };

  try {
    const entriesList = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => response.data)
      .then((response) => mapResponseAsCalendarEntries(response))
      .then((entries) => mapCalendarEntriesByDate(entries, startDate, endDate))
      .then((calendarDateMap) =>
        mapCalendarDateMapByMonth(calendarDateMap, startDate, endDate)
      );

    console.log("%c Get Calendar Entries : ", entriesList);
    return entriesList;
  } catch (err) {
    console.error("Get Calendar Entries : ", err);
  }
};

export default {
  getVenueCalendar: getVenueCalendar,
};
