import React from "react";
import { connect } from "react-redux";
import {
  requestBusinessMetrics,
  REQUEST_KEY_MONTHLY_METRICS_LIST,
  FILTER_DATE_FORMAT,
} from "./states/actions";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import { subscribeToBusinessMetrics } from "./states/reducers";
import {
  REQUEST_STATE_LOADING,
  REQUEST_STATE_STAND_BY,
  REQUEST_STATE_ERROR,
} from "../../common/states/actions.request";
import { mapBusinessMetricsDataToTempateOneList } from "./mappings/metrics.mappings";
import NavigationBar from "../../routes/NavigationBar";
import { LoadingByRequestState } from "../../components/LoadingScreen";
import {
  Error,
  EmptyCard,
  ErrorCard,
  CoErrorScreen,
} from "../../components/EmptyScreen";
import CoSection from "../../components/CoSection";
import CoTableTemplateTwo from "../../components/templates/CoCardTemplateTwo";
import { getCurrentDate } from "../../common/utils";

import moment from "moment";
import { CoCardTemplateOneList } from "../../components/templates/CoCardTemplateOne";
import { compose } from "redux";
import withSplashScreenNavigation from "../../components/hoc/withSplashScreenNavigation";
import withHistoryObject from "../../components/hoc/withHistoryObject";

const getDateRangeForPastBusinessMetrics = () => {
  const endDate = moment().format(FILTER_DATE_FORMAT);
  const startDate = moment()
    .subtract(1, "years")
    .startOf("month")
    .format(FILTER_DATE_FORMAT);

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

class PastBusinessMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templateTwoData: {},
      templateOneData: {},
    };
  }

  componentDidMount() {
    this.getMonthlyMetricsList(this.props, this.state);
    this.mapRecceListDataToUiTemplate(this.props);
  }

  getMonthlyMetricsList(props, state) {
    const {
      api: { request_state: requestState },
      user: {
        selected_venue: { venue_id: venueId },
      },
      requestMonthlyMetrics,
      businessMetrics: {
        monthly_metrics: { metrics_list: businessMetricsList },
      } = {},
    } = props;

    if (requestState !== REQUEST_STATE_LOADING) {
      if (businessMetricsList?.length !== 0) {
        this.mapRecceListDataToUiTemplate(props);
        return;
      }
    }
    if (venueId !== undefined) {
      requestMonthlyMetrics({
        venueId: venueId,
        ...getDateRangeForPastBusinessMetrics(),
      });
    }
  }

  mapRecceListDataToUiTemplate(newProps) {
    const {
      businessMetrics: {
        monthly_metrics: { metrics_list: businessMetricsList = [] },
      } = {},
    } = newProps;

    this.setState({
      templateOneData: mapBusinessMetricsDataToTempateOneList(
        businessMetricsList
      ),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      api: { request_state: newRequestState },
    } = nextProps;

    const {
      api: { request_state: oldRequestState },
    } = this.props;

    if (
      oldRequestState === REQUEST_STATE_LOADING &&
      newRequestState === REQUEST_STATE_STAND_BY
    ) {
      this.mapRecceListDataToUiTemplate(nextProps);
      return false;
    }

    return true;
  }

  render() {
    const { templateOneData } = this.state;

    const {
      api: {
        request_state: requestState,
        loading: { message: loadingMessage } = {},
        error: { message: errorMessage, code: errorCode } = {},
      },
      businessMetrics: {
        monthly_metrics: { metrics_list: businessMetricsList = [] },
      },
      showAsTab = false,
    } = this.props;
    return (
      <>
        {!showAsTab ? <NavigationBar title={"Business Metrics"} /> : null}

        <LoadingByRequestState
          message={loadingMessage}
          requestState={requestState}
        >
          <div
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "20px",
              marginTop: "35px",
            }}
          >
            <CoErrorScreen
              hasMargins={false}
              hasError={requestState === REQUEST_STATE_ERROR}
              isLoading={requestState === REQUEST_STATE_LOADING}
              message={errorMessage}
              statusCode={errorCode}
              onRetryButtonClicked={() => {
                this.getMonthlyMetricsList(this.props, this.state);
              }}
            >
              <CoSection title={"Month Wise Performance"}>
                <EmptyCard
                  isEmpty={businessMetricsList?.length === 0}
                  title={"No Recces Found"}
                  subtitle={"There are no Past Metrics"}
                  hasMargins={false}
                >
                  <CoCardTemplateOneList data={templateOneData.data} />
                </EmptyCard>
              </CoSection>
            </CoErrorScreen>
          </div>
        </LoadingByRequestState>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  businessMetrics: subscribeToBusinessMetrics(state),
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_MONTHLY_METRICS_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestMonthlyMetrics: (params) =>
    dispatch(requestBusinessMetrics({ ...params })),
});

const ComposedPastBusinessMetrics = compose(
  withHistoryObject,
  withSplashScreenNavigation
)(PastBusinessMetrics);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedPastBusinessMetrics);
