import React from "react";

const withMockUiCall = (
  WrappedComponent,
  { propInitialUiTemplate, propUiMockedTemplate }
) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ...propInitialUiTemplate,
      };
    }

    componentDidMount() {
      console.log("CDM");
      setTimeout(() => {
        console.log("Update props");
        this.setState({ ...propUiMockedTemplate });
      }, 3000);
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
};

export default withMockUiCall;
