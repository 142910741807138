import moment from "moment";
import { mapOrderListResponse } from "./order.mappings";
import { mapRecceListResponse } from "./recce.mappings";

const DATE_FORMAT = "DD-MM-YYYY";

export const createCalendarDateMap = (sDate, eDate) => {
  const startDate = moment(sDate);
  const endDate = moment(eDate);

  const calendarDateMap = {};
  for (
    var m = moment(startDate);
    m.diff(endDate, "days") <= 0;
    m.add(1, "days")
  ) {
    const formattedDate = m.format(DATE_FORMAT);
    calendarDateMap[formattedDate] = {};
    calendarDateMap[formattedDate].order_list = [];
    calendarDateMap[formattedDate].recce_list = [];
  }

  return calendarDateMap;
};

export const createCalendarMonthMap = (sDate, eDate) => {
  const startDate = moment(sDate);
  const endDate = moment(eDate);

  const calendarMonthMap = {};
  for (
    var m = moment(startDate);
    m.diff(endDate, "days") <= 0;
    m.add(1, "month")
  ) {
    const formattedDate = m.format("MMMM_YYYY");

    calendarMonthMap[formattedDate] = {};
    calendarMonthMap[formattedDate].eventCount = 0;
    calendarMonthMap[formattedDate].gmv = 0;
    calendarMonthMap[formattedDate].monthName = m.format("MMMM YYYY");
    calendarMonthMap[formattedDate].dayWiseSplit = {};
  }

  console.log("CalendarMonthMap", calendarMonthMap);
  return calendarMonthMap;
};

export const mapResponseAsCalendarEntries = (response) => {
  const orders = mapOrderListResponse(response.orders.events);
  const recces = mapRecceListResponse(response.recces.recees);

  return {
    order_list: orders,
    recce_list: recces,
  };
};

const getOrderAggregatedDataFromMap = (order_map) => {
  let eventCount = 0;
  let gmv = 0;

  Object.keys(order_map).forEach((key) => {
    const orderList = order_map[key];

    const {
      gmv: oGmv,
      eventCount: oEventCount,
    } = getOrderAggregatedDataFromMap(orderList);

    eventCount = eventCount + oEventCount;
    gmv = gmv + oGmv;
  });

  return {
    gmv: gmv,
    eventCount: eventCount,
  };
};

const getOrderAggregatedDataFromOrderList = (orderList) => {
  let eventCount = 0;
  let gmv = 0;

  eventCount = eventCount + (orderList || []).length;
  gmv =
    gmv + (orderList || []).map((order) => order.gmv).reduce((t1, t2) => t1 + t2, 0);

  return {
    gmv: gmv,
    eventCount: eventCount,
  };
};

export const mapCalendarDateMapByMonth = (calendarDateMap, sDate, eDate) => {
  const calendarMonthMap = createCalendarMonthMap(sDate, eDate);

  console.log("YOLO", sDate, eDate);
  
  if((calendarDateMap && calendarMonthMap)){
  Object.keys(calendarDateMap || []).forEach((key) => {
    const monthDate = moment(key, DATE_FORMAT).format("MMMM_YYYY");
    const calendarData = calendarDateMap[key];

    const { eventCount, gmv } = getOrderAggregatedDataFromOrderList(
      calendarData.order_list
    );
    
    let monthData = calendarMonthMap[monthDate];
    if(!monthData)
    {
      monthData={dayWiseSplit: {}}
    }
    monthData.dayWiseSplit= {...monthData.dayWiseSplit, [key]:calendarData}
    monthData.eventCount = monthData.eventCount + eventCount;
    monthData.gmv = monthData.gmv + gmv;
  });
}
console.log("POLO", calendarMonthMap);
  return calendarMonthMap;
};

export const mapCalendarEntriesByDate = (entries, sDate, eDate) => {
  const calendarDateMap = createCalendarDateMap(sDate, eDate);
  const { order_list: orderList, recce_list: recceList } = entries;

  const currentDate = moment();

  recceList.forEach((recce) => {
    let dateString;
    if (recce.event_date !== null || recce.event_date !== undefined) {
      dateString = recce.event_date;
    }
    const date = moment(dateString).format(DATE_FORMAT);
    addRecceToDate(calendarDateMap, date, recce);
  });

  orderList.forEach((order) => {
    const bookingDate = moment(order.booking_date);
    const eventDate = moment(order.event_date);

    let date;
    if (eventDate.isAfter(currentDate)) {
      // TODO, check this later. Maybe we need to use booking date here
      date = eventDate.format(DATE_FORMAT);
    } else {
      date = eventDate.format(DATE_FORMAT);
    }
    addOrderToDate(calendarDateMap, date, order);
  });
  return calendarDateMap;
};

const addOrderToDate = (calendarDateMap, date, order) => {
  if (calendarDateMap[date] === undefined) {
    console.log(date);
    return;
  }
  const dateOrderList = calendarDateMap[date].order_list;

  if (dateOrderList == null || dateOrderList === undefined) {
    calendarDateMap[date].order_list = [];
  }

  calendarDateMap[date].order_list.push(order);
};

const addRecceToDate = (calendarDateMap, date, recce) => {
  
  let dateRecceList=[];
  if(calendarDateMap && calendarDateMap[date])
  dateRecceList= calendarDateMap[date].recce_list;

  if (!(dateRecceList && dateRecceList.length)) {
    calendarDateMap[date]= { recce_list : []}
  }

  calendarDateMap[date].recce_list.push(recce);
};
