import { UserLoginService } from "../../../data/VenueManagementService";
import RequestAction, {
  REQUEST_STATE_STAND_BY,
} from "../../../common/states/actions.request";
import {
  SET_USER_STATE,
  RESET_USER_STATE,
  SET_DEVICE_TOKEN,
} from "../../venueSelection/states/actions";

import KeyValueStore from "../../../data/sources/store/keyValue";

export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const RESET_USER_LOGIN = "RESET_USER_LOGIN";
export const REQUEST_KEY_USER_LOGIN = "rk_user_login";

export const requestLoginOtp = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait",
      key: REQUEST_KEY_USER_LOGIN,
    })
  );

  // dispatch({ type: RESET_USER_LOGIN });
  const { countryCode, phoneNumber } = params;

  try {
    const userLoginResponse = await UserLoginService.generateOtp({
      countryCode: countryCode,
      phoneNumber: phoneNumber,
    });

    const { otp, name } = userLoginResponse;

    dispatch({
      type: SET_USER_LOGIN,
      login: {
        ...params,
        otp,
        name,
      },
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_USER_LOGIN,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    console.error("Error while requesting otp from user", err);

    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_USER_LOGIN,
        error: JSON.parse(err.message),
      })
    );
  }
};

export const requestVerifyOtp = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait",
      key: REQUEST_KEY_USER_LOGIN,
    })
  );

  const { countryCode, phoneNumber, otp, deviceToken } = params;

  try {
    const userLoginResponse = await UserLoginService.verifyOtp({
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      otp: otp,
      deviceToken: deviceToken,
    });

    const { user, venue_list, primary_venue } = userLoginResponse;

    const userWithDeviceToken = Object.assign(user, {
      device_token: deviceToken,
      logged_in_date: new Date(),
    });

    const userState = {
      user_venue_list: venue_list,
      selected_venue: primary_venue,
      logged_in_user: userWithDeviceToken,
    };

    await KeyValueStore.setValue("USER_STATE", userState);

    const { auth_token: authToken } = user;
    UserLoginService.setAuthTokenInAllRequests({ authToken });

    dispatch({
      type: SET_USER_STATE,
      user: userState,
    });

    dispatch({ type: RESET_USER_LOGIN });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_USER_LOGIN,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    console.error("Error while requesting otp from user", err);
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_USER_LOGIN,
        error: JSON.parse(err.message),
      })
    );
  }
};

export const requestUpdateToken = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait",
      key: REQUEST_KEY_USER_LOGIN,
    })
  );

  const { countryCode, phoneNumber, deviceToken } = params;

  try {
    const updateDeviceTokenResponse = await UserLoginService.updateDeviceToken({
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      deviceToken: deviceToken,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_USER_LOGIN,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_USER_LOGIN,
        error: JSON.parse(err.message),
      })
    );

    console.error("Error while requesting update device token", err);
  } finally {
    dispatch({ type: SET_DEVICE_TOKEN, deviceToken });
  }
};

export const requestLogoutUser = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait",
      key: REQUEST_KEY_USER_LOGIN,
    })
  );

  const { countryCode, phoneNumber, deviceToken } = params;

  try {
    await UserLoginService.logoutUser({
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      deviceToken: deviceToken,
    });
  } catch (err) {
    // dispatch(
    //   RequestAction.setError({
    //     key: REQUEST_KEY_USER_LOGIN,
    //     error: JSON.parse(err.message),
    //   })
    // );

    console.error("Error while logging out user", err);
  } finally {
    await KeyValueStore.setValue("USER_STATE", undefined);
    UserLoginService.resetAuthTokenInAllRequests();

    dispatch({
      type: RESET_USER_STATE,
    });
  }

  dispatch(
    RequestAction.setRequestState({
      key: REQUEST_KEY_USER_LOGIN,
      request_state: REQUEST_STATE_STAND_BY,
    })
  );
};
