import React from "react";
import CoSection, { CoDropDownButton } from "./CoSection";
import { SimpleDropDownMenu } from "./CoDropDownMenu";

class CoSectionWithDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.onDropDownOptionSelected = this.onDropDownOptionSelected.bind(this);
  }

  onDropDownOptionSelected(option) {
    const { onOptionChanged, selectedOption } = this.props;

    if (selectedOption.id !== option.id) {
      onOptionChanged(option);
    }
  }

  render() {
    const { children, title, optionList = [], selectedOption } = this.props;
    return (
      <CoSection
        title={title}
        action={
          <SimpleDropDownMenu
            onItemSelected={this.onDropDownOptionSelected}
            options={optionList}
          >
            <CoDropDownButton title={selectedOption.name} />
          </SimpleDropDownMenu>
        }
      >
        {children}
      </CoSection>
    );
  }
}

export default CoSectionWithDropDown;
