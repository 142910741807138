import React from "react";
import AlertDialog from "./AlertDialog";

class ShowWithPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
  }

  handleClose = () => {
    this.setState({ openDialog: false, description: "" });
  };

  onItemClicked() {
    this.setState({ openDialog: true });
  }

  render() {
    const { children, style, title = "Message", message } = this.props;

    return (
      <div>
        <div style={style} onClick={this.onItemClicked}>
          {children}
        </div>
        <AlertDialog
          title={title}
          description={message}
          open={this.state.openDialog}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}

export default ShowWithPopup;
