import React from "react";
import { CoAlertDialog } from "../AlertDialog";
import {
  UPDATE_STATE_UNDEFINED,
  UPDATE_STATE_AVAILABLE,
  UPDATE_STATE_SUCCESS,
} from "../../common/states/actions.update";

import { withCookies } from "react-cookie";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  requestUpdateToken,
  REQUEST_KEY_USER_LOGIN,
} from "../../modules/login/states/actions";
import { subscribeToUser } from "../../modules/venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import { REQUEST_STATE_LOADING } from "../../common/states/actions.request";
import FirebaseCloudMessaging from "../../data/sources/notifications/FirebaseCloudMessaging";
import spectator from "../../data/sources/analytics/spectator";
import { isInStandaloneMode } from "../../common/utils";
import credentials from "../../data/sources/api/credentials";

export const UPDATE_TIMEOUT_IN_SECONDS = 3000;
export const COOKIE_SHOW_UPDATE_SUCCESS_DIALOG =
  "cookie_show_update_success_dialog";

let timer = undefined;

function withUpdateDialog(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dialogType: "",
        dialogMessage: "",
        isDialogVisible: false,
        buttonText1: undefined,
        buttonText2: undefined,
      };
    }

    componentDidMount() {
      console.log("SW : About to check for updates to show dialog");

      clearTimeout(timer);

      if (timer !== undefined) {
        return;
      }

      // setTimeout(() => {
      //   console.log("SW : check is update available");
      //   const {
      //     shared: { updateAvailable },
      //   } = this.props;
      //   console.log("SW : updateAvailable", updateAvailable);
      //   if (updateAvailable === true) {
      //     this.setState({
      //       isDialogVisible: true,
      //       dialogMessage:
      //         "A new update is available. Please restart the app to apply new changes.",
      //       buttonText1: "Restart",
      //       buttonText2: "Cancel",
      //     });
      //   }
      // }, UPDATE_TIMEOUT_IN_SECONDS);
    }

    shouldComponentUpdate(nextProps) {
      const {
        shared: { updateAvailable: updateAvailableOld },
      } = this.props;

      const {
        shared: { updateAvailable: updateAvailableNew },
        cookies,
      } = nextProps;

      if (
        updateAvailableOld === UPDATE_STATE_UNDEFINED &&
        updateAvailableNew === UPDATE_STATE_AVAILABLE
      ) {
        this.setState({
          isDialogVisible: true,
          dialogType: "update",
          dialogMessage:
            "A new update is available. Please restart the app to apply new changes.",
          buttonText1: "Update",
          buttonText2: "Cancel",
        });
        return false;
      }

      if (
        (updateAvailableOld === UPDATE_STATE_AVAILABLE ||
          updateAvailableOld === UPDATE_STATE_UNDEFINED) &&
        updateAvailableNew === UPDATE_STATE_SUCCESS &&
        cookies.get(COOKIE_SHOW_UPDATE_SUCCESS_DIALOG) === "true"
      ) {
        spectator.logEvent("app_updated", {
          is_standalone: isInStandaloneMode(),
          app_version: credentials.app_version.semanticCode,
        });

        this.setState({
          isDialogVisible: true,
          dialogType: "success",
          dialogMessage: "We have successfully updated the app",
          buttonText1: undefined,
          buttonText2: "Okay",
        });

        console.log("update successful. updating token now");
        //  Update device token
        this.updateDeviceTokenAfterUpdation();
        return false;
      }
      return true;
    }

    updateDeviceTokenAfterUpdation = () => {
      const {
        myUser: {
          logged_in_user: {
            country_code: countryCode = undefined,
            phone_number: phoneNumber = undefined,
            device_token: deviceToken = undefined,
          },
        },
        apiLogin: { request_state: requestState },
        requestUpdateDeviceToken,
      } = this.props;

      if (requestState !== REQUEST_STATE_LOADING) {
        FirebaseCloudMessaging.getDeviceRegistrationToken().then((token) => {
          if (token !== undefined) {
            console.log(
              "UPDATE TOKEN. It's differnet from what is saved in app state",
              requestState,
              deviceToken
            );

            requestUpdateDeviceToken({
              phoneNumber: phoneNumber,
              countryCode: countryCode,
              deviceToken: token,
            });
          }
        });
      }
    };

    handleClose = () => {
      const { cookies } = this.props;

      this.setState(
        {
          isDialogVisible: false,
          dialogMessage: "",
        },
        () => {
          //  If success dialog is already showing and cookie is set then
          //  reset the cookie.
          console.log("SW", "reset cookie to not show welcome dialog next");
          if (
            this.state.dialogType === "success" &&
            cookies.get(COOKIE_SHOW_UPDATE_SUCCESS_DIALOG) !== "false"
          ) {
            cookies.set(COOKIE_SHOW_UPDATE_SUCCESS_DIALOG, "false");
          }
        }
      );
    };

    handleUpdateButtonClicked = () => {
      const { cookies } = this.props;

      this.setState(
        {
          isDialogVisible: false,
          dialogMessage: "",
        },
        () => {
          //  If update dialog is showing then set cookie to show success dialog.
          if (
            this.state.dialogType === "update" &&
            cookies.get(COOKIE_SHOW_UPDATE_SUCCESS_DIALOG) !== "true"
          ) {
            cookies.set(COOKIE_SHOW_UPDATE_SUCCESS_DIALOG, "true");
          }
          this.forceSWupdate();
          // window.location.reload(true);
        }
      );
    };

    forceSWupdate() {
      console.log("SW : FORCE UPDATE");
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              console.log("SW : Registration", registration);
              registration.unregister();
            }

            window.location.reload();
          });
      }
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} />
          <CoAlertDialog
            message={this.state.dialogMessage}
            open={this.state.isDialogVisible}
            handleClose={this.handleClose}
            buttonText1={this.state.buttonText1}
            buttonText2={this.state.buttonText2}
            handleButton1Clicked={this.handleUpdateButtonClicked}
          />
        </>
      );
    }
  };
}

const mapStateToProps = (state) => ({
  myUser: subscribeToUser(state),
  apiLogin: subscribeToRequestState(state, REQUEST_KEY_USER_LOGIN),
});

const mapDispatchToProps = (dispatch) => ({
  requestUpdateDeviceToken: (params) =>
    dispatch(requestUpdateToken({ ...params })),
});

const ComposedUpdateDialog = compose(withCookies, withUpdateDialog);

export default (WrappedComponent) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComposedUpdateDialog(WrappedComponent));
