import React from "react";
import { CoElevatedPanel } from "../../../components/CoPanel";
import { Typography, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.7,
      lineHeight: 1.51,
      marginRight: 5,
    },
    value: {
      fontFamily: "Brown-Bold",
      fontSize: "22px",
      color: "#000000",
      opacity: 0.87,
      textAlign: "center",
    },
    approved: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      opacity: 0.87,
      textAlign: "center",
      color: "#4ec685",
    },
    pending: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      opacity: 0.87,
      textAlign: "center",
      color: "#ff8387",
    },
    rejected: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      opacity: 0.6,
      textAlign: "center",
      color: "#000000",
    },
  })
);

const Divider = (props) => {
  return (
    <div
      style={{
        width: "1px",
        opacity: 0.1,
        backgroundColor: "#000000",
      }}
    />
  );
};
const ApprovedBox = (props) => {
  const { classes, approved } = props;

  return (
    <div style={{ flex: 1 }}>
      <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
        {approved ?? "Na"}
      </Typography>
      <Typography
        style={{ flex: 1 }}
        variant="body1"
        className={classes.approved}
      >
        {"Approved"}
      </Typography>
    </div>
  );
};

const PendingBox = (props) => {
  const { classes, pending } = props;

  return (
    <div style={{ flex: 1 }}>
      <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
        {pending ?? "Na"}
      </Typography>
      <Typography
        style={{ flex: 1 }}
        variant="body1"
        className={classes.pending}
      >
        {"Pending"}
      </Typography>
    </div>
  );
};

const RejectedBox = (props) => {
  const { classes, rejected } = props;

  return (
    <div style={{ flex: 1 }}>
      <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
        {rejected ?? "Na"}
      </Typography>
      <Typography
        style={{ flex: 1 }}
        variant="body1"
        className={classes.rejected}
      >
        {"Rejected"}
      </Typography>
    </div>
  );
};

const BookingSummaryCard = (props) => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 3 }}>
        <CoElevatedPanel isLoading={false} hasHorizontalMargin={false}>
          <div style={{ display: "flex" }}>
            <PendingBox classes={classes} {...props} />
            <Divider />
            <ApprovedBox classes={classes} {...props} />
            <Divider />
            <RejectedBox classes={classes} {...props} />
          </div>
        </CoElevatedPanel>
      </div>
    </div>
  );
};

export default BookingSummaryCard;
