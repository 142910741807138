import {
  formatJsDateForReview,
  hasDecimalPlaces,
  capitalize,
} from "../../../common/utils";

const mapReviewToCoTableRow = ({ id, name }, review) => [
  { id: id },
  { value: review["customer_name"], flex: 2 },
  { value: formatJsDateForReview(review["event_date"]) ?? "-", flex: 1.5 },
  { value: (((review["rating"] ?? 0) * 5.0) / 100) * 1.0, flex: 1 },
  {
    value:
      (review["customer_comment"] ?? "").substr(0, 50) +
      ((review["customer_comment"] ?? "").length > 50 ? "..." : ""),
    flex: 2,
    id: 3,
  },
];

const mapReviewToCoReviewCard = ({ id, name }, review) => {
  return {
    id,
    name,
    data: {
      header: {
        title: capitalize(review["customer_name"]),
        title4: `${
          formatJsDateForReview(review["event_date"]) ?? "No Event Date"
        }`,
        title2: `${capitalize(review["event"]) ?? "Unknown Event"}`,
      },
      body: {
        item: review,
        // commentText: review["customer_comment"],
        commentText:
          (review["customer_comment"] ?? "").substr(0, 100) +
          ((review["customer_comment"] ?? "").length > 100 ? "..." : ""),
        rating: (((review["rating"] ?? 0) * 5.0) / 100) * 1.0,
      },
    },
  };
};

export const mapReviewListToCoTable = (reviewList = []) => {
  let index = 0;

  const coTableData = reviewList.map((review) => {
    const tableRow = mapReviewToCoTableRow({ id: index, name: index }, review);

    index += 1;
    return tableRow;
  });

  return coTableData.length !== 0
    ? {
        type: "mutli_column_table",
        header: [
          { value: "Customer Name", flex: 2 },
          { value: "Event Date", flex: 1.5 },
          { value: "Rating", flex: 1 },
          { value: "Comments", flex: 2 },
        ],
        rows: coTableData,
      }
    : {};
};

export const mapReviewListToCoReviewCardList = (reviewList = []) => {
  let index = 0;

  const coReviewCardList = reviewList.map((review) => {
    const coReviewCard = mapReviewToCoReviewCard(
      { id: index, name: index },
      review
    );

    index += 1;
    return coReviewCard;
  });

  return coReviewCardList.length !== 0
    ? {
        type: "co_review_card_list",
        data: coReviewCardList,
      }
    : {};
};

export const mapVenueOverallRatingToTemplateOne = (venueRating = {}) => {
  return {
    type: "template_one",
    data: [
      {
        type: "rating",
        title: "Overall Rating",
        showValue: true,
        value: venueRating["average_rating"],
      },
      {
        title: "Delight",
        value: `${venueRating["delighted_user_percentage"]}%`,
      },
      {
        title: "Unhappy",
        value: `${venueRating["unhappy_user_percentage"]}%`,
      },
    ],
  };
};

export const mapVenueOverallRatingToCustomerReviewCard = (venueRating = {}) => {
  return {
    delight: `${
      hasDecimalPlaces(venueRating["delighted_user_percentage"])
        ? venueRating["delighted_user_percentage"]?.toFixed(1) ?? "NA"
        : venueRating["delighted_user_percentage"]
    }%`,
    unhappy: `${
      hasDecimalPlaces(venueRating["unhappy_user_percentage"])
        ? venueRating["unhappy_user_percentage"]?.toFixed(1) ?? "NA"
        : venueRating["unhappy_user_percentage"]
    }%`,
    rating: `${
      hasDecimalPlaces(venueRating["average_rating"])
        ? venueRating["average_rating"]?.toFixed(1) ?? "NA"
        : venueRating["average_rating"]
    }`,
  };
};
