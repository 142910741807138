import React from "react";
import {
  makeStyles,
  SnackbarContent,
  Button,
  Snackbar,
  Slide,
  Typography,
  IconButton,
} from "@material-ui/core";

import IosShareIcon from "../assets/icons/ios_share_icon_white.png";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { CoLogo } from "./Logo";

export const AUTO_HIDE_DURATION_IN_SECONDS = 60000;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonContainedRoot: {
    fontSize: "14px",
    fontFamily: "Brown-Bold",
    textTransform: "none",
    borderRadius: "20px",
    paddingTop: "5px",
    paddingBottom: "3px",
    paddingRight: "24px",
    paddingLeft: "24px",
    opacity: 0.89,
  },
  buttonContainedPrimary: {
    backgroundColor: "#fcfdfe",
    color: "#ee2e24",
  },

  buttonContainedDisabled: {
    opacity: 0.5,
  },

  textStyle: {
    color: "#ffffff",
    fontFamily: "Brown-Bold",
    fontSize: "16px",
    lineHeight: 1.29,
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const IosShareSymbol = () => {
  return (
    <img
      src={IosShareIcon}
      style={{ widows: "28px", height: "28px" }}
      alt="share-icon"
    />
  );
};

const InstallBannerIOS = (props) => {
  const { onBackPressed, open } = props;
  const classes = useStyles();

  return (
    <div style={{}} className={classes.root}>
      <Snackbar
        style={{ display: "flex" }}
        open={open}
        autoHideDuration={AUTO_HIDE_DURATION_IN_SECONDS}
        onClose={onBackPressed}
        TransitionComponent={SlideTransition}
      >
        <div style={{ flex: 1, backgroundColor: "#212121" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="delete" onClick={onBackPressed}>
              <CloseOutlined
                style={{ color: "#ffffff", opacity: 0.15 }}
                size="small"
              />
            </IconButton>
          </div>
          <div
            style={{
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <CoLogo />
          </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  marginLeft: "15px",
                  marginBottom: "15px",
                  marginTop: "15px",
                  margingRight: "25px",
                }}
              >
                <Typography className={classes.textStyle}>
                  {`Install this application on your home screen for quick and easy access when you're on the go.`}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "15px",
                marginRight: "15px",
                marginBottom: "15px",
                alignItems: "center",
              }}
            >
              <Typography
                className={classes.textStyle}
              >{`Just Tap`}</Typography>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <IosShareSymbol />
              </div>
              <Typography className={classes.textStyle}>
                {`appearing below and then select "Add to Home Screen"`}
              </Typography>
            </div>
          </div>
        </div>
      </Snackbar>
    </div>
  );
};

export default InstallBannerIOS;
