import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { CoNonElevatedPanel } from "../CoPanel";
import { CoListItemLoading, CoListItemEmpty } from "../CoSection";

const useStyles = makeStyles((theme) =>
  createStyles({
    multiColumnSubHeading: {
      fontFamily: "Brown-Bold",
      fontSize: "12px",
      color: "#000000",
      opacity: 0.54,
      lineHeight: 1.45,
      marginRight: 10,
      textAlign: "start",
    },
    multiColumnValue: {
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.71,
      alignSelf: "center",
      letterSpacing: "0.33px",
      textAlign: "center",
      marginRight: 5,
    },
  })
);

const CoMultiColumnRowSubHeading = (props) => {
  const { classes, value } = props;

  return (
    <Typography variant="body1" className={classes.multiColumnSubHeading}>
      {value}
    </Typography>
  );
};

const CoMultiColumnRowValue = (props) => {
  const { classes, value } = props;

  return (
    <Typography variant="body1" className={classes.multiColumnValue}>
      {value}
    </Typography>
  );
};

const CoMultiColumnItem = (props) => {
  const { title, value, ...otherProps } = props;

  const classes = useStyles();

  return (
    <div
      style={{
        ...otherProps,
      }}
    >
      <CoMultiColumnRowValue classes={classes} value={value ?? "NA"} />
      <CoMultiColumnRowSubHeading classes={classes} value={title ?? "NA"} />
    </div>
  );
};

const CoMultiColumnRow = (props) => {
  const { row = [], onClick } = props;

  const { id, name } = row;

  let index = 0;
  return (
    <CoNonElevatedPanel
      onClick={
        onClick != null
          ? () => {
              onClick(id);
            }
          : null
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "scroll",
        }}
      >
        {row.items
          .filter((row) => {
            return row.value != null;
          })
          .map((item) => {
            index += 1;
            return (
              <CoMultiColumnItem
                key={index}
                title={item.title}
                value={item.value}
                {...item}
              />
            );
          })}
      </div>
    </CoNonElevatedPanel>
  );
};

export const CoMultiColumnListt = (props) => {
  const { data = [], ...otherProps } = props;

  const lastIndex = data.length - 1;
  let index = 0;

  return data.map((row) => {
    if (index === lastIndex) {
      index += 1;
      return <CoMultiColumnRow key={index} row={row} {...otherProps} />;
    }
    index += 1;
    return (
      <div key={index}>
        <CoMultiColumnRow row={row} {...otherProps} />
        <div style={{ height: "16px" }} />
      </div>
    );
  });
};

const CoTableTemplateTwo = (props) => {
  const { data, isLoading, ...otherProps } = props;

  if (isLoading) {
    return <CoListItemLoading style={{ height: "100px" }} />;
  }

  if (props.data?.length === 0) {
    return <CoListItemEmpty style={{ height: "100px" }} />;
  }

  return (
    <CoMultiColumnListt data={data} isLoading={isLoading} {...otherProps} />
  );
};

export default CoTableTemplateTwo;
