import axios from "axios";
import credentials from "../credentials";

//  adding a default base url
axios.defaults.baseURL = credentials.vendor_management_base_url;

//  adding a default header for every request.
axios.defaults.headers.common = {
  accept: "application/json",
  "client-id": credentials.vendor_management_client_id,
  // authToken:
  // "6d74483fc09effa32fcb284fb8f98d8b99fa8790d2d9d637b9f00e1c3c984afd467330c52a81a39105e21ad5da01a8853f6142df6646d4321945481ed87070dc",
};

//  adding request and response interceptors. We use these
//  primarily for logging requests and responses
axios.interceptors.request.use((request) => {
  console.log("%c REST API REQUEST : ", "color: yellow", request);
  return request;
});

axios.interceptors.response.use((response) => {
  console.log("%c REST API RESPONSE : ", "color: yellow", response);
  return response;
});

const readXMLHttpRequestTypeError = (error) => {
  // eslint-disable-next-line no-underscore-dangle
  const errorResponse = JSON.stringify(error.request.response);
  console.log("apple");
  console.log(errorResponse);

  if (errorResponse.includes("Unable to resolve host")) {
    return new Error(
      JSON.stringify({
        status: "ttt",
        errorCode: "ttt",
        errorType: "ttt",
        message: "Could not connect to the service",
      })
    );
  }
  return new Error(
    JSON.stringify({
      status: "ttt",
      errorCode: "ttq",
      errorType: "NETWORK ERROR",
      message: error.message,
    })
  );
};

//  Here we handle network error. The implementation of this
//  function depends upon the rest api that we are connecting to.
//  Here is how it is implemented for rocket app. It might be
//  different for other apps.
const handleNetworkError = (error) => {
  // console.log("errr");
  // console.log(error);
  // console.log(error.message);
  // console.log(error.response);
  // console.log(error.request);

  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);

      if (error.response.data.error !== undefined) {
        throw new Error(
          JSON.stringify({
            status: error.response.status,
            errorCode: 666,
            errorType: "ApiError",
            message: error.response.data.error,
          })
        );
      }

      //  Unathorized
      if (error.response.status === 401) {
        throw new Error(
          JSON.stringify({
            status: error.response.status,
            errorCode: 401,
            errorType: "UNAUTHORISED",
            message:
              "Your session has expired. Please Login again to continue.",
          })
        );
      }

      if (!error.response.data?.error?.code) {
        throw new Error(
          JSON.stringify({
            status: error.response.status,
            errorCode: 401,
            errorType: "NOT FOUND",
            message: error.response.data.error,
          })
        );
      } else {
        throw new Error(
          JSON.stringify({
            status: error.response.status,
            errorCode: error.response.data.error.code,
            errorType: error.response.data.error.type,
            message: error.response.data.error.message,
          })
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      throw readXMLHttpRequestTypeError(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(
        JSON.stringify({
          status: "ttt",
          errorCode: "ttt",
          errorType: "NETWORK ERROR",
          message: error.message,
        })
      );
    }
  } catch (error) {
    console.log("%c REST Api Error", "color: yellow;");
    console.log(error);
    throw error;
  }
};

//  Use this method to make any post request
export const postRequest = (url, data) =>
  axios.post(url, data).catch((error) => handleNetworkError(error));

//  use this method to make a get request
export const getRequest = (url, data) =>
  axios
    .get(url, {
      params: data,
    })
    .catch((error) => handleNetworkError(error));

export const setAuthTokenHeader = (token) => {
  axios.defaults.headers.common["authToken"] = `${token}`;
};

export const resetAuthTokenHeader = (token) => {
  axios.defaults.headers.common["authToken"] = null;
};
