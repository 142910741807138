import { mapBookingListToTemplateSix } from "./mappings/order.mapping";

export const mapEventsToUiTemplate = (eventList, onCCFButtonClicked, onStatusChange = ()=> {}, status="Na") => {
  return {
    coTemplateSixData: mapBookingListToTemplateSix(
      eventList,
      onCCFButtonClicked,
      onStatusChange,
      status
    ),
  };
};

export const initialEventUiTemplate = {
  coTemplateSixData: {},
};
