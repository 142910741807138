import { createStore, applyMiddleware } from "redux";

//  used for making asynchronous calls that would
//  emit some redux action when the call completes
import thunk from "redux-thunk";

import { createLogger } from "redux-logger";

import rootReducer from "./reducer";

const middlewares = [];
middlewares.push(thunk);

if (process.env.NODE_ENV === `development`) {
  //  creating the logger middleware
  const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });

  middlewares.push(loggerMiddleware);
}

const configureStore = (preloadedState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(...middlewares));

const store = configureStore();
export default store;
