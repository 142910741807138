import React from "react";
import { REQUEST_STATE_UNDEFINED } from "../../common/states/actions.request";

const withSinglePageMockCall = (
  WrappedComponent,
  { propInitialUiTemplate, propMapToUiTemplate, propMapSinglePageMockCall }
) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ...propInitialUiTemplate,
      };

      this.mapMockResponseToUiTemplate = this.mapMockResponseToUiTemplate.bind(
        this
      );
    }

    componentDidMount() {
      const {
        api: { request_state: requestState },
      } = this.props;

      if (requestState === REQUEST_STATE_UNDEFINED) {
        this.makeMockCall(this.props);
      }
    }

    makeMockCall = async (props) => {
      const response = await propMapSinglePageMockCall(props);
      this.mapMockResponseToUiTemplate(response);
    };

    mapMockResponseToUiTemplate(newProps) {
      this.setState({ ...propMapToUiTemplate(newProps) });
    }
  };
};

export default withSinglePageMockCall;
