import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import Iframe from "react-iframe";
import NavigationBar from "../../routes/NavigationBar";

class CCFScreen extends React.Component {
  render() {
    const {
      location: {
        state: { url },
      },
    } = this.props;

    return (
      <div>
        <NavigationBar title={"CCF"} hasBackIcon={true} hasDrawerIcon={false} />
        <Iframe
          position="absolute"
          url={url}
          width="100%"
          id="myId"
          className="myClassname"
          height="100%"
          styles={{ height: "25px" }}
        />
      </div>
    );
  }
}

const ComposedCCfScreen = compose(withRouter)(CCFScreen);
export default ComposedCCfScreen;
