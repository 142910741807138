import React from "react";
import NavigationBar from "../../routes/NavigationBar";
import CoTabs, { LinkTab, TabPanel } from "../../components/CoTabs";

import queryString from "query-string";
import VenueCalendarRecceDetails from "./calendarRecceDetails";
import VenueCalendarBookingDetails from "./calendarBookingDetails";
import { compose } from "redux";

import HigherOrder from "../../components/hoc/index.js";
import { getCalendarDateFromPath } from "../../routes";

import * as moment from "moment";

const getIntialTabIndex = (props) => {
  const { location: { search = undefined } = {} } = props;

  getSelectedDateFromRoute(props);

  if (search !== undefined) {
    const query_params = queryString.parse(search);
    const selected = query_params["selected"];

    if (selected !== undefined && isNaN(selected) === false) {
      const selectedTab = parseInt(selected);

      if (selectedTab > 0 && selectedTab < 3) {
        return selectedTab;
      }
    }
  }

  return 0;
};

const getSelectedDateFromRoute = (props) => {
  const {
    location: { pathname = undefined },
  } = props;

  const date = getCalendarDateFromPath(pathname);
  return date;
};

const VenueCalendarDetails = (props) => {
  const getLinkTab = (index) => {
    switch (index) {
      case 0:
        return (
          <LinkTab key={index} label="Visits" href="/venue/metrics/recces" />
        );
      case 1:
        return (
          <LinkTab
            key={index}
            label="Bookings"
            href="/venue/metrics/bookings"
          />
        );

      default:
        return null;
    }
  };

  const getTabPanel = (index, selectedValue) => {
    const routeDateString = getSelectedDateFromRoute(props);
    const routeDateFormat = moment(routeDateString, "DDMMYYYY").format(
      "DD-MM-YYYY"
    );

    switch (index) {
      case 0:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <VenueCalendarRecceDetails
              selectedDate={routeDateFormat}
              showAsTab={true}
            />
          </TabPanel>
        );
      case 1:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <VenueCalendarBookingDetails
              selectedDate={routeDateFormat}
              showAsTab={true}
            />
          </TabPanel>
        );
      //   case 2:
      //     return (
      //       <TabPanel key={index} value={selectedValue} index={index}>
      //         <PastBusinessMetrics showAsTab={true} />
      //       </TabPanel>
      //     );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavigationBar title="Calendar Details" hasBackIcon={true} />
      <CoTabs
        tabCount={2}
        initialTabIndex={getIntialTabIndex(props)}
        getLinkTab={getLinkTab}
        getTabPanel={getTabPanel}
      />
    </div>
  );
};

const ComposedVenueCalendarDetails = compose(HigherOrder.withHistoryObject)(
  VenueCalendarDetails
);

export default ComposedVenueCalendarDetails;
