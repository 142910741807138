// Saves Splash Timeout Var. This will be used to cancel the Timer if necessary
let splashTimeout = null;

const resetSplashTimer = () => clearTimeout(splashTimeout);

const createSplashTimer = ({ seconds, onComplete }) => {
  resetSplashTimer();

  splashTimeout = setTimeout(onComplete, seconds);
};

export default {
  createSplashTimer: createSplashTimer,
  resetSplashTimer: resetSplashTimer,
};
