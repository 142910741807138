import React from "react";
import { connect } from "react-redux";
import { subscribeToBusinessMetrics } from "./states/reducers";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import {
  REQUEST_KEY_MONTHLY_BOOKING_LIST,
  requestMonthlyBookingList,
  updateBookingStatus,
} from "./states/actions";
import {
  REQUEST_STATE_LOADING,
  REQUEST_STATE_ERROR,
  REQUEST_STATE_STAND_BY,
} from "../../common/states/actions.request";
import {
  BookingApproved,
  BookingRejected,
  AlreadyReplied,
  CustomerResponsePending
} from "../../common/utils/constants";
import NavigationBar from "../../routes/NavigationBar";
import { LoadingByRequestState } from "../../components/LoadingScreen";
import { Error, EmptyCard, CoErrorScreen } from "../../components/EmptyScreen";
import CoSectionWithDropDown from "../../components/CoSectionWithDropDown";

import { mapBookingListToTemplateSix, mapBookingListToBookingTabs } from "../events/mappings/order.mapping";
import { CoCardTemplateSixList } from "../../components/templates/CoCardTemplateSix";
import CoMultiColumnRow from "./bookingComponents/BookingTabs";
import {
  getDropDownOptionList,
  getStateFormattedDate,
  getMonthFormattedDate,
  getOption,
} from "./index";
import { compose } from "redux";
import withSplashScreenNavigation from "../../components/hoc/withSplashScreenNavigation";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import { createVenuePath } from "../../routes";
import {VenueOrderService} from "../../data/VenueManagementService";
import ShowSnackbar from "../../components/ShowSnackbar";

class BMBookingDetails extends React.Component {
  constructor(props) {
    super(props);

    const { monthId = -1 } = props;

    const {
      optionList,
      selectedOption,
      selectedStateFormattedDate,
    } = getDropDownOptionList(monthId);

    this.state = {
      templateSixData: {},
      tabsTemplateData: {},
      status: "pending",
      optionList: optionList,
      selectedOption: selectedOption,
      selectedStateFormattedDate: selectedStateFormattedDate,
      snackBarData: {},
    };

    this.onCCFButtonClicked = this.onCCFButtonClicked.bind(this);
    this.onCountTabChange = this.onCountTabChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.onDropDownOptionChanged = this.onDropDownOptionChanged.bind(this);
  }

  componentDidMount() {
    this.getMonthlyBookingList(this.props, this.state);
    this.mapBookingListDataToUiTemplate(this.props);
  }

  getMonthlyBookingList(props, state) {
    const { selectedOption, selectedStateFormattedDate } = state;

    const {
      api: { request_state: requestState },
      user: {
        selected_venue: { venue_id: venueId },
      },
      requestMonthlyBookingList,
      businessMetrics: {
        monthly_bookings: {
          [selectedStateFormattedDate]: {
            event_list: monthlyBookingList = null,
          } = {},
        } = {},
      } = {},
    } = props;

    if (requestState !== REQUEST_STATE_LOADING) {
      if (monthlyBookingList !== null) {
        console.log(
          "Data already exists. No need to call",
          selectedStateFormattedDate
        );
        this.mapBookingListDataToUiTemplate(props);
        return;
      }

      if (venueId !== undefined) {
        requestMonthlyBookingList({
          venueId: venueId,
          monthId: selectedOption.id,
        });
      }
    }
  }

  onCCFButtonClicked(booking) {
    const { pushPath } = this.props;
    const venuePath = createVenuePath(this.props);
    pushPath(`${venuePath}/events/ccf`, {
      title: "CCF",
      url: (typeof booking === 'string' || booking instanceof String)? booking : booking["ccf_url"],
    });
    // window.open("https://www.alibaba.com/premium/dual_monitor_arm.html");
  }

  onCountTabChange(status) {
    // const { setMonthId } = this.props;

    const setStateCallback = () => {
      this.getMonthlyBookingList(this.props, this.state);
    };
    this.setState({
      status: status,
    },
    setStateCallback,
    );
  }

  async onStatusChange(selected, reason, bookingDetails){
    const { selectedStateFormattedDate } = this.state;

    const {
      api: { request_state: requestState },
      user: {
        selected_venue: { venue_id: venueId },
      },
      updateBookingStatus,
      businessMetrics: {
        monthly_bookings: {
          [selectedStateFormattedDate]: bookingList= {},
        } = {},
      } = {},
    } = this.props;

    const params = {
      venueId: venueId,
      documentUrl: bookingDetails["ccf_url"], 
      oldStatus: bookingDetails["vo_status"], 
      newStatus: selected, 
      rejection_reason: reason,
      selectedStateFormattedDate: selectedStateFormattedDate,
      bookingList: bookingList,
      booking_id: bookingDetails['booking_id'],
    }
    if (requestState !== REQUEST_STATE_LOADING) {
        return await updateBookingStatus(params).then((response)=>{
          const ui_data = { //[message , severity , color]
            CustomerResponsePending : ['Wait for customer to approve.', 'info', 'info'],
            BookingApproved: ['Approved', 'success', 'success'],
            BookingRejected: ['Rejected', 'success', 'error'],
            AlreadyReplied: ['Already Replied', 'info', 'info'],
          }
          if(response)  {
            this.setState({
              snackBarData: { "open": true, 
                              "message": ui_data[response][0], 
                              "severity": ui_data[response][1], 
                              "color": ui_data[response][2],},
              status: "pending",
            },
            ()=>{this.getMonthlyBookingList(this.props, this.state)});
          }
        });
    }
  }

  handleSnackbarClose= ()=>{
    const {severity, color} = this.state.snackBarData;
    this.setState({
      snackBarData: { "open": false, "message": "", "severity": severity, "color": color },
    });
  }

  mapBookingListDataToUiTemplate(props) {
    const { selectedStateFormattedDate, status } = this.state;

    const {
      businessMetrics: {
        monthly_bookings: {
          [selectedStateFormattedDate]: { event_list: monthlyBookingList } = {},
        } = {},
      } = {},
    } = props;

    this.setState({
      templateSixData: mapBookingListToTemplateSix(
        monthlyBookingList,
        this.onCCFButtonClicked,
        this.onStatusChange,
        status,
      ),
      tabsTemplateData: mapBookingListToBookingTabs(
        monthlyBookingList,
        this.onCountTabChange,
      )
    });
  }

  onDropDownOptionChanged(option) {
    const { setMonthId } = this.props;

    const setStateCallback = () => {
      this.getMonthlyBookingList(this.props, this.state);
      if (setMonthId != null) {
        setMonthId(option.id);
      }
    };

    this.setState(
      {
        selectedOption: option,
        selectedStateFormattedDate: getStateFormattedDate(option.id),
      },
      setStateCallback
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      api: { request_state: newRequestState },
    } = nextProps;

    const {
      api: { request_state: oldRequestState },
    } = this.props;

    if (
      oldRequestState === REQUEST_STATE_LOADING &&
      newRequestState === REQUEST_STATE_STAND_BY
    ) {
      this.mapBookingListDataToUiTemplate(nextProps);
      return false;
    }

    return true;
  }

  render() {
    const {
      templateSixData,
      tabsTemplateData,
      status,
      optionList,
      selectedOption,
      selectedStateFormattedDate,
      snackBarData,
    } = this.state;

    const {
      api: {
        request_state: requestState,
        loading: { message: loadingMessage } = {},
        error: { message: errorMessage, code: errorCode } = {},
      },
      businessMetrics: {
        monthly_bookings: {
          [selectedStateFormattedDate]: { event_list: monthlyBookingList } = {},
        } = {},
      },
      showAsTab = false,
    } = this.props;

    return (
      <div>
        {!showAsTab ? <NavigationBar title={"Business Metrics"} hasBackIcon={true} /> : null}
        <LoadingByRequestState
          message={loadingMessage}
          requestState={requestState}
        >
          <CoErrorScreen
            hasMargins={false}
            hasError={requestState === REQUEST_STATE_ERROR}
            isLoading={requestState === REQUEST_STATE_LOADING}
            message={errorMessage}
            statusCode={errorCode}
            onRetryButtonClicked={() => {
              this.getMonthlyBookingList(this.props, this.state);
            }}
          >
            <div style={{ margin: "20px" }}>
              <CoSectionWithDropDown
                title={"Booking Details"}
                optionList={optionList}
                selectedOption={selectedOption}
                onOptionChanged={this.onDropDownOptionChanged}
              >
                <div style={{ marginBottom: "20px" }}>
                    <CoMultiColumnRow data={tabsTemplateData.data} />
                  </div>
                <EmptyCard
                  hasMargins={false}
                  isEmpty={templateSixData.data?.length === 0}
                  title={"No Bookings Found"}
                  subtitle={`There were no ${status} bookings for the month of ${getMonthFormattedDate(
                    selectedOption.id
                  )}`}
                >
                  <CoCardTemplateSixList data={templateSixData.data} />
                </EmptyCard>
                <ShowSnackbar data={snackBarData} onClose={this.handleSnackbarClose}/>
              </CoSectionWithDropDown>
            </div>
          </CoErrorScreen>
        </LoadingByRequestState>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  businessMetrics: subscribeToBusinessMetrics(state),
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_MONTHLY_BOOKING_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestMonthlyBookingList: (params) =>
    dispatch(requestMonthlyBookingList({ ...params })),
  updateBookingStatus: (params) => dispatch(updateBookingStatus({ ...params })),
});

const ComposedBMBookingDetails = compose(
  withHistoryObject,
  withSplashScreenNavigation
)(BMBookingDetails);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedBMBookingDetails);
