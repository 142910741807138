import React from "react";
import { CircularProgress } from "@material-ui/core";

const styles = {
  circularContainer: {
    position: "absolute",
    bottom: 0,
    marginBottom: "60px",
    backgroundColor: "white",
    width: "46px",
    height: "46px",
    borderRadius: "23px",
    display: "flex",
    justifyContent: "center",
  },
  circularProgressBar: {
    color: "#2298DA",
    alignSelf: "center",
    opacity: 1.0,
  },
};

export const CoCircularProgressBar = (props) => {
  return (
    <div style={styles.circularContainer}>
      <CircularProgress
        thickness={3.5}
        size={35}
        style={styles.circularProgressBar}
      />
    </div>
  );
};
