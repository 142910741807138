import React from "react";
import ReactDOM from "react-dom";
import "./common/styles/index.css";
import * as serviceWorker from "./serviceWorker";
import AppRouter from "./routes";
import App from "./App";

import ReduxStore from "./data/sources/store/redux";
import {
  SET_UPDATE_STATE,
  UPDATE_STATE_AVAILABLE,
  UPDATE_STATE_SUCCESS,
} from "./common/states/actions.update";

require("dotenv").config();

ReactDOM.render(
  <React.StrictMode>
    <AppRouter>
      <App />
    </AppRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

const onUpdate = (registration) => {
  console.log("SWW : onUpdate", registration);
  ReduxStore.dispatch({
    type: SET_UPDATE_STATE,
    state: UPDATE_STATE_AVAILABLE,
  });
};

const onSuccess = (registration) => {
  console.log("SWW : onSuccess", registration);

  //  There should be a small delay before we fire this action.
  //  Give some time for app to initialize itself
  setTimeout(() => {
    console.log("SWW : updating update status in redux state now");
    ReduxStore.dispatch({
      type: SET_UPDATE_STATE,
      state: UPDATE_STATE_SUCCESS,
    });
  }, 3000);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: onUpdate,
  onSuccess: onSuccess,
});
