import { combineReducers } from "redux";
import shared from "../../../../common/states/reducers";
import reviews from "../../../../modules/reviews/states/reducers";
import venueDashboard from "../../../../modules/venueDashboard/states/reducers";
import user from "../../../../modules/venueSelection/states/reducers";
import businessMetrics from "../../../../modules/businessMetrics/states/reducers";
import userLogin from "../../../../modules/login/states/reducers";
import events from "../../../../modules/events/states/reducers";
import recces from "../../../../modules/recces/states/reducers";
import venueCalendar from "../../../../modules/calendar/states/reducers";

export default combineReducers({
  user: user,
  login: userLogin,
  shared: shared,
  events: events,
  recces: recces,
  reviews: reviews,
  venueDashboard: venueDashboard,
  businessMetrics: businessMetrics,
  venueCalendar: venueCalendar,
});
