import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { CoElevatedPanel } from "../CoPanel";
import Rating from "@material-ui/lab/Rating";
import CoCollapsablePanel from "../CoCollapsablePanel";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.7,
      lineHeight: 1.51,
      marginRight: 5,
    },
    value: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.51,
      textAlign: "right",
    },
  })
);

const TwoColumnRowTitle = (props) => {
  const { classes, value } = props;

  return (
    <Typography style={{ flex: 2 }} variant="body1" className={classes.title}>
      {value}
    </Typography>
  );
};

const TwoColumnRowValue = (props) => {
  const { classes, value } = props;

  return (
    <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
      {value}
    </Typography>
  );
};

const TwoColumnRowRatingValue = (props) => {
  const { value, showValue = false, classes } = props;
  return (
    <div style={{ display: "flex" }}>
      <Rating
        name="read-only"
        size={"small"}
        precision={0.5}
        value={value}
        readOnly
      />
      {showValue ? (
        <Typography
          style={{ marginLeft: "5px", alignSelf: "center" }}
          variant="body1"
          className={classes.value}
        >
          {value}
        </Typography>
      ) : null}
    </div>
  );
};

const TwoColumnRow = (props) => {
  const { title, value, type = "text", classes, ...otherProps } = props;

  const getRowComponent = (type) => {
    switch (type) {
      case "text":
        return <TwoColumnRowValue classes={classes} value={value ?? "NA"} />;

      case "rating":
        return (
          <TwoColumnRowRatingValue
            classes={classes}
            value={value ?? 0}
            {...otherProps}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <TwoColumnRowTitle classes={classes} value={title ?? "NA"} />
      {getRowComponent(type)}
    </div>
  );
};

const CoCardTemplateOneUnwrapped = (props) => {
  const { items = [], isLoading = false, ...restProps } = props;
  const classes = useStyles();

  const lastIndex = items.length - 1;
  let index = 0;

  return (
    <>
      {items.length === 0 ? (
        <TwoColumnRowTitle classes={classes} value={"No Records Found"} />
      ) : (
        items.map((item) => {
          if (index === lastIndex) {
            index += 1;
            return (
              <TwoColumnRow
                classes={classes}
                key={index}
                title={item.title}
                value={item.value}
                {...item}
              />
            );
          }
          index += 1;
          return (
            <div key={index}>
              <TwoColumnRow
                title={item.title}
                classes={classes}
                value={item.value}
                {...item}
              />
              <div style={{ height: "8px" }} />
            </div>
          );
        })
      )}
    </>
  );
};

const CoCardTemplateOne = (props) => {
  const { header = undefined, isLoading = false, ...restProps } = props;

  return header === undefined ? (
    <CoElevatedPanel isLoading={isLoading} {...restProps}>
      <CoCardTemplateOneUnwrapped {...props} />
    </CoElevatedPanel>
  ) : (
    <CoCollapsablePanel isLoading={isLoading} header={header}>
      <CoCardTemplateOneUnwrapped {...props} />
    </CoCollapsablePanel>
  );
};

export const CoCardTemplateOneList = (props) => {
  const { data = [], ...otherProps } = props;

  const lastIndex = data.length - 1;
  let index = 0;

  return data.map((row) => {
    const { id, items } = row;
    if (index === lastIndex) {
      index += 1;
      return (
        <CoCardTemplateOne
          key={id ?? index}
          items={items}
          {...row}
          {...otherProps}
        />
      );
    }
    index += 1;

    return (
      <div key={id ?? index}>
        <CoCardTemplateOne items={items} {...row} {...otherProps} />
        <div style={{ height: "16px" }} />
      </div>
    );
  });
};

export default CoCardTemplateOne;
