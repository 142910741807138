console.log("CREDENTIALS 1 : ", process.env.REACT_APP_ENV);
console.log("CREDENTIALS 2 : ", process.env.NODE_ENV);

const appVersionCode = 4;
const appSemanticVersionCode = "1.0.9";

export default {
  swapi_base_url: "http://swapi.dev",
  vendor_management_base_url: `${process.env.REACT_APP_VENUE_MANAGEMENT_BASE_URL}`,
  vendor_management_client_id: `${process.env.REACT_APP_VENUE_MANAGEMENT_CLIENT_ID}`,
  support_contact_number: "+918800231045",
  support_contact_form:
    "https://docs.google.com/forms/d/e/1FAIpQLSeJYdwpcGHPdCMoxSAnzQGk_m3vmuu4Frv4odvjCa0m62TDgw/viewform",
  user_manual_url:
    "https://docs.google.com/presentation/d/1mVZG8TYi5mitkfz5Lh-6mw1GK_OMsdwwfK0cqXxR9bA/edit?usp=sharing",
  app_version: {
    code: appVersionCode,
    semanticCode: appSemanticVersionCode,
    flavour: `${process.env.REACT_APP_FLAVOUR ?? ""}`,
  },
};
