import {
  SET_USER_VENUE_LIST,
  RESET_USER_VENUE_LIST,
  SET_SELECTED_VENUE,
  RESET_SELECTED_VENUE,
  SET_USER_STATE,
  RESET_USER_STATE,
  SET_DEVICE_TOKEN,
  RESET_DEVICE_TOKEN,
} from "./actions";

const initialState = {
  user_venue_list: [],
  selected_venue: {
    // venue_id: "5caa469ffc4858715be3a08b",
    // venue_name: "Lemon Tree Hotel",
    // venue_readable_name: "Lemon Tree Hotel",
    // venue_location: "Test Account 1",
  },
  logged_in_user: {
    logged_in_date: undefined,
    visit_date: undefined,
  },
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_VENUE_LIST:
      return Object.assign({}, state, {
        user_venue_list: action.userVenueList,
      });

    case SET_SELECTED_VENUE:
      return Object.assign({}, state, {
        selected_venue: action.selectedVenue,
      });

    case RESET_USER_VENUE_LIST:
      return Object.assign({}, state, {
        user_venue_list: initialState.user_venue_list,
      });
    case RESET_SELECTED_VENUE:
      return Object.assign({}, state, {
        selected_venue: initialState.selected_venue,
      });

    case SET_USER_STATE:
      return Object.assign({}, state, {
        ...action.user,
      });

    case RESET_USER_STATE:
      return Object.assign({}, state, {
        ...initialState,
        logged_in_user: {
          ...initialState.logged_in_user,
          ...initializeLoggedInUserDates(initialState),
        },
      });

    case SET_DEVICE_TOKEN:
      return Object.assign({}, state, {
        logged_in_user: {
          ...state.logged_in_user,
          device_token: action.deviceToken,
        },
      });

    case RESET_DEVICE_TOKEN:
      return Object.assign({}, state, {
        logged_in_user: {
          ...state.logged_in_user,
          device_token: undefined,
        },
      });

    default:
      return state;
  }
};

const initializeLoggedInUserDates = (state) => ({
  visit_date: state.logged_in_user.visit_date ?? new Date(),
  logged_in_date: undefined,
});

export default user;

export const subscribeToUser = (state) => state?.user;
