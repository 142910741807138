import React from "react";

import {
  Typography,
  createStyles,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { CoElevatedPanel } from "./CoPanel";

import CoNoBookingsIcon from "../assets/icons/co_no_bookings.svg";
import CoErrorIcon from "../assets/icons/co_error_icon.svg";
import {
  LoginPrimaryButton,
  FooterButton,
} from "../modules/login/components/LoginBackground";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      marginTop: "15px",
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      lineHeight: "1.5",
      alignSelf: "center",
    },
    actionButtonLabel: {
      textTransform: "capitalize",
      color: "#ee2e24",
      fontSize: "16px",
      fontFamily: "Brown-Bold",
      marginTop: "3px",
      lineHeight: "1.2",
    },
    root: {
      position: "relative",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    top: {
      color: "#1a90ff",
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },

    errorCardTitle: {
      marginTop: "24px",
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.87,
    },

    errorCardSubTitle: {
      marginTop: "16px",
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.54,
      lineHeight: 1.43,
      textAlign: "center",
    },

    errorMessage: {
      marginTop: "17px",
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.37,
      lineHeight: 1.43,
      textAlign: "center",
    },
    erroCardMessage: {
      marginTop: "16px",
      fontFamily: "Brown",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.5,
    },
  })
);

const EmptyScreen = (props) => {
  const classes = useStyles();

  const { message = "This list looks empty", isLoading } = props;
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        display: "flex",
        marginTop: "56px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="body1" className={classes.title}>
          {isLoading ? "" : message}
        </Typography>
      </div>
    </div>
  );
};

const ErrorScreen = (props) => {
  const classes = useStyles();

  const { message = "Something went wrong", isLoading } = props;
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        display: "flex",
        marginTop: "56px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <ErrorOutlineIcon
          style={{ width: "50px", height: "50px", color: "red" }}
        />
        <Typography variant="body1" style={{}} className={classes.title}>
          {isLoading ? "" : message}
        </Typography>
      </div>
    </div>
  );
};

const Empty = (props) => {
  const {
    isEmpty = false,
    EmptyComponent = EmptyCard,
    children,
    ...restProps
  } = props;

  return isEmpty ? (
    <EmptyComponent {...props.loading} {...restProps} />
  ) : (
    children
  );
};

export const Error = (props) => {
  const {
    hasError = false,
    ErrorComponent = ErrorScreen,
    children,
    ...restProps
  } = props;

  return hasError ? (
    <ErrorComponent {...props.loading} {...restProps} />
  ) : (
    children
  );
};

export const ErrorCard = (props) => {
  const {
    hasError = false,
    children = <div />,
    message = "No Records found",
    height = "352px",
    hasMargins,
  } = props;
  const classes = useStyles();

  return hasError ? (
    <div style={{ marging: hasMargins ? "20px" : "0px" }}>
      <CoElevatedPanel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: height,
          }}
        >
          <img src={CoNoBookingsIcon} alt="no bookings icon" />
          <Typography variant="body1" className={classes.erroCardMessage}>
            {message}
          </Typography>
        </div>
      </CoElevatedPanel>
    </div>
  ) : (
    children
  );
};

export const CoErrorScreen = (props) => {
  const {
    hasMargins = true,
    hasError = false,
    children,
    message,
    statusCode,
    onRetryButtonClicked,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  if(statusCode === 401){
    console.log('UNAUTHORISED or Session Expired.. Redirecting to login page');
    history.replace('/login');
  }
  return hasError ? (
    <div
      style={{
        margin: hasMargins ? "20px" : "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "352px",
      }}
    >
      <img src={CoErrorIcon} alt="error icon" />
      <Typography variant="body1" className={classes.errorMessage}>
        {message}
      </Typography>

      {onRetryButtonClicked ? (
        <div style={{ marginTop: "40px" }}>
          <LoginPrimaryButton
            value="Retry"
            onPrimaryButtonClicked={onRetryButtonClicked}
          />
          {/* <FooterButton onClick={onRetryButtonClicked} value="Retry" /> */}
        </div>
      ) : null}
    </div>
  ) : (
    children
  );
};

export const EmptyCard = (props) => {
  const {
    isEmpty = false,
    children = <div />,
    title = "No Records found",
    subtitle = "We were not able to find any data",
    height = "352px",
    hasMargins = true,
  } = props;
  const classes = useStyles();

  return isEmpty ? (
    <div style={{ margin: hasMargins ? "20px" : "0px" }}>
      <CoElevatedPanel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: height,
          }}
        >
          <img src={CoNoBookingsIcon} alt="no bookings icon" />
          <Typography variant="body1" className={classes.errorCardTitle}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.errorCardSubTitle}>
            {subtitle}
          </Typography>
        </div>
      </CoElevatedPanel>
    </div>
  ) : (
    children
  );
};

export default Empty;
