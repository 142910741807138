import React from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  IconButton,
} from "@material-ui/core";

import CoSettingsIcon from "../assets/icons/co_settings_icon.svg";
import CoDropDownIcon from "../assets/icons/dropdown_icon.svg";

import { CoCircleImage } from "../components/templates/CoCardTemplateFive";

import credentials from "../data/sources/api/credentials";
import { ElevatedContainerText2 } from "../modules/login/components/LoginBackground";

const useStyles = makeStyles((theme) =>
  createStyles({
    headerTitle: {
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.54,
      lineHeight: 1.5,
    },
    value: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.51,
      textAlign: "right",
    },

    subHeadingTitle: {
      opacity: 0.54,
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      lineHeight: 1.7,
      paddingTop: "3px",
      color: "#000000",
    },
    menuItemTitle: {
      opacity: 0.87,
      fontFamily: "Brown-Bold",
      lineHeight: 1.51,
      color: "#000000",
      paddingTop: "3px",
      fontSize: "16px",
    },
    menuButton: {},
    helpLabel: {
      fontFamily: "Brown-Bold",
      color: "#000000",
      textAlign: "left",
      fontSize: "16px",
    },
    dropDownLabel: {
      fontFamily: "Brown-Bold",
      opacity: 0.87,
      fontSize: "16px",
      lineHeight: 1.11,
      textAlign: "left",
    },
  })
);

const styles = {
  divider: {
    height: "1px",
    opacity: 0.06,
    backgroundColor: "#000000",
  },
};

const SettingsButton = (props) => {
  const { onHeaderIconClicked, classes } = props;

  return (
    <IconButton
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
      onClick={onHeaderIconClicked}
    >
      {/* <img src={CoSettingsIcon} alt="settings" /> */}
      <div style={{ height: "20px" }} />
    </IconButton>
  );
};

const Divider = () => <div style={styles.divider} />;

const DrawerSubHeading = (props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "#f3f5f7",
      }}
    >
      <Typography
        style={{ flex: 2, alignSelf: "center" }}
        variant="body1"
        className={classes.subHeadingTitle}
      >
        {"ACTIVE SERVICES"}
      </Typography>
    </div>
  );
};

const DrawerMenuItem = (props) => {
  const classes = useStyles();

  const { title, onClick } = props;

  return (
    <div
      style={{
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Typography
        style={{ flex: 2, alignSelf: "center" }}
        variant="body1"
        className={classes.menuItemTitle}
      >
        {title ?? "Bookings"}
      </Typography>
    </div>
  );
};

export const CoDrawerHelpItem = (props) => {
  const classes = useStyles();

  const { onHelpItemClicked } = props;

  return (
    <div
      onClick={onHelpItemClicked}
      style={{
        // backgroundImage: "linear-gradient(99deg, #d11450 2%, #ef4123)",
        // backgroundColor: "#D80666",
        width: "100%",
      }}
    >
      <Typography
        style={{
          display: "inline-block",
          marginLeft: "20px",
          marginBottom: "15px",
          marginTop: "15px",
        }}
        variant="body1"
        className={classes.helpLabel}
      >
        {"Need Help ?"}
      </Typography>
    </div>
  );
};

export const CoDrawerDropDownItem = (props) => {
  const classes = useStyles();

  const { id, title, onClick } = props;

  return (
    <div>
      <div
        onClick={onClick}
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          marginLeft: "20px",
          marginRight: "15px",
          display: "flex",
        }}
      >
        <div style={{ flex: 2, display: "flex", alignItems: "center" }}>
          <CoCircleImage />
        </div>
        <div style={{ flex: 6 }}>
          <Typography
            style={{
              display: "inline-block",
              marginBottom: "15px",
              marginTop: "18px",
            }}
            variant="body1"
            className={classes.dropDownLabel}
          >
            {title ?? "NA"}
          </Typography>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img src={CoDropDownIcon} alt="dropdown" />
        </div>
      </div>
      <div
        style={{
          marginBottom: "10px",
          marginRight: "15px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ElevatedContainerText2
          value={`v ${credentials.app_version.semanticCode} ${credentials.app_version.flavour}`}
        />
      </div>
    </div>
  );
};

export const CoDrawerHeader = (props) => {
  const classes = useStyles();

  const {
    user: {
      logged_in_user: { name: venueOwnerName },
    },
  } = props;

  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          marginLeft: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Typography
            style={{ flex: 2, alignSelf: "center" }}
            variant="body1"
            className={classes.headerTitle}
          >
            {`Hi, ${venueOwnerName}`}
          </Typography>
          <SettingsButton
            classes={classes}
            // onHeaderIconClicked={() => {
            //   props.history.push("/settings");
            // }}
          />
        </div>
      </div>
      <Divider />
      {/* <DrawerMenuItem />
      <DrawerMenuItem title={"Digital Board"} />
      <DrawerMenuItem title={"Visibility Board"} />
      <DrawerSubHeading /> */}
    </div>
  );
};
