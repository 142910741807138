import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackBar:{
    width: '95%',
    backgroundColor: "#212121",
    borderRadius: "4px",
    color: "#ffffff",
  },
}));

export default function ShowSnackbar(props) {
  const classes = useStyles();

  const {data, onClose} = props;
  const { severity= "success", color, message='', open=false } = data;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <div className={classes.root}>
      <Snackbar classes={{anchorOriginBottomCenter: classes.snackBar}} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert className={classes.snackBar} variant="standard" severity={severity} color={color ?? severity } onClose={handleClose}>
            {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
