import React from "react";
import { FILTER_DATE_FORMAT, STATE_DATE_FORMAT } from "./states/actions";

import { monthList } from "../../common/utils/month.list";
import queryString from "query-string";

import {
  getCurrentDate,
  formatDate,
  getStartOfMonth,
  getValidYearByMonthId,
} from "../../common/utils";

import BMRecceDetails from "./recceDetails";
import BMBookingDetails from "./bookingDetails";

import NavigationBar from "../../routes/NavigationBar";
import PastBusinessMetrics from "./pastBusinessMetrics";
import CoTabs, { LinkTab, TabPanel } from "../../components/CoTabs";
import { compose } from "redux";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import withNotificationHandler from "../../components/hoc/withNotificationHandler";

export const getStateFormattedDate = (monthId) => {
  const year = getValidYearByMonthId(monthId);
  let startDate = getStartOfMonth({
    month: monthId - 1,
    year: year,
    format: FILTER_DATE_FORMAT,
  });

  const stateFromattedDate = formatDate(startDate, STATE_DATE_FORMAT);
  return stateFromattedDate;
};

export const getMonthFormattedDate = (monthId) => {
  const year = getValidYearByMonthId(monthId);
  let startDate = getStartOfMonth({
    month: monthId - 1,
    year: year,
    format: FILTER_DATE_FORMAT,
  });

  const stateFromattedDate = formatDate(startDate, "MMMM yyyy");
  return stateFromattedDate;
};

export const getDropDownOptionList = (monthId = -1) => {
  const currentYear = getCurrentDate("YYYY");
  const currentMonth = getCurrentDate("M");
  const monthIndex = monthId !== -1 ? monthId : currentMonth;

  return {
    optionList: monthList
      .slice(0, currentMonth)
      .reverse()
      .concat(
        currentYear + "" === "2020"
          ? []
          : monthList.slice(currentMonth).reverse()
      ),

    selectedOption: getOption(monthIndex),
    selectedStateFormattedDate: getStateFormattedDate(monthIndex),
  };
};

export const getOption = (monthId) => {
  return monthList[monthId - 1];
};

const getIntialTabIndex = (props) => {
  const { location: { search = undefined } = {} } = props;

  if (search !== undefined) {
    const query_params = queryString.parse(search);
    const selected = query_params["selected"];

    if (selected !== undefined && isNaN(selected) === false) {
      const selectedTab = parseInt(selected);

      if (selectedTab > 0 && selectedTab < 3) {
        return selectedTab;
      }
    }
  }

  return 0;
};

const BusinessMetrics = (props) => {
  const [recceMonthId, setRecceMonthId] = React.useState(-1);
  const [bookingMonthId, setBookingMonthId] = React.useState(-1);

  const getLinkTab = (index) => {
    switch (index) {
      case 0:
        return (
          <LinkTab key={index} label="Visits" href="/venue/metrics/recces" />
        );
      case 1:
        return (
          <LinkTab
            key={index}
            label="Bookings"
            href="/venue/metrics/bookings"
          />
        );
      case 2:
        return (
          <LinkTab
            key={index}
            label="Past Performance"
            href="/venue/metrics/past"
          />
        );
      default:
        return null;
    }
  };

  const getTabPanel = (index, selectedValue) => {
    switch (index) {
      case 0:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <BMRecceDetails
              monthId={recceMonthId}
              setMonthId={setRecceMonthId}
              showAsTab={true}
            />
          </TabPanel>
        );
      case 1:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <BMBookingDetails
              monthId={bookingMonthId}
              setMonthId={setBookingMonthId}
              showAsTab={true}
            />
          </TabPanel>
        );
      case 2:
        return (
          <TabPanel key={index} value={selectedValue} index={index}>
            <PastBusinessMetrics showAsTab={true} />
          </TabPanel>
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavigationBar title="Performance Report" hasBackIcon={true} />
      <CoTabs
        tabCount={3}
        initialTabIndex={getIntialTabIndex(props)}
        getLinkTab={getLinkTab}
        getTabPanel={getTabPanel}
      />
    </div>
  );
};

const ComposedBusinessMetrics = compose(
  withHistoryObject,
  withNotificationHandler
)(BusinessMetrics);

export default ComposedBusinessMetrics;
