import { mapOrderSummaryResponse } from "./order.mappings";
import { mapVenueOverallRatingApiResponse } from "./review.mappings";
import { mapRecceSummaryResponse } from "./recce.mappings";
import { parseDate } from "../../common/utils/RestUtils";

const mapBusinessMetricsSummaryResponse = (businessMetricsSummary = {}) => {
  return {
    recce_count: businessMetricsSummary?.receesCount,
    closure_count: businessMetricsSummary?.ordersCount,
    lead_count: businessMetricsSummary?.leadsCount,
    lead_to_recce_percentage: businessMetricsSummary?.l2r,
    recce_to_closure_percentage: businessMetricsSummary?.r2c,
    gmv: businessMetricsSummary?.gmv,
  };
};

export const mapMonthWiseOccupancyResponse = (monthWiseOccupancy = {}) => {
  const { occupancyList = [] } = monthWiseOccupancy;

  const occupancy_list_summary = occupancyList.map((occupancy) => {
    return {
      month: parseDate({ dateString: occupancy.startDate }),
      event_count: occupancy.totalEventCount,
      gmv_amount: occupancy.totalGmvAmount,
    };
  });

  return occupancy_list_summary;
};

export const mapBusinessMetricsMonthWiseApiResponse = (response = {}) => {
  const {
    data: { businessMetricsMonthWise = {} },
  } = response;

  const businessMetricsList = Object.keys(businessMetricsMonthWise)
    .map((key) => {
      const metrics = mapBusinessMetricsSummaryResponse(
        businessMetricsMonthWise[key]
      );

      return {
        month: parseDate({ dateString: key }),
        metrics: metrics,
      };
    })
    .sort((m1, m2) => {
      return m2.month - m1.month;
    });

  return {
    metrics_list: businessMetricsList,
  };
};

const mapOrderCountsSummaryResponse = (order_counts = {
  approved:0,
  pending: 0,
  rejected:0,
}) => {
  return {
    approved: order_counts.approved,
    pending: order_counts.pending,
    rejected: order_counts.rejected,
  }
}

export const mapVenueDashboardApiResponse = (response) => {
  const {
    data: {
      venueId: venue_id,
      businessMetrics: business_metrics_summary,
      upcomingOrders: upcoming_orders_summary,
      upcomingRecees: upcoming_recces_summary,
      ratings: customerRatings,
      monthWiseOccupancy: month_wise_occupancy,
      orderCounts: order_counts,
    },
  } = response;

  const customerReviews = mapVenueOverallRatingApiResponse(customerRatings);
  const upcomingOrders = mapOrderSummaryResponse(upcoming_orders_summary);
  const upcomingRecces = mapRecceSummaryResponse(upcoming_recces_summary);
  const businessMetrics = mapBusinessMetricsSummaryResponse(
    business_metrics_summary
  );

  const monthWiseOccupancy = mapMonthWiseOccupancyResponse(
    month_wise_occupancy
  );
  
  const bookingSummary = mapOrderCountsSummaryResponse(order_counts);

  return {
    venue_rating: customerReviews,
    upcoming_events_summary: upcomingOrders,
    upcoming_recces_summary: upcomingRecces,
    business_metrics_summary: businessMetrics,
    occupancy_summary: monthWiseOccupancy,
    booking_summary: bookingSummary,
  };
};
