import {
  RecceService,
  VenueOrderService,
  VenueDashboardService,
} from "../../../data/VenueManagementService";

import RequestAction, {
  REQUEST_STATE_STAND_BY,
} from "../../../common/states/actions.request";
import {
  BookingApproved, 
  BookingRejected
} from "../../../common/utils/constants"
import {
  getStartOfMonth,
  getEndOfMonth,
  getCurrentDate,
  formatDate,
  getValidYearByMonthId,
} from "../../../common/utils";

export const SET_MONTHLY_RECCE_LIST = "SET_MONTHLY_RECCE_LIST";
export const RESET_MONTHLY_RECCE_LIST = "RESET_MONTHLY_RECCE_LIST";

export const SET_MONTHLY_BOOKING_LIST = "SET_MONTHLY_BOOKING_LIST";
export const RESET_MONTHLY_BOOKING_LIST = "RESET_MONTHLY_BOOKING_LIST";

export const SET_MONTHLY_METRICS_LIST = "SET_MONTHLY_METRICS_LIST";
export const RESET_MONTHLY_METRICS_LIST = "RESET_MONTHLY_METRICS_LIST";

export const FILTER_DATE_FORMAT = "yyyy-MM-DD";
export const STATE_DATE_FORMAT = "MM_YYYY";

export const REQUEST_KEY_MONTHLY_RECCE_LIST = "rk_monthly_recce_list";
export const REQUEST_KEY_MONTHLY_BOOKING_LIST = "rk_monthly_booking_list";
export const REQUEST_KEY_MONTHLY_METRICS_LIST = "rk_monthly_metrics_list";

const getDateRangeFromMonthId = (monthId) => {
  const year = getValidYearByMonthId(monthId);

  let startDate = getStartOfMonth({
    month: monthId - 1,
    year: year,
    format: FILTER_DATE_FORMAT,
  });

  let endDate =
    monthId + "" === getCurrentDate("M")
      ? getCurrentDate(FILTER_DATE_FORMAT)
      : getEndOfMonth({
          month: monthId - 1,
          year: year,
          format: FILTER_DATE_FORMAT,
        });

  return { startDate: startDate, endDate: endDate };
};

export const requestMonthlyBookingList = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_MONTHLY_BOOKING_LIST,
    })
  );

  const { venueId, monthId } = params;
  const { startDate, endDate } = getDateRangeFromMonthId(monthId);
  const stateFromattedDate = formatDate(startDate, STATE_DATE_FORMAT);

  try {
    const bookingList = await VenueOrderService.getBookingListByDateRange({
      venueId,
      startDate,
      endDate,
    });

    dispatch({
      type: SET_MONTHLY_BOOKING_LIST,
      date: stateFromattedDate,
      monthlyBookingList: bookingList,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_MONTHLY_BOOKING_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_MONTHLY_BOOKING_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while getting upcoming orders from OrderService", err);
  }
};

export const requestMonthlyRecceList = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_MONTHLY_RECCE_LIST,
    })
  );

  const { venueId, monthId } = params;
  const { startDate, endDate } = getDateRangeFromMonthId(monthId);

  const stateFromattedDate = formatDate(startDate, STATE_DATE_FORMAT);

  try {
    const upcomingRecceList = await RecceService.getRecceListByDateRange({
      venueId,
      startDate,
      endDate,
    });

    dispatch({
      type: SET_MONTHLY_RECCE_LIST,
      date: stateFromattedDate,
      monthlyRecceList: upcomingRecceList,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_MONTHLY_RECCE_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_MONTHLY_RECCE_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while getting upcoming visits from RecceService", err);
  }
};

export const requestBusinessMetrics = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_MONTHLY_METRICS_LIST,
    })
  );

  const { venueId, startDate, endDate } = params;

  try {
    const monthlyMetrics = await VenueDashboardService.getBusinessMetricsByDateRange(
      {
        venueId,
        startDate,
        endDate,
      }
    );

    dispatch({
      type: SET_MONTHLY_METRICS_LIST,
      metricsList: monthlyMetrics.metrics_list,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_MONTHLY_METRICS_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_MONTHLY_METRICS_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error(
      "Error while getting metrics from VenueDashboardService",
      err
    );
  }
};

export const sendVoMessage = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_MONTHLY_RECCE_LIST,
    })
  );

  const { venueId, receeId, voMessage, stateFromattedDate } = params;

  try {
    const upcomingRecceList = await RecceService.sendVoMessage({
      venueId,
      receeId,
      voMessage,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_MONTHLY_RECCE_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
    return upcomingRecceList.data;
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_MONTHLY_RECCE_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while getting upcoming vitits from RecceService", err);
  }
};

export const updateBookingStatus = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_MONTHLY_BOOKING_LIST,
    })
  );

  const { venueId, documentUrl, oldStatus, newStatus, rejection_reason, stateFromattedDate, bookingList, booking_id } = params;

  try {
    const bookingUpdateResponse = await VenueOrderService.updateBookingStatus({
      venueId,
      documentUrl,
      oldStatus,
      newStatus,
      rejection_reason,
    });
    console.log(bookingUpdateResponse.data);
    if([BookingApproved, BookingRejected].includes(bookingUpdateResponse.data)){
      bookingList["event_list"].forEach((booking)=>{
        if(booking['booking_id'] == booking_id){
          booking['vo_status'] = (newStatus == "Approve") ? "Approved" : "Rejected" ;
        } 
      });

      dispatch({
        type: SET_MONTHLY_BOOKING_LIST,
        date: stateFromattedDate,
        monthlyBookingList: bookingList,
      });

    }

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_MONTHLY_BOOKING_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
      return bookingUpdateResponse.data;
    
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_MONTHLY_BOOKING_LIST,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while updating booking status from OrderService", err);
  }
};
