import React from "react";
import { mapReccesToUiTemplate, initialRecceUiTemplate } from ".";

import HigherOrder from "../../components/hoc/index.js";
import NavigationBar from "../../routes/NavigationBar";
import { LoadingErrorEmpty } from "../../components/LoadingScreen";
import { CoCardTemplateSixList } from "../../components/templates/CoCardTemplateSix";
import { compose } from "redux";
import { connect } from "react-redux";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import {
  REQUEST_KEY_UPCOMING_RECCE_LIST,
  requestUpcomingRecceList,
  sendVoMessage
} from "../recces/states/actions";
import {
  REQUEST_STATE_LOADING
} from "../../common/states/actions.request";
import { subscribeToRecces } from "../recces/states/reducers";

const UpcomingRecces = (props) => {
  const { coTemplateSixData } = props;

  return (
    <>
      <NavigationBar title={"Upcoming Visits"} hasBackIcon={true} />
      <LoadingErrorEmpty
        {...props}
        isListEmpty={coTemplateSixData?.data?.length === 0}
        empty={{
          title: "No Upcoming Visits found",
          subtitle: "There are no Upcoming Visits right now",
        }}
        onRetryButtonClicked={() => {
          requestUpcomingRecces(props);
        }}
      >
        <div style={{ margin: "20px" }}>
          <CoCardTemplateSixList data={coTemplateSixData.data} />
        </div>
      </LoadingErrorEmpty>
    </>
  );
};

const onSendVoMessage = async(voMessage, receeDetails, props)=>{
  const {
    api: { request_state: requestState },
    user: {
      selected_venue: { venue_id: venueId },
    },
    sendVoMessage,
    
  } = props;

  const params = {
    venueId: venueId,
    receeId: receeDetails["recce_id"], 
    voMessage: voMessage, 
  }

  if (requestState !== REQUEST_STATE_LOADING){
    return await sendVoMessage(params).then((response)=>{
      console.log(response)
    });
  }

}

const requestUpcomingRecces = (props) => {
  const {
    user: {
      selected_venue: { venue_id: venueId },
    },
    requestUpcomingRecceList,
  } = props;

  if (venueId !== undefined) {
    requestUpcomingRecceList({ venueId: venueId });
  }
};

const mapUpcomingReccesToUiTemplate = (props) => {
  const {
    recces: {
      upcoming_recces: { recce_list: upcomingRecceList },
    },
  } = props;
  const onVoMessage = (voMessage, receeDetails) => onSendVoMessage(voMessage, receeDetails, props)
  return mapReccesToUiTemplate(upcomingRecceList, onVoMessage);
};

const withApiData = (WrappedComponent) =>
  HigherOrder.withSinglePageApiCall(WrappedComponent, {
    propInitialUiTemplate: initialRecceUiTemplate,
    propMakeSinglePageApiCall: requestUpcomingRecces,
    propMapToUiTemplate: mapUpcomingReccesToUiTemplate,
  });

const ComposedUpcomingRecces = compose(
  HigherOrder.withHistoryObject,
  HigherOrder.withSplashScreenNavigation,
  HigherOrder.withNotifcationHandler,
  withApiData
)(UpcomingRecces);

const mapStateToProps = (state) => ({
  recces: subscribeToRecces(state),
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_UPCOMING_RECCE_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestUpcomingRecceList: (params) =>dispatch(requestUpcomingRecceList({ ...params })),
  sendVoMessage: (params) => dispatch(sendVoMessage({ ...params })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedUpcomingRecces);
