const mapVenueToTemplateFiveRowItem = ({ elevated = false }, venue) => {
  return {
    id: venue["venue_id"],
    title: venue["venue_name"],
    elevated: elevated,
    subTitles: [venue["venue_location"]],
  };
};

export const mapVenueListToTemplateFiveRowList = (
  venueList = [],
  selectedVenue = {}
) => {
  return venueList.map((venue) => {
    return mapVenueToTemplateFiveRowItem(
      { elevated: venue["venue_id"] === selectedVenue["venue_id"] },
      venue
    );
  });
};
