import { SET_LOADING, RESET_LOADING, SET_ERROR, RESET_ERROR } from "./actions";
import {
  REQUEST_ACTION_SET_LOADING,
  REQUEST_ACTION_SET_ERROR,
  REQUEST_ACTION_SET_REQ_STATE,
  REQUEST_ACTION_SET_REQ_STATE_ALL,
} from "./actions.request";
import {
  SET_UPDATE_STATE,
  RESET_UPDATE_STATE,
  UPDATE_STATE_UNDEFINED,
} from "./actions.update";

import {
  SET_INSTALL_STATE,
  RESET_INSTALL_STATE,
  INSTALL_STATE_UNDEFINED,
} from "./actions.install";

const initialState = {
  loading: {
    isLoading: false,
    message: "",
  },
  error: {
    code: "",
    type: "",
    message: "",
    isHandled: true,
  },
  updateAvailable: UPDATE_STATE_UNDEFINED,
  installApp: INSTALL_STATE_UNDEFINED,
  request: {},
};

const shared = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case SET_LOADING:
    case RESET_LOADING:
      if (action.key || action.key !== undefined) {
        return Object.assign({}, state, {
          loading: {
            ...state.loading,
            [action.key]: {
              isLoading: action.loading.isLoading,
              message: action.loading.message,
            },
          },
        });
      }
      return Object.assign({}, state, {
        loading: {
          ...state.loading,
          isLoading: action.loading.isLoading,
          message: action.loading.message,
        },
      });

    case SET_ERROR:
    case RESET_ERROR:
      return Object.assign({}, state, {
        error: {
          code: action.error.code,
          type: action.error.type,
          message: action.error.message,
          isHandled: action.error.code === "",
        },
      });

    case REQUEST_ACTION_SET_LOADING:
      return Object.assign({}, state, {
        request: {
          ...state.request,
          [action.key]: {
            ...state.request[action.key],
            request_state: action.request_state,
            loading: {
              isLoading: action.loading.isLoading,
              message: action.loading.message,
            },
          },
        },
      });

    case REQUEST_ACTION_SET_ERROR:
      return Object.assign({}, state, {
        request: {
          ...state.request,
          [action.key]: {
            ...state.request[action.key],
            request_state: action.request_state,
            error: {
              code: action.error.code,
              type: action.error.type,
              message: action.error.message,
            },
          },
        },
      });

    case REQUEST_ACTION_SET_REQ_STATE:
      return Object.assign({}, state, {
        request: {
          ...state.request,
          [action.key]: {
            ...state.request[action.key],
            request_state: action.request_state,
          },
        },
      });

    case REQUEST_ACTION_SET_REQ_STATE_ALL:
      return updateRequestStateFor(
        state,
        action.request_state,
        action.update_for,
        action.update_for_except
      );

    case SET_UPDATE_STATE:
      return Object.assign({}, state, {
        updateAvailable: action.state,
      });

    case RESET_UPDATE_STATE:
      return Object.assign({}, state, {
        updateAvailable: initialState.state,
      });

    case SET_INSTALL_STATE:
      return Object.assign({}, state, {
        installApp: action.state,
      });

    case RESET_INSTALL_STATE:
      return Object.assign({}, state, {
        installApp: initialState.state,
      });

    default:
      return state;
  }
};

const updateRequestStateFor = (
  originalState,
  requestState,
  updateFor = [],
  updateForExcept = []
) => {
  const state = Object.assign({}, originalState);

  for (const key of Object.keys(state.request)) {
    //  If the key exists in except list then coninue
    if (updateForExcept.includes(key)) {
      continue;
    }

    //  if update for is not empty then only make change if the key exists in
    //  updateFor list.
    if (updateFor.length !== 0 && updateFor.includes(key)) {
      state.request[key].request_state = requestState;
      continue;
    }

    //  If updateFor is empty then change in all except request objects
    state.request[key].request_state = requestState;
  }
  return state;
};

const getLoadingState = (state, key) => {
  if (!key || key === undefined) {
    return state.shared.loading;
  }

  if (key in state.shared.loading && state.shared.loading[key] !== undefined) {
    return state.shared.loading[key];
  }

  return initialState.loading;
};

export const subscribeToLoading = (state, key) => getLoadingState(state, key);

export const subscribeToRequestState = (state, key) =>
  state.shared.request[key] ?? {};

export const subscribeToSharedState = (state) => state.shared;

export default shared;
