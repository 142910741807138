import {
  REQUEST_STATE_UNDEFINED,
  REQUEST_STATE_STAND_BY,
} from "../../common/states/actions.request";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getVenueIdFromPath } from "../../routes";

const withSplashScreenNavigation = (WrappedComponent) => (props) => {
  let location = useLocation();
  const { pathname: pathName, search } = location;

  const [hasTriggeredNavigation, setHasTriggeredNavigation] = useState(
    undefined
  );

  useEffect(() => {
    const {
      api: { request_state: requestState },
      user: { selected_venue: selectedVenue } = {},
      replacePath,
    } = props;

    const pathVenueId = getVenueIdFromPath(pathName);

    if (
      requestState === REQUEST_STATE_UNDEFINED ||
      requestState === REQUEST_STATE_STAND_BY
    ) {
      if (selectedVenue !== undefined && "venue_id" in selectedVenue) {
        const selectedVenueId = selectedVenue["venue_id"];

        if (
          hasTriggeredNavigation === undefined &&
          pathVenueId !== undefined &&
          pathVenueId !== selectedVenueId
        ) {
          console.log(
            "YOLO",
            "Requested venue is not selected. Switch to Splash to re initialise the app",
            pathName
          );

          //  We have a selected venue but it does not match with
          //  the requested venue as per the path.
          //  Trigger a redirect to Splash screen
          setHasTriggeredNavigation(true);
        } else {
          //  Everything looks fine. No need to redirect.
          setHasTriggeredNavigation(false);
        }
      } else {
        //  Either there is no selectedVenue or there is no venue_id in it.
        //  This means that we need to re initialise the state.
        //  Trigger a redirect to splash screen
        setHasTriggeredNavigation(true);
      }
    } else {
      //  The request is in LOADING or ERROR state. Either we are
      //  waiting for a request to resolve or now the user will
      //  trigger a retry. No Need to redirect.
      setHasTriggeredNavigation(false);
    }
  }, [setHasTriggeredNavigation, props, hasTriggeredNavigation, pathName]);

  // useEffect(() => {
  //   const {
  //     api: { request_state: requestState },
  //     user: { selected_venue: selectedVenue } = {},
  //   } = props;

  //   if (
  //     requestState === REQUEST_STATE_UNDEFINED ||
  //     requestState === REQUEST_STATE_STAND_BY
  //   ) {
  //     if (selectedVenue === undefined || !("venue_id" in selectedVenue)) {
  //       setHasTriggeredNavigation(true);
  //     }
  //   }
  // }, [props]);

  useEffect(() => {
    const { replacePath } = props;
    if (hasTriggeredNavigation) {
      replacePath("/", { target: { pathname: pathName, search: search } });
    }
  }, [hasTriggeredNavigation, props, pathName, search]);

  return hasTriggeredNavigation !== undefined &&
    hasTriggeredNavigation === false ? (
    <WrappedComponent {...props} />
  ) : null;
};

export default withSplashScreenNavigation;
