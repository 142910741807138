import VenueManagementUrls from "../sources/api/urls";
import * as VenueManagementClient from "../sources/api/clients/vendorManagement";
import { mapLoginResponse } from "../mappings/user.mappings";

const generateLoginOtp = async ({ countryCode, phoneNumber }) => {
  const url = `${VenueManagementUrls.generateLoginOtp}`;
  const body = {
    countryCode: countryCode,
    phoneNo: phoneNumber,
    target: "PHONE",
  };

  try {
    const recceList = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => response.data);

    console.log("%c Generate Login Otp: ", "color: yellow", recceList);
    return recceList;
  } catch (err) {
    console.error("%c Generate Login Otp: Error ", "color: yellow", err);
    throw err;
  }
};

const verifyOtp = async ({ countryCode, phoneNumber, otp, deviceToken }) => {
  const url = `${VenueManagementUrls.verifyLoginOtp}`;
  const body = {
    countryCode: countryCode,
    phoneNo: phoneNumber,
    otp: otp,
    target: "PHONE",
    deviceToken: deviceToken,
  };

  try {
    const recceList = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => mapLoginResponse(response));

    console.log("%c Verify Login Otp: ", "color: yellow", recceList);
    return recceList;
  } catch (err) {
    console.error("%c Verify Login Otp: Error ", "color: yellow", err);
    throw err;
  }
};

const logoutUser = async ({ countryCode, phoneNumber, deviceToken }) => {
  const url = `${VenueManagementUrls.logout}`;
  const body = {
    countryCode: countryCode,
    phoneNo: phoneNumber,
    target: "PHONE",
    deviceToken: deviceToken,
  };

  try {
    const recceList = await VenueManagementClient.postRequest(url, body).then(
      (response) => response.data
    );

    console.log("%clogout: ", "color: yellow", recceList);
    return recceList;
  } catch (err) {
    console.error("%logout : Error ", "color: yellow", err);
    throw err;
  }
};

const updateDeviceToken = async ({ countryCode, phoneNumber, deviceToken }) => {
  const url = `${VenueManagementUrls.updateDeviceToken}`;
  const body = {
    countryCode: countryCode,
    phoneNo: phoneNumber,
    target: "PHONE",
    deviceToken: deviceToken,
  };

  try {
    const updateDeviceTokenResponse = await VenueManagementClient.postRequest(
      url,
      body
    ).then((response) => response.data);

    console.log(
      "%c Update Device Token",
      "color: yellow",
      updateDeviceTokenResponse
    );
    return updateDeviceToken;
  } catch (err) {
    console.error("%c Update Device Token: Error ", "color: yellow", err);
    throw err;
  }
};

const setAuthTokenInAllRequests = ({ authToken }) => {
  VenueManagementClient.setAuthTokenHeader(authToken);
};

const resetAuthTokenInAllRequests = () => {
  VenueManagementClient.resetAuthTokenHeader();
};

export default {
  generateOtp: generateLoginOtp,
  verifyOtp: verifyOtp,
  logoutUser: logoutUser,
  updateDeviceToken: updateDeviceToken,
  setAuthTokenInAllRequests: setAuthTokenInAllRequests,
  resetAuthTokenInAllRequests: resetAuthTokenInAllRequests,
};
