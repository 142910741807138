import VenueManagementUrls from "../sources/api/urls";
import * as VenueManagementClient from "../sources/api/clients/vendorManagement";
import { mapVenueReviewApiResponse } from "../mappings/review.mappings";

const getVenueReviewList = async ({ venueId }) => {
  const url = `${VenueManagementUrls.review}/${venueId}/`;

  try {
    const venueReviews = await VenueManagementClient.getRequest(url)
      .then((response) => response.data)
      .then((response) => mapVenueReviewApiResponse(response));

    console.log("Customer Review Response : ", venueReviews);

    return venueReviews;
  } catch (err) {
    console.error("Customer Review Error :", err);
    throw err;
  }
};

export default {
  getVenueReviewList: getVenueReviewList,
};
