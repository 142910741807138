import { SET_VENUE_CALENDAR, RESET_VENUE_CALENDAR } from "./actions";

import { REQUEST_ACTION_RESET_ALL_STATES } from "../../../common/states/actions.request";

const initialState = {
  calendar_data: {},
};

const venueCalendar = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENUE_CALENDAR:
      return Object.assign({}, state, {
        calendar_data: Object.assign(
          {},
          state.calendar_data,
          action.venueCalendar
        ),
      });

    case RESET_VENUE_CALENDAR:
      return Object.assign({}, state, { ...initialState });

    case REQUEST_ACTION_RESET_ALL_STATES:
      return Object.assign({}, state, {
        ...initialState,
      });

    default:
      return state;
  }
};

export default venueCalendar;

export const subscribeToVenueCalendar = (state) => state.venueCalendar;
