import LoginService from "./services/UserLoginService";
import DashboardService from "./services/VenueDashboardService";
import TheRecceService from "./services/RecceService";
import TheVenueOrderService from "./services/VenueOrderService";
import TheReviewService from "./services/ReviewService";
import TheCalendarService from "./services/CalendarService";

export const RecceService = TheRecceService;
export const UserLoginService = LoginService;
export const CustomerReviewService = TheReviewService;
export const VenueOrderService = TheVenueOrderService;
export const VenueDashboardService = DashboardService;
export const CalendarService = TheCalendarService;
