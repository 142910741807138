import React from "react";
import {
  REQUEST_STATE_UNDEFINED,
  REQUEST_STATE_LOADING,
  REQUEST_STATE_STAND_BY,
} from "../../common/states/actions.request";

const withSinglePageApiCall = (
  WrappedComponent,
  { propInitialUiTemplate, propMapToUiTemplate, propMakeSinglePageApiCall }
) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ...propInitialUiTemplate,
      };

      this.mapApiResponseToUiTemplate = this.mapApiResponseToUiTemplate.bind(
        this
      );
    }

    componentDidMount() {
      const {
        api: { request_state: requestState },
      } = this.props;

      if (requestState === REQUEST_STATE_UNDEFINED) {
        propMakeSinglePageApiCall(this.props);
      }

      this.mapApiResponseToUiTemplate(this.props);
    }

    shouldComponentUpdate(nextProps) {
      const {
        api: { request_state: newRequestState },
      } = nextProps;

      const {
        api: { request_state: oldRequestState },
      } = this.props;

      if (
        oldRequestState === REQUEST_STATE_LOADING &&
        newRequestState === REQUEST_STATE_STAND_BY
      ) {
        // console.log(
        //   "REQ STATE TRANSITION : ",
        //   `${oldRequestState} -> ${newRequestState}`
        // );

        this.mapApiResponseToUiTemplate(nextProps);
        return true;
      }

      return true;
    }

    mapApiResponseToUiTemplate(newProps) {
      this.setState({ ...propMapToUiTemplate(newProps) });
    }

    render() {
      const {
        api: { request_state: requestState },
      } = this.props;

      return (
        <WrappedComponent
          isLoading={requestState === REQUEST_STATE_LOADING}
          {...this.state}
          {...this.props}
        />
      );
    }
  };
};

export default withSinglePageApiCall;
