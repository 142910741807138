import React from "react";
import { connect } from "react-redux";
import { subscribeToBusinessMetrics } from "./states/reducers";
import { subscribeToUser } from "../venueSelection/states/reducers";
import { subscribeToRequestState } from "../../common/states/reducers";
import {
  REQUEST_KEY_MONTHLY_RECCE_LIST,
  requestMonthlyRecceList,
  sendVoMessage
} from "./states/actions";
import {
  REQUEST_STATE_LOADING,
  REQUEST_STATE_ERROR,
  REQUEST_STATE_STAND_BY,
} from "../../common/states/actions.request";
import NavigationBar from "../../routes/NavigationBar";
import { LoadingByRequestState } from "../../components/LoadingScreen";
import { Error, EmptyCard, CoErrorScreen } from "../../components/EmptyScreen";
import CoSectionWithDropDown from "../../components/CoSectionWithDropDown";

import { CoCardTemplateSixList } from "../../components/templates/CoCardTemplateSix";

import {
  getDropDownOptionList,
  getStateFormattedDate,
  getMonthFormattedDate,
  getOption,
} from "./index";
import {
  mapRecceListToTemplateSix,
  mapRecceListMetaToTemplateTwo,
} from "../recces/mappings/recce.mappings";
import { compose } from "redux";
import withSplashScreenNavigation from "../../components/hoc/withSplashScreenNavigation";
import withHistoryObject from "../../components/hoc/withHistoryObject";
import CoTableTemplateTwo from "../../components/templates/CoCardTemplateTwo";

class BMRecceDetails extends React.Component {
  constructor(props) {
    super(props);

    const { monthId = -1 } = props;

    const {
      optionList,
      selectedOption,
      selectedStateFormattedDate,
    } = getDropDownOptionList(monthId);

    this.state = {
      templateSixData: {},
      templateTwoData: {},

      optionList: optionList,
      selectedOption: selectedOption,
      selectedStateFormattedDate: selectedStateFormattedDate,
    };

    this.onDropDownOptionChanged = this.onDropDownOptionChanged.bind(this);
    this.onVoMessage = this.onVoMessage.bind(this);
  }

  componentDidMount() {
    this.getMonthlyRecceList(this.props, this.state);
    this.mapRecceListDataToUiTemplate(this.props);
  }

  onDropDownOptionChanged(option) {
    const { setMonthId } = this.props;

    const setStateCallback = () => {
      this.getMonthlyRecceList(this.props, this.state);
      if (setMonthId != null) {
        setMonthId(option.id);
      }
    };

    this.setState(
      {
        selectedOption: option,
        selectedStateFormattedDate: getStateFormattedDate(option.id),
      },
      setStateCallback
    );
  }

  getMonthlyRecceList(props, state) {
    const { selectedOption, selectedStateFormattedDate } = state;

    const {
      api: { request_state: requestState },
      user: {
        selected_venue: { venue_id: venueId },
      },
      requestMonthlyRecceList,
      businessMetrics: {
        monthly_recces: {
          [selectedStateFormattedDate]: monthlyRecceList = null,
        } = {},
      } = {},
    } = props;

    if (requestState !== REQUEST_STATE_LOADING) {
      if (monthlyRecceList !== null) {
        console.log(
          "Data already exists. No need to call",
          selectedStateFormattedDate
        );
        this.mapRecceListDataToUiTemplate(props);
        return;
      }

      if (venueId !== undefined) {
        requestMonthlyRecceList({
          venueId: venueId,
          monthId: selectedOption.id,
        });
      }
    }
  }

  async onVoMessage(voMessage, receeDetails){
    const {
      api: { request_state: requestState },
      user: {
        selected_venue: { venue_id: venueId },
      },
      sendVoMessage,
      
    } = this.props;

    const params = {
      venueId: venueId,
      receeId: receeDetails["recce_id"], 
      voMessage: voMessage, 
    }

    if (requestState !== REQUEST_STATE_LOADING){
      return await sendVoMessage(params).then((response)=>{
      });
    }

  }

  mapRecceListDataToUiTemplate(newProps) {
    const { selectedStateFormattedDate } = this.state;

    const {
      businessMetrics: {
        monthly_recces: { [selectedStateFormattedDate]: monthlyRecceList } = {},
      } = {},
    } = newProps;
    this.setState({
      templateSixData: mapRecceListToTemplateSix(monthlyRecceList, this.onVoMessage),
      templateTwoData: mapRecceListMetaToTemplateTwo(monthlyRecceList),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      api: { request_state: newRequestState },
    } = nextProps;

    const {
      api: { request_state: oldRequestState },
    } = this.props;

    if (
      oldRequestState === REQUEST_STATE_LOADING &&
      newRequestState === REQUEST_STATE_STAND_BY
    ) {
      this.mapRecceListDataToUiTemplate(nextProps);
      return false;
    }

    return true;
  }

  render() {
    const {
      templateSixData,
      templateTwoData,

      optionList,
      selectedOption,
      selectedStateFormattedDate,
    } = this.state;

    const {
      api: {
        request_state: requestState,
        loading: { message: loadingMessage } = {},
        error: { message: errorMessage, code: errorCode } = {},
      },
      businessMetrics: {
        monthly_recces: {
          [selectedStateFormattedDate]: monthlyRecceList = null,
        },
      },
      showAsTab = false,
    } = this.props;

    return (
      <>
        {!showAsTab ? <NavigationBar title={"Business Metrics"} /> : null}
        <LoadingByRequestState
          message={loadingMessage}
          requestState={requestState}
        >
          <CoErrorScreen
            hasMargins={false}
            hasError={requestState === REQUEST_STATE_ERROR}
            isLoading={requestState === REQUEST_STATE_LOADING}
            message={errorMessage}
            statusCode={errorCode}
            onRetryButtonClicked={() => {
              this.getMonthlyRecceList(this.props, this.state);
            }}
          >
            <div style={{ margin: "20px" }}>
              <CoSectionWithDropDown
                title={"Visit Details"}
                optionList={optionList}
                selectedOption={selectedOption}
                onOptionChanged={this.onDropDownOptionChanged}
              >
                <EmptyCard
                  hasMargins={false}
                  isEmpty={monthlyRecceList?.length === 0}
                  title={"No Visits Found"}
                  subtitle={`There were no visitss for the month of ${getMonthFormattedDate(
                    selectedOption.id
                  )}`}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <CoTableTemplateTwo data={templateTwoData.data} />
                  </div>
                  <CoCardTemplateSixList data={templateSixData.data} />
                </EmptyCard>
              </CoSectionWithDropDown>
            </div>
          </CoErrorScreen>
        </LoadingByRequestState>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  businessMetrics: subscribeToBusinessMetrics(state),
  user: subscribeToUser(state),
  api: subscribeToRequestState(state, REQUEST_KEY_MONTHLY_RECCE_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  requestMonthlyRecceList: (params) =>
    dispatch(requestMonthlyRecceList({ ...params })),
    sendVoMessage: (params) => dispatch(sendVoMessage({ ...params })),
});

const ComposedBMRecceDetails = compose(
  withHistoryObject,
  withSplashScreenNavigation
)(BMRecceDetails);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposedBMRecceDetails);
