import { SET_UPCOMING_RECCE_LIST, RESET_UPCOMING_RECCE_LIST } from "./actions";
import { REQUEST_ACTION_RESET_ALL_STATES } from "../../../common/states/actions.request";

const initialState = {
  upcoming_recces: {
    recce_list: [],
    pagination: {},
  },
};

const recces = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPCOMING_RECCE_LIST:
      return Object.assign({}, state, {
        ...state.events,
        upcoming_recces: {
          recce_list: action.upcomingRecceList,
          pagination: action.pagination,
        },
      });

    case RESET_UPCOMING_RECCE_LIST:
      return Object.assign({}, state, {
        ...state.events,
        upcoming_recces: initialState.recces.upcoming_recces,
      });

    case REQUEST_ACTION_RESET_ALL_STATES:
      return Object.assign({}, state, {
        ...initialState,
      });
    default:
      return state;
  }
};

export default recces;

export const subscribeToRecces = (state) => state.recces;
