import {
  SET_MONTHLY_RECCE_LIST,
  RESET_MONTHLY_RECCE_LIST,
  SET_MONTHLY_BOOKING_LIST,
  RESET_MONTHLY_BOOKING_LIST,
  SET_MONTHLY_METRICS_LIST,
  RESET_MONTHLY_METRICS_LIST,
} from "./actions";
import { REQUEST_ACTION_RESET_ALL_STATES } from "../../../common/states/actions.request";

const initialState = {
  monthly_recces: {},
  monthly_bookings: {},
  monthly_metrics: {
    metrics_list: [],
  },
};

const businessMetrics = (state = initialState, action) => {
  switch (action.type) {
    case SET_MONTHLY_RECCE_LIST:
      return Object.assign({}, state, {
        monthly_recces: {
          ...state.monthly_recces,
          [action.date]: action.monthlyRecceList,
        },
      });

    case RESET_MONTHLY_RECCE_LIST:
      return Object.assign({}, state, {
        monthly_recces: initialState.monthly_recces,
      });

    case SET_MONTHLY_BOOKING_LIST:
      return Object.assign({}, state, {
        monthly_bookings: {
          ...state.monthly_bookings,
          [action.date]: action.monthlyBookingList,
        },
      });

    case RESET_MONTHLY_BOOKING_LIST:
      return Object.assign({}, state, {
        monthly_bookings: initialState.monthly_bookings,
      });

    case SET_MONTHLY_METRICS_LIST:
      return Object.assign({}, state, {
        monthly_metrics: {
          ...state.monthly_metrics,
          metrics_list: action.metricsList,
        },
      });

    case RESET_MONTHLY_METRICS_LIST:
      return Object.assign({}, state, {
        monthly_metrics: initialState.monthly_metrics,
      });

    case REQUEST_ACTION_RESET_ALL_STATES:
      return Object.assign({}, state, {
        ...initialState,
      });

    default:
      return state;
  }
};

export default businessMetrics;

export const subscribeToBusinessMetrics = (state) => state.businessMetrics;
