import { SET_USER_LOGIN, RESET_USER_LOGIN } from "./actions";

const initialState = {
  phone: {
    country_code: "+91",
    phone_number: "",
  },
  name: undefined,
  otp: undefined,
};

const userLogin = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOGIN:
      return Object.assign({}, state, {
        phone: {
          country_code: action.login.countryCode,
          phone_number: action.login.phoneNumber,
        },
        otp: action.login.otp,
        name: action.login.name,
      });

    case RESET_USER_LOGIN:
      return Object.assign({}, state, {
        ...initialState,
      });

    default:
      return state;
  }
};

export default userLogin;

export const subscribeToUserLogin = (state) => state.login;
