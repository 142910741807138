import React, { useEffect } from "react";
import { CoLogo } from "../../../components/Logo";
import {
  createStyles,
  makeStyles,
  Button,
  Typography,
  TextField,
  LinearProgress,
} from "@material-ui/core";

import styled from "styled-components";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonRoot: {
      fontSize: "14",
      fontFamily: "Brown",
      letterSpacing: "-0.28px",
      textTransform: "none",
    },

    buttonTextPrimary: {
      color: "#2298DA",
    },

    buttonContainedRoot: {
      fontSize: "16",
      fontFamily: "Brown-Bold",
      textTransform: "none",
      borderRadius: "6px",
      paddingTop: "13px",
      paddingBottom: "9px",
      paddingRight: "70px",
      paddingLeft: "70px",
    },
    buttonContainedPrimary: {
      backgroundImage: "linear-gradient(104deg, #D80666 2%, #D80666)",
      // backgroundColor: "#D80666",
      color: "#ffffff",
    },

    buttonContainedDisabled: {
      opacity: 0.5,
    },

    h6: {
      fontSize: "18",
      fontFamily: "Brown-Bold",
      textAlign: "center",
      opacity: 0.87,
      color: "#0000000",
    },

    text1: {
      fontSize: "14px",
      fontFamily: "Brown",
      opacity: 0.87,
      color: "#0000000",
    },

    text2: {
      fontSize: "14px",
      fontFamily: "Brown",
      opacity: 0.54,
      color: "#0000000",
    },
  })
);

const styles = {
  header: {
    zIndex: -1,
    width: "100%",
    height: "280px",
    // backgroundImage: "linear-gradient(104deg, #d11450 2%, #ef4123)",
    backgroundColor: "#212121",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  footerButtonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  footerDivider: {
    marginTop: "8px",
    width: "1px",
    height: "20px",
    backgroundColor: "#d8dce2",
  },

  headerTitleContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "30px",
    marginBottom: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const ElevatedContainerHeaderTitle = (props) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <div style={styles.headerTitleContainer}>
      <Typography classes={{ h6: classes.h6 }} variant="h6">
        {value}
      </Typography>
    </div>
  );
};

export const ElevatedContainerText1 = (props) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <div>
      <Typography classes={{ h6: classes.text1 }} variant="h6">
        {value}
      </Typography>
    </div>
  );
};

export const ElevatedContainerText2 = (props) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <div>
      <Typography classes={{ h6: classes.text2 }} variant="h6">
        {value}
      </Typography>
    </div>
  );
};

export const ElevatedContainerPrimaryButton = (props) => {
  return (
    <div style={styles.headerTitleContainer}>
      <LoginPrimaryButton {...props} />
    </div>
  );
};

export const LoginPrimaryButton = (props) => {
  const classes = useStyles();
  const { value, onPrimaryButtonClicked, ...otherProps } = props;

  return (
    <Button
      classes={{
        root: classes.buttonContainedRoot,
        disabled: classes.buttonContainedDisabled,
        containedPrimary: classes.buttonContainedPrimary,
      }}
      variant="contained"
      color="primary"
      onClick={onPrimaryButtonClicked}
      {...otherProps}
    >
      {value}
    </Button>
  );
};

export const ElevatedLoginContainer = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "0 4px 10px -5px rgba(0, 0, 0, 0.2)",
        border: "solid 1px rgba(0, 0, 0, 0.06)",
      }}
    >
      {children}
    </div>
  );
};

const Footer = (props) => {
  const { button1, button2 } = props;

  return (
    <div
      style={{
        display: "flex",
        marginTop: "17px",
      }}
    >
      {button1 !== undefined ? (
        <div style={styles.footerButtonContainer}>{button1}</div>
      ) : null}
      {button1 !== undefined && button2 !== undefined ? (
        <div style={styles.footerDivider}></div>
      ) : null}
      <div style={styles.footerButtonContainer}>{button2}</div>
    </div>
  );
};

export const FooterButton = (props) => {
  const classes = useStyles();

  const { value, ...otherProps } = props;

  return (
    <Button
      classes={{
        root: classes.buttonRoot,
        textPrimary: classes.buttonTextPrimary,
      }}
      color="primary"
      {...otherProps}
    >
      {value}
    </Button>
  );
};

export const LoginFooter = (props) => {
  const { onButtonOneClicked, onButtonTwoClicked, ...otherProps } = props;

  return (
    <Footer
      button2={
        <FooterButton
          onClick={onButtonTwoClicked}
          value="Click here for support"
        />
      }
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiFormLabel-root {
    color: #000000;
    opacity: 0.3;
    font-family: "Brown-Bold";
    font-size: "14em";

    &.Mui-focused {
      opacity: 0.54;
      font-size: "12em";
    }
  }

  .MuiFormHelperText-root {
    font-family: "Brown";
  }

  .MuiFilledInput-root {
    background-color: #f3f5f7;
    border-radius: 4px;

    &.Mui-focused {
      border: solid 0.5px rgba(0, 0, 0, 0.3);
    }
  }

  .MuiInputBase-root {
    font-family: "Brown";
    color: #000000;
    font-size: "14em";
    opacity: 0.87;
  }
`;

export const PhoneNumberField = (props) => {
  return (
    <StyledTextField
      {...props}
      style={{ flex: 1 }}
      label="Mobile Number"
      variant="filled"
      InputProps={{
        disableUnderline: true,
        fullWidth: true,
      }}
    />
    // <div>
    //   <Button>Default</Button>
    //   <StyledButton>Customized</StyledButton>
    // </div>
  );
};

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  colorPrimary: {
    backgroundColor: "#b30000",
  },
  bar: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    backgroundColor: "#ff694f",
  },
}))(LinearProgress);

export const LoginBackground = (props) => {
  const { children } = props;

  useEffect(() => {
    document.body.style.backgroundColor = "#ffffff";
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <div style={styles.header}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "280px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              width: "90px",
              height: "90px",
              borderRadius: "0 0 0px 90px",
              backgroundColor: "#303030",
            }}
          />
          <div
            style={{
              zIndex: -2,
              bottom: "0px",
              left: "-61px",
              position: "absolute",
              width: "140px",
              height: "100px",
              borderTopLeftRadius: "140px",
              borderTopRightRadius: "140px",

              backgroundColor: "#303030",
            }}
          />
        </div>
        <CoLogo />
      </div>
      {children}
    </div>
  );
};
