export const REQUEST_STATE_ERROR = "ERROR";
export const REQUEST_STATE_LOADING = "LOADING";
export const REQUEST_STATE_STAND_BY = "STAND_BY";
export const REQUEST_STATE_UNDEFINED = undefined;

export const REQUEST_ACTION_SET_ERROR = "REQ_SET_ERROR";
export const REQUEST_ACTION_SET_LOADING = "REQ_SET_LOADING";
export const REQUEST_ACTION_SET_STAND_BY = "REQ_SET_STAND_BY";
export const REQUEST_ACTION_SET_REQ_STATE = "REQ_UPDATE_STATE";
export const REQUEST_ACTION_SET_REQ_STATE_ALL = "REQ_UPDATE_STATE_FOR_ALL";
export const REQUEST_ACTION_RESET_ALL_STATES = "REQ_UPDATE_RESET_ALL_STATES";

const setLoading = ({ message = "Please wait", key }) => {
  return {
    type: REQUEST_ACTION_SET_LOADING,
    key,
    request_state: REQUEST_STATE_LOADING,
    loading: {
      isLoading: true,
      message: message,
    },
  };
};

const resetLoading = ({ key }) => {
  return {
    type: REQUEST_ACTION_SET_LOADING,
    key,
    request_state: REQUEST_STATE_LOADING,
    loading: {
      isLoading: false,
      message: "",
    },
  };
};

const setError = ({ key, error }) => ({
  type: REQUEST_ACTION_SET_ERROR,
  key,
  request_state: REQUEST_STATE_ERROR,
  error: {
    code: error.code ? error.code : error.errorCode,
    message: error.message,
    type: error.type ? error.type : error.errorType,
  },
});

const resetError = (key) => ({
  type: REQUEST_ACTION_SET_ERROR,
  key,
  request_state: REQUEST_STATE_ERROR,
  error: {
    code: "",
    type: "",
    message: "",
  },
});

const setRequestState = ({ key, request_state }) => ({
  type: REQUEST_ACTION_SET_REQ_STATE,
  key,
  request_state,
});

export const setRequestStateFor = ({
  request_state,
  update_for = [],
  update_for_except = [],
}) => ({
  type: REQUEST_ACTION_SET_REQ_STATE_ALL,
  request_state,
  update_for,
  update_for_except,
});

const requestHasCompletedSuccessfully = (oldProps, newProps) => {
  const {
    api: { request_state: newRequestState },
  } = newProps;

  const {
    api: { request_state: oldRequestState },
  } = oldProps;

  return (
    oldRequestState === REQUEST_STATE_LOADING &&
    newRequestState === REQUEST_STATE_STAND_BY
  );
};

export default {
  setLoading: setLoading,
  resetLoading: resetLoading,
  setError: setError,
  resetError: resetError,
  setRequestState: setRequestState,
  STATE: {
    ERROR: REQUEST_STATE_ERROR,
    LOADING: REQUEST_STATE_LOADING,
    STAND_BY: REQUEST_STATE_STAND_BY,
  },
  hasCompletedSuccessfully: requestHasCompletedSuccessfully,
};
