const mapVenueResponse = (response) => {
  const {
    venueTitle: venue_name,
    venueCatalogId: venue_id,
    city,
    locality,
    chContact: all_cluster_heads,
    cthContact: all_city_heads,
    vmContact: all_venue_managers,
  } = response;

  return {
    venue_id,
    venue_name,
    city,
    locality,
    venue_location: `${locality}, ${city}`,
    all_venue_managers: all_venue_managers ?? [],
    all_cluster_heads: all_cluster_heads ?? [],
    all_city_heads: all_city_heads ?? [],
  };
};

const mapVenuesByVenueId = (venueIdList = []) => {
  let index = 0;

  const venueList = [];
  venueIdList.forEach((venueId) => {
    venueList.push({
      venue_id: venueId,
      venue_name: `Venue ${index + 1}`,
      city: "City",
      locality: "locality",
      venue_location: `${"locality"}, ${"city"}`,
      all_venue_managers: [],
      all_cluster_heads: [],
      all_city_heads: [],
    });

    index += 1;
  });

  return venueList;
};

export const mapVenueListFromLoginResponse = (response) => {
  const { venueDetails, venueIds } = response;
  const venueList = [];

  if (venueDetails === undefined || venueDetails === null) {
    return mapVenuesByVenueId(venueIds);
  }

  Object.keys(venueDetails).forEach((key) => {
    const venueResponse = venueDetails[key];
    venueList.push(mapVenueResponse(venueResponse));
  });

  return venueList;
};
