import React from "react";

import { Tab, Tabs, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#ffffff",
      // paddingBottom: "5px",
      // paddingLeft: "13px",
      // paddingRight: "13px",
      // boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.06)",
    },
    tabRoot: {
      // minHeight: "36px",
    },
    wrapper: {
      // height: "36px",
      // backgroundImage: "linear-gradient(108deg, #d11450 1%, #ef4123)",
      // borderRadius: "100px",
    },

    textColorPrimary: {
      color: "#000000",
      fontFamily: "Brown-Bold",
      textTransform: "capitalize",
      fontSize: "14px",
      "&$selected": {
        color: "white",
      },
    },
    selected: {
      // backgroundImage: "linear-gradient(108deg, #d11450 1%, #ef4123)",
      // color: "#D80666",
      // borderRadius: "100px",
      "&$textColorPrimary": {
        color: "#D80666",
      },
    },
    indicator: {
      backgroundColor: "#D80666",
    },
  })
);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export function LinkTab(props) {
  const { ...otherProps } = props;

  const classes = useStyles();

  return (
    <Tab
      style={
        {
          // fontSize: "16px",
          // fontFamily: "Brown-Bold",
          // textTransform: "capitalize",
        }
      }
      classes={{
        selected: classes.selected,
        textColorPrimary: classes.textColorPrimary,
        wrapper: classes.wrapper,
        root: classes.tabRoot,
      }}
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...otherProps}
    />
  );
}

const TheTabs = (props) => {
  const { linkTabs, tabPanels, handleChange, selectedValue } = props;
  const classes = useStyles();

  return (
    <>
      <Tabs
        value={selectedValue}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        classes={{ root: classes.root, indicator: classes.indicator }}
      >
        {linkTabs}
      </Tabs>
      {tabPanels}
    </>
  );
};

const CoTabs = (props) => {
  const { tabCount, initialTabIndex, getLinkTab, getTabPanel } = props;

  const [value, setValue] = React.useState(initialTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const linkTabs = Array.from(Array(tabCount).keys()).map((index) => {
    return getLinkTab(index);
  });

  const tabPanels = Array.from(Array(tabCount).keys()).map((index) => {
    return getTabPanel(index, value);
  });

  return (
    <TheTabs
      selectedValue={value}
      initialTabIndex={initialTabIndex}
      linkTabs={linkTabs}
      tabPanels={tabPanels}
      handleChange={handleChange}
    />
  );
};

export default CoTabs;
