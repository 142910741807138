import React from "react";

import { TextField } from "@material-ui/core";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  .MuiFormLabel-root {
    color: #000000;
    opacity: 0.3;
    font-family: "Brown-Bold";
    font-size: "14em";

    &.Mui-focused {
      opacity: 0.54;
      font-size: "12em";
    }
  }

  .MuiFormHelperText-root {
    font-family: "Brown";
  }

  .MuiFilledInput-root {
    background-color: #f3f5f7;
    border-radius: 4px;
    border: solid 0.5px rgba(0, 0, 0, 0.3);

    // &.Mui-focused {
    //   border: solid 0.5px rgba(0, 0, 0, 0.3);
    // }
  }

  .MuiFilledInput-input {
    padding: 15px;
  }

  .MuiInputBase-root {
    font-family: "Brown";
    color: #000000;
    font-size: "14em";
    opacity: 0.87;
  }
`;

const initializeOtpMap = (props) => {
  const { initialOtp: otp = undefined, digits } = props;

  const fillMap = (arr) => {
    const map = {};
    let index = 0;
    arr.forEach((ch) => {
      map[index] = {
        value: ch,
      };
      index += 1;
    });

    return map;
  };

  if (otp !== undefined && otp !== "") {
    return fillMap(otp.split(""));
  } else {
    return fillMap(Array(digits).fill(""));
  }
};

const initializeRefs = (props) => {
  const { digits } = props;

  let index = 0;
  const map = {};
  Array(digits)
    .fill("")
    .forEach((digit) => {
      map[index] = {
        ref: React.createRef(),
      };
      index += 1;
    });

  return map;
};

class OtpField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otpValue: initializeOtpMap(props),
    };
    this.refMap = initializeRefs(props);
  }

  componentDidMount() {
    this.onUpdateOtp(this.props, this.state);
  }

  shouldComponentUpdate(nextProps) {
    const { initialize: initializeOld } = this.props;
    const { initialize: initializeNew } = nextProps;

    if (initializeOld === false && initializeNew === true) {
      this.setState({ otpValue: initializeOtpMap(nextProps) });
      return false;
    }

    return true;
  }

  onDigitChanged(index, digit) {
    if (digit !== null || digit !== "") {
      if (digit.length > 1) {
        digit = digit % 10;
      }

      this.setState(
        (prevState) => ({
          otpValue: {
            ...prevState.otpValue,
            [index]: {
              ...prevState.otpValue[index],
              value: digit,
            },
          },
        }),
        () => {
          this.updateFieldFocus(index, digit);
          this.onUpdateOtp(this.props, this.state);
        }
      );
    }
  }

  updateFieldFocus(index, digit) {
    index = parseInt(index);

    if (digit.length === 1 && this.refMap[index + 1] !== undefined) {
      this.refMap[index + 1].ref.focus();
    }

    if (digit.length === 0 && this.refMap[index - 1] !== undefined) {
      this.refMap[index - 1].ref.focus();
    }
  }

  onUpdateOtp(props, state) {
    const { otpValue } = state;
    const { updateOtpCallback } = props;

    const otp = Object.keys(otpValue)
      .map((key) => {
        return otpValue[key].value;
      })
      .reduce((d1, d2) => d1 + d2);

    updateOtpCallback(otp);
  }

  render() {
    const { digits } = this.props;
    const { otpValue } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {Object.keys(otpValue).map((key) => {
          return (
            <StyledTextField
              key={key}
              type="number"
              value={otpValue[key].value}
              onChange={(event) => {
                this.onDigitChanged(key, event.target.value);
              }}
              style={{
                flex: 1,
                marginRight: key < digits - 1 ? "10px" : "0px",
                textAlign: "center",
              }}
              variant="filled"
              InputProps={{
                disableUnderline: true,
                maxLength: 1,
              }}
              inputRef={(input) => {
                this.refMap[key].ref = input;
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default OtpField;
