import { CalendarService, VenueOrderService, RecceService } from "../../../data/VenueManagementService";
import RequestAction, { REQUEST_STATE_STAND_BY } from "../../../common/states/actions.request";
import * as moment from "moment";
import {BookingApproved, BookingRejected} from "../../../common/utils/constants"

export const SET_VENUE_CALENDAR = "SET_VENUE_CALENDAR";
export const RESET_VENUE_CALENDAR = "RESET_VENUE_CALENDAR";
export const REQUEST_KEY_VENUE_CALENDAR_DATA = "rk_venue_calendar_data";

export const requestVenueCalendarData = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait while we fetch calendar entries",
      key: REQUEST_KEY_VENUE_CALENDAR_DATA,
    })
  );

  const { venueId, startDate, endDate } = params;

  try {
    const venueCalendar = await CalendarService.getVenueCalendar({
      venueId: venueId,
      startDate: startDate,
      endDate: endDate,
    });

    dispatch({
      type: SET_VENUE_CALENDAR,
      venueCalendar: venueCalendar,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_VENUE_CALENDAR_DATA,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_VENUE_CALENDAR_DATA,
        error: JSON.parse(err.message),
      })
    );
    console.error(
      "Error while fetching calendar entries from CalendarService",
      err
    );
  }
};

export const updateBookingStatus = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait while we update booking status",
      key: REQUEST_KEY_VENUE_CALENDAR_DATA,
    })
  );

  const { venueId, documentUrl, oldStatus, newStatus, rejection_reason, selectedDate, calendarData, booking_id } = params;

  try {
    // console.log({ venueId, documentUrl, oldStatus, newStatus, rejection_reason, selectedDate, calendarData, booking_id })
    const bookingUpdateResponse = await VenueOrderService.updateBookingStatus({
      venueId,
      documentUrl,
      oldStatus,
      newStatus,
      rejection_reason,
    });

    if([BookingApproved, BookingRejected].includes(bookingUpdateResponse.data)){
      const monthDate = moment(selectedDate, "DD-MM-yyyy").format("MMMM_yyyy");
      const monthMap = calendarData[monthDate]
      if(monthMap !== null){
        const { dayWiseSplit } = monthMap
        const calendarDateObject = dayWiseSplit[selectedDate]
        if(calendarDateObject !== null){
          calendarDateObject["order_list"].forEach((booking)=>{
            if(booking['booking_id'] == booking_id){
              booking['vo_status'] = (newStatus == "Approve") ? "Approved" : "Rejected" ;
            } 
          });
          dispatch({
            type: SET_VENUE_CALENDAR,
            venueCalendar: calendarData,
          });
        }
          
      }
      
    }

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_VENUE_CALENDAR_DATA,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
   return bookingUpdateResponse.data;
    
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_VENUE_CALENDAR_DATA,
        error: JSON.parse(err.message),
      })
    );
    console.error("Error while updating booking status from OrderService in venue calendar module ", err);
  }
};

export const sendVoMessage = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please wait while we fetch calendar entries",
      key: REQUEST_KEY_VENUE_CALENDAR_DATA,
    })
  );

  const { venueId, receeId, voMessage } = params;

  try {
    // console.log({ venueId, receeId, voMessage })
    const upcomingRecceList = await RecceService.sendVoMessage({
      venueId,
      receeId,
      voMessage,
    });
    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_VENUE_CALENDAR_DATA,
        request_state: RequestAction.STATE.STAND_BY,
      })
    );
    return upcomingRecceList.data;
  } catch (err) {
    dispatch(
      RequestAction.setError({
        key: REQUEST_KEY_VENUE_CALENDAR_DATA,
        error: JSON.parse(err.message),
      })
    );
    console.error(
      "Error while sending note to VM from CalendarService",
      err
    );
  }
};