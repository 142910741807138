import {
  SET_VENUE_DASHBOARD_DATA,
  RESET_VENUE_DASHBOARD_DATA,
} from "./actions";
import { REQUEST_ACTION_RESET_ALL_STATES } from "../../../common/states/actions.request";

const initialState = {
  dashboard_data: {},
};

const venueDashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENUE_DASHBOARD_DATA:
      return Object.assign({}, state, {
        dashboard_data: action.venueDashboardData,
      });

    case RESET_VENUE_DASHBOARD_DATA:
      return Object.assign({}, state, {
        ...initialState,
      });

    case REQUEST_ACTION_RESET_ALL_STATES:
      return Object.assign({}, state, {
        ...initialState,
      });

    default:
      return state;
  }
};

export default venueDashboard;

export const subscribeToVenueDashboard = (state) => state.venueDashboard;
