import React, {useState, useEffect} from "react";

import {
  Typography,
  createStyles,
  makeStyles,
  Button,
  Backdrop,
  TextField, Radio, FormControl, FormLabel, RadioGroup, FormControlLabel
} from "@material-ui/core";
import { CoElevatedPanel, CoNonElevatedPanel } from "../CoPanel";
import ShowWithPopup from "../ShowAsPopup";
import SimpleDialogListPopup from "../SimpleDialogListPopup";
import ApproveRejectPopup from "../../modules/businessMetrics/bookingComponents/approveRejectPopup";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';


const useStyles = makeStyles((theme) =>
  createStyles({

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    headerTitle: {
      opacity: 0.87,
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      textAlign: "start",
      color: "#000000",
    },

    headerTitle4: {
      opacity: 0.54,
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      textAlign: "start",
      color: "#000000",
    },

    headerTitle3: {
      opacity: 0.54,
      fontFamily: "Brown-Bold",
      fontSize: "12px",
      textAlign: "end",
      lineHeight: 1.43,
      color: "#000000",
    },

    headerTitle2: {
      opacity: 0.7,
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      textAlign: "end",
      color: "#000000",
    },

    labelValue: {
      opacity: 0.54,
      fontFamily: "Brown",
      fontSize: "14px",
      textAlign: "center",
      color: "#000000",
    },

    bodyValueOne: {
      opacity: 0.87,
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      textAlign: "center",
      color: "#000000",
      lineHeight: 1.25,
      marginTop: "5px",
    },

    bodyValueTwo: {
      opacity: 0.7,
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      textAlign: "center",
      color: "#000000",
      lineHeight: 1.43,
    },
    bodyValueThree: {
      opacity: 0.7,
      fontFamily: "Brown",
      fontSize: "14px",
      textAlign: "center",
      color: "#000000",
      lineHeight: 1.43,
    },

    footerValueOne: {
      opacity: 0.7,
      fontFamily: "Brown-Bold",
      fontSize: "12px",
      textAlign: "start",
      lineHeight: 1.67,
      color: "#000000",
    },

    footerValueTwo: {
      opacity: 0.54,
      fontFamily: "Brown",
      fontSize: "14px",
      textAlign: "end",
      color: "#000000",
    },

    bodyTitle1: {
      opacity: 0.87,
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      textAlign: "start",
      color: "#000000",
      lineHeight: 1.43,
    },

    bodyText1: {
      opacity: 0.54,
      fontFamily: "Brown",
      fontSize: "14px",
      textAlign: "start",
      color: "#000000",
      lineHeight: 1.43,
    },
    bodyText2: {
      opacity: 0.7,
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      textAlign: "center",
      color: "#000000",
      lineHeight: 1.43,
    },
    bodyLabel1: {
      opacity: 0.54,
      fontFamily: "Brown",
      fontSize: "12px",
      textAlign: "center",
      color: "#000000",
    },

    bodyPendingLable: {
      
      fontFamily: "Brown",
      fontSize: "12px",
      textAlign: "center",
      color: "#DF9641",
    },

    bodySuccessLable: {
      
      fontFamily: "Brown",
      fontSize: "12px",
      textAlign: "center",
      color: "#00B28A",
    },

    bodyRejectLable: {
      
      fontFamily: "Brown",
      fontSize: "12px",
      textAlign: "center",
      color: "#EE2A24",
    },

    buttonContainedRoot: {
      fontSize: "14",
      fontFamily: "Brown-Bold",
      textTransform: "none",
      borderRadius: "100px",
      paddingTop: "10px",
      paddingBottom: "6px",
      paddingRight: "25px",
      paddingLeft: "25px",
      border: "solid 0.5px #c8cacf",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fcfdfe",
      color: "#2298DA",
    },
    buttonContainedPrimary: {
      backgroundColor: "#fcfdfe",
      color: "#2298DA",
    },

    buttonContainedDisabled: {
      opacity: 0.5,
    },

    submitContainedRoot: {
      fontSize: "14",
      fontFamily: "Brown-Bold",
      textTransform: "none",
      borderRadius: "6px",
      paddingTop: "10px",
      paddingBottom: "6px",
      paddingRight: "25px",
      paddingLeft: "25px",
      border: "solid 1px #c8cacf",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
      marginLeft: "10px",
    },
    submitContainedPrimary: {
      backgroundColor: "#D80666",
      color: "#ffffff",
    },
  
    submitContainedDisabled: {
      opacity: 0.5,
    },

    buttonContainedVoCancle: {
      fontSize: "14",
      fontFamily: "Brown-Bold",
      textTransform: "none",
      
    },

    buttonContainedPopUp: {
      fontSize: "14",
      fontFamily: "Brown-Bold",
      textTransform: "none",
      borderRadius: "6px"
      
    },

    sectionContainTitle: {
      fontSize: "14",
      fontFamily: "Brown-Bold",
      textTransform: "none",
      paddingBottom:"0px",
      fontWeight: 'bold' 
      
    },

    vmMessageRadioLabel: {
      lineHeight: "1.2",
    },
  })
);

const styles = {
  header: {
    backgroundColor: "#f3f5f7",

    borderRadius: "5px",
    paddingTop: "16px",
    paddingBottom: "16px",
    marginLeft: "15px",
    marginRight: "15px",
  },
  divider: {
    height: "1px",
    opacity: 0.06,
    backgroundColor: "#000000",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#000000",
    opacity: 0.06,
  },
  label: {
    backgroundColor: "#f1f3f6",
    paddingTop: "1px",
    paddingBottom: "1px",
    paddingLeft: "4px",
    paddingRight: "4px",
    borderRadius: "3px",
  },
  circleSeparator: {
    marginLeft: "8px",
    marginRight: "8px",
    width: "4px",
    height: "4px",
    borderRadius: "4px",
    backgroundColor: "#c9c9c9",
  },
  singleLineText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export const Divider = () => <div style={styles.divider} />;

export const Header = (props) => {
  const { title, title2, title3, title4 } = props;

  const classes = useStyles();
  return title ? (
    <div
      style={{
        display: "inline-block",
        marginTop: "17px",
        marginLeft: "15px",
        marginRight: "15px",
        marginBottom: "12px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Typography variant="body1" className={classes.headerTitle}>
            {title ?? "NA"}
          </Typography>
          {title4 != null ? (
            <Typography variant="body1" className={classes.headerTitle4}>
              {title4 ?? "NA"}
            </Typography>
          ) : null}
        </div>
        <div style={{ flex: 1 }}>
          {title2 != null ? (
            <Typography variant="body1" className={classes.headerTitle2}>
              {title2 ?? "NA"}
            </Typography>
          ) : null}
          {title3 != null ? (
            <Typography variant="body1" className={classes.headerTitle3}>
              {title3 ?? "NA"}
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

const Footer = (props) => {
  const { classes, value1, value2 } = props;

  return value1 != null || value2 != null ? (
    <div style={{ display: "flex" }}>
      {value1 != null ? (
        <div
          style={Object.assign(
            {},
            {
              flex: 3,
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "8px",
              marginBottom: "9px",
            },
            styles.singleLineText
          )}
        >
          <Typography
            style={styles.singleLineText}
            variant="body1"
            className={classes.footerValueOne}
          >
            {value1}
          </Typography>
        </div>
      ) : null}
      {value2 != null ? (
        <div
          style={{ width: "1px", backgroundColor: "#000000", opacity: 0.06 }}
        />
      ) : null}
      {value2 != null ? (
        <div
          style={Object.assign(
            {
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "8px",
              marginBottom: "9px",
              display: "flex",
              alignItems: "center",
              flex: 2,
              justifyContent: "flex-start",
            },
            styles.singleLineText
          )}
        >
          <ShowWithPopup message={value2}>
            <Typography
              variant="body1"
              style={styles.singleLineText}
              className={classes.footerValueTwo}
            >
              {value2?.length > 8 ? value2?.slice(0, 7) + "..." : value2}
            </Typography>
          </ShowWithPopup>
        </div>
      ) : null}
    </div>
  ) : null;
};

const CircleSeparator = () => <div style={styles.circleSeparator}></div>;

const getRenderComponent = (id, item, classes) => {
  const { type } = item;

  switch (type) {
    case "bodyTitle1":
      return <BodyTitle1 key={id} classes={classes} {...item} />;

    case "bodyText1":
      return <BodyText1 key={id} classes={classes} {...item} />;

    case "bodyText2":
      return <BodyText2 key={id} classes={classes} {...item} />;

    case "bodyLabel1":
      return <BodyLabel1 key={id} classes={classes} {...item} />;

    case "bodyRow1":
      return <BodyRow1 key={id} classes={classes} {...item} />;

    case "bodyLink1":
      return <BodyLink1 key={id} classes={classes} {...item} />;

    case "bodyButton1":
      return <BodyButton1 key={id} classes={classes} {...item} />;
    
    case "popupButton":
      return <PopupButton key={id} classes={classes} {...item} />;

    case "approveRejectPopupButton":
      return <ApproveRejectPopupButton key={id} classes={classes} {...item} />;

    case "bodyPendingLable":
      return <BodyPendingLable key={id} classes={classes} {...item} />;

    case "bodySuccessLable":
      return <BodySuccessLable key={id} classes={classes} {...item} />;

    case "bodyRejectLable":
      return <BodyRejectLable key={id} classes={classes} {...item} />;
    
    case "voMessageToVmButton":
      return <VoMessageToVmButton key={id} classes={classes} {...item} />;

    default:
      return null;
  }
};

const getBottomRowMargin = (kind) => {
  switch (kind) {
    case "title":
    case "mixed":
      return "4px";
    case "text":
      return "2px";

    case "label":
      return "8px";

    default:
      return "0px";
  }
};

const getOverflowKind = (kind) => {
  switch (kind) {
    case "title":
    case "text":
      return "hidden";

    case "mixed":
    case "label":
      return "scroll";

    default:
      return "hidden";
  }
};

const Body = (props) => {
  const { classes, items = [] } = props;

  let index = 0;
  const lastIndex = items.length;
  return items.length !== 0 ? (
    <div style={{ margin: "20px" }}>
      {items.map((item) => {
        if(item== null || item==undefined){
          return ;
        }
        const { id = index } = item;
        index += 1;

        return getRenderComponent(id, item, classes);
      })}
    </div>
  ) : null;
};

export const BodyTitle1 = (props) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <Typography
      style={Object.assign({}, styles.singleLineText)}
      variant="body1"
      className={classes.bodyTitle1}
    >
      {value}
    </Typography>
  );
};

const BodyButton1 = (props) => {
  const { classes, value, item, onButtonClicked, disabled = false } = props;

  return (
    <div style={{ marginTop: "10px", marginBottom: "5px" }}>
      <Button
        disabled={disabled}
        style={{ backgroundColor: "#fcfdfe" }}
        classes={{
          root: classes.buttonContainedRoot,
          disabled: classes.buttonContainedDisabled,
          containedPrimary: classes.buttonContainedPrimary,
        }}
        variant="contained"
        color="primary"
        onClick={() => {
          onButtonClicked(item);
        }}
      >
        {value}
      </Button>
    </div>
  );
};

const BodyLink1 = (props) => {
  const { classes, value, onBodyLinkClicked, ...otherProps } = props;

  return value !== undefined && value !== "" ? (
    <Typography
      onClick={onBodyLinkClicked}
      style={{
        fontFamily: "Brown-Bold",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#ee2a24",
      }}
      variant="body1"
    >
      {value !== undefined && value !== "" ? value : "This is empty"}
    </Typography>
  ) : null;
};

const BodyText1 = (props) => {
  const { classes, value } = props;

  return value !== undefined && value !== "" ? (
    <ShowWithPopup
      message={value !== undefined && value !== "" ? value : "This is empty"}
    >
      <Typography
        style={Object.assign({}, styles.singleLineText)}
        variant="body1"
        className={classes.bodyText1}
      >
        {value !== undefined && value !== "" ? value : "This is empty"}
      </Typography>
    </ShowWithPopup>
  ) : null;
};

const BodyText2 = (props) => {
  const { classes, value } = props;

  return value !== undefined && value !== "" ? (
    <ShowWithPopup
      message={value !== undefined && value !== "" ? value : "This is empty"}
    >
      <Typography
        style={Object.assign({}, styles.singleLineText)}
        variant="body1"
        className={classes.bodyText2}
      >
        {value !== undefined && value !== "" ? value : "This is empty"}
      </Typography>
    </ShowWithPopup>
  ) : null;
};

const BodyLabel1 = (props) => {
  const { classes, value } = props;

  return value !== null && value !== "null" ? (
    <div
      style={Object.assign({}, styles.label, {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
      })}
    >
      <Typography variant="body1" className={classes.bodyLabel1}>
        {value}
      </Typography>
    </div>
  ) : null;
};

const BodyPendingLable = (props) => {
  const { classes, value } = props;

  return value !== null && value !== "null" ? (
    <div
      style={Object.assign({}, styles.label, {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        backgroundColor: "rgba(223, 150, 65, 0.08)",
        height: "24px",
        
        borderRadius: "14px",
      })}
    >
      <AccessTimeIcon style={{ color: "#DF9641", fontSize: 13, paddingRight: "5px" }} />
      <Typography variant="body1" className={classes.bodyPendingLable}>
        {value}
      </Typography>
    </div>
  ) : null;
};

const BodySuccessLable = (props) => {
  const { classes, value } = props;

  return value !== null && value !== "null" ? (
    <div
      style={Object.assign({}, styles.label, {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        backgroundColor: "rgba(0, 178, 138, 0.08)",
        height: "24px",
        
        borderRadius: "14px",
      })}
    >
      <CheckOutlinedIcon style={{ color: "#00B28A", fontSize: 13, paddingRight: "5px" }} />
      <Typography variant="body1" className={classes.bodySuccessLable}>
        {value}
      </Typography>
    </div>
  ) : null;
};

const BodyRejectLable = (props) => {
  const { classes, value } = props;

  return value !== null && value !== "null" ? (
    <div
      style={Object.assign({}, styles.label, {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        backgroundColor: "rgba(238, 42, 36, 0.08)",
        height: "24px",
        
        borderRadius: "14px",
      })}
    >
      <ErrorOutlineOutlinedIcon style={{ color: "#EE2A24", fontSize: 13, paddingRight: "5px" }} />
      <Typography variant="body1" className={classes.bodyRejectLable}>
        {value}
      </Typography>
    </div>
  ) : null;
};

const VoMessageToVmButton = (props) => {
  const { classes, value, item, onVoMessage, disabled = false, receeDeatails,  } = props;
  const [comment, setComment] = useState("");
  const [note, setNote] = React.useState();
  const standardRecceNotes = 
    [ "Offer more discount to this client",
      "I would want to meet this client",
      "Upgrade the menu at same price",
      "Provide additional services at same price",
      "Call me to discuss about this client",
      "We are ok to drop the query",
      "Others"
    ]
      
  const setData = ()=>{
    const initialValues = receeDeatails['vo_message']?.split(" => ")
    if(initialValues?.length === 2){
      setNote(`${standardRecceNotes.findIndex((value)=> value == initialValues[0])}` )
      setComment(initialValues[1])
    }
  }

  useEffect(()=>{
    setData()
  },[])

  const handleSendMessage = async() => {
    const message = document?.getElementById("addComment")?.value
      
      if(!(note=== null || note === undefined || note === "") && message!==undefined){
        const finalMessage = `${standardRecceNotes[note]} => ${message?.trim()}` 
        onVoMessage(finalMessage, receeDeatails)
        handleClose()
        receeDeatails['vo_message'] = finalMessage; 
        setData()
      }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  
  const [open, setOpen] = React.useState(false);

  const handelFocus = (event)=>{ event.target.selectionStart = event.target.selectionStart + comment?.length }

  const handleChange = (event)=>{ setNote(event.target.value) }

  const handleClickOpen = () => { setOpen(true) } 
  
  const handleClose = () => { setOpen(false) }

  return (
    <div style={{width: "100%"}}>
      
      <Button variant="outlined" color="primary" onClick={handleClickOpen} className={classes.buttonContainedPopUp}>
        {receeDeatails['vo_message'] ? `Edit Message` : `Add an action for VM` }
      </Button> 

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.sectionContainTitle}>
          Action for VM
        </DialogTitle>
        <DialogContent >

          {/* Standard Recce Notes */}

          <FormControl component="fieldset">
            <RadioGroup aria-label="standardNotes" name="standardNotes" value={note} onChange={handleChange}>
              {standardRecceNotes.map((option, index)=>
              <FormControlLabel 
                classes={{
                  label: classes.vmMessageRadioLabel
                }}
                key={index} 
                value={`${index}`} 
                control={<Radio />} 
                label={option} 
              />)}
            </RadioGroup>
          </FormControl>
          <br/>
          <br/>
          {/* Comment for VM */}
          {(note=== null || note === undefined || note === "") ? null :
            <TextField 
              autoFocus 
              fullWidth  
              variant= "outlined"
              defaultValue={comment} 
              onFocus={handelFocus}  
              id="addComment"  
              multiline rows={3}  
              label= "Add Comment"
            /> 
          } 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" className={classes.buttonContainedVoCancle}>
            Cancel
          </Button>
          <Button 
            variant="contained"
            color= "primary"
            onClick={handleSendMessage} 
            classes={{
              root: classes.submitContainedRoot,
              disabled: classes.submitContainedDisabled,
              containedPrimary: classes.submitContainedPrimary,
            }}
            disabled = {(note=== null || note === undefined || note === "")}
            >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const BodyRow1 = (props) => {
  const { classes, items = [], kind = "mixed" } = props;

  let index = 0;
  const lastIndex = items.filter((item) => item !== null).length - 1;

  const RowItems =
    items.length !== 0 ? (
      <div style={{ display: "flex", overflow: getOverflowKind(kind) }}>
        {items
          .filter((item) => item !== null)
          .map((item) => {
            const { id = index } = item;
            const RenderComponent = getRenderComponent(id, item, classes);

            if (RenderComponent === null) {
              index += 1;
              return null;
            }

            if (index === lastIndex) {
              index += 1;
              return RenderComponent;
            }

            index += 1;
            return (
              <div
                key={id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "spaceBetween",
                }}
              >
                {RenderComponent}
                <CircleSeparator />
              </div>
            );
          })}
      </div>
    ) : null;

  return (
    <div style={{ marginBottom: getBottomRowMargin(kind) }}>{RowItems}</div>
  );
};

const PopupButton = (props) =>{
  const { classes, value, item, onButtonClicked, disabled = false } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (selected) => {
    setOpen(false);
    if(selected != "Na"){
      onButtonClicked(selected);
    }
  };

  return value !== undefined && value !== "" ? (
      <div style={{ marginTop: "10px", marginBottom: "5px" }}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={disabled} >
          {value}
        </Button>
        <SimpleDialogListPopup open={open} onClose={handleClose} item={item} />
      </div>
  ) : null;
}

const ApproveRejectPopupButton = (props) =>{
  const { classes, value, item, onStatusChange, disabled = false, bookingDetails } = props;
  
  const handleChange = (selected="Na", reason="Na") => {
    if(selected != "Na"){
      onStatusChange(selected, reason, bookingDetails);
    }
  };

  return value !== undefined && value !== "" ? (
      <div style={{ marginTop: "10px", marginBottom: "5px", width: "100%" }}>
        <ApproveRejectPopup onApproveReject={handleChange} currentStatus={bookingDetails['vo_status']} item={item} />
      </div>
  ) : null;
}

export const CoCardTemplateSixList = (props) => {
  const { data = [], ...otherProps } = props;

  const lastIndex = data.length - 1;
  let index = 0;

  return data.map((row) => {
    const {
      id,
      data: { data },
    } = row;
    if (index === lastIndex) {
      index += 1;
      return (
        <CoCardTemplateSix key={id ?? index} data={data} {...otherProps} />
      );
    }
    index += 1;

    return (
      <div key={id ?? index}>
        <CoCardTemplateSix data={data} {...otherProps} />
        <div style={{ height: "16px" }} />
      </div>
    );
  });
};

export const CoCardTemplateSix = (props) => {
  const classes = useStyles();

  const { data: { header, body, footer } = {}, elevated = true } = props;

  const Card = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {header != null ? <Header classes={classes} {...header} /> : null}
      {header != null && body != null ? <Divider /> : null}

      {body != null ? <Body classes={classes} {...body} /> : null}

      {footer != null && body != null ? <Divider /> : null}
      {footer != null ? <Footer classes={classes} {...footer} /> : null}
    </div>
  );
  return (
    <div>
      {elevated ? (
        <CoElevatedPanel hasHorizontalMargin={false} hasVerticalMargin={false}>
          <Card {...props} />
        </CoElevatedPanel>
      ) : (
        <CoNonElevatedPanel
          hasHorizontalMargin={false}
          hasVerticalMargin={false}
        >
          <Card {...props} />
        </CoNonElevatedPanel>
      )}
    </div>
  );
};
