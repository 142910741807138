import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { CoNonElevatedPanel } from "../../../components/CoPanel";
import { CoListItemLoading, CoListItemEmpty } from "../../../components/CoSection";

const useStyles = makeStyles((theme) =>
  createStyles({
    SubHeading: {
      fontFamily: "Brown-Bold",
      fontSize: "12px",
      color: "#000000",
      opacity: 0.54,
      lineHeight: 1.45,
      marginRight: 10,
      textAlign: "start",
    },
    Value: {
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.71,
      alignSelf: "center",
      letterSpacing: "0.33px",
      textAlign: "center",
      marginRight: 5,
    },
    selectedSubHeading: {
      fontFamily: "Brown-Bold",
      fontSize: "12px",
      color: "#D80666",
      opacity: 0.54,
      lineHeight: 1.45,
      marginRight: 10,
      textAlign: "start",
    },
    selectedValue: {
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#D80666",
      opacity: 0.97,
      lineHeight: 1.71,
      alignSelf: "center",
      letterSpacing: "0.33px",
      textAlign: "center",
      marginRight: 5,
    },
  })
);

const Divider = (props) => {
  return (
    <div
      style={{
        width: "1px",
        opacity: 0.1,
        backgroundColor: "#000000",
      }}
    />
  );
};

const CoMultiColumnRowSubHeading = (props) => {
  const { classes, isSelected, value } = props;

  return (
    <Typography variant="body1" className={isSelected ? classes.selectedSubHeading : classes.SubHeading}>
      {value}
    </Typography>
  );
};

const CoMultiColumnRowValue = (props) => {
  const { classes, isSelected, value } = props;

  return (
    <Typography variant="body1" className={isSelected ? classes.selectedValue : classes.Value}>
      {value}
    </Typography>
  );
};

const CoMultiColumnItem = (props) => {
  const { title, id, value, onClick, selectedTab, ...otherProps } = props;

  const classes = useStyles();

  return (
    <div
      style={{
        ...otherProps,
      }}
      onClick={
        onClick != null && selectedTab != id
          ? () => {
              onClick(id);
            }
          : null
      }
    >
      <CoMultiColumnRowValue classes={classes} isSelected={selectedTab == id} value={value ?? "NA"} />
      <CoMultiColumnRowSubHeading classes={classes} isSelected={selectedTab == id} value={title ?? "NA"} />
    </div>
  );
};

 const CoMultiColumnRow = (props) => {
  let index = 0;
  const [selected, setSelected] = React.useState(0);
  const { data={onClick : () => {}, items:[],}, ...otherProps } = props;

  if (data.items?.length === 0 || data === null) {
    return <CoListItemLoading style={{ height: "100px" }} />;
  }
  const lastIndex = data.items.length;
  

  const onTabChange = (index) =>{
    setSelected(index);
    data.onClick(index);
  };

  return (
    <CoNonElevatedPanel>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "scroll",
        }}
      >
        {data.items
          .filter((row) => {
            return row.value != null;
          })
          .map((item) => {
            index += 1;
            return (
              <>
                <CoMultiColumnItem
                  key={index}
                  id={item.id}
                  selectedTab={selected}
                  title={item.title}
                  value={item.value}
                  onClick={onTabChange}
                  {...item}
                />
                {index != lastIndex ? <Divider /> : null}
              </>
            );
          })}
      </div>
    </CoNonElevatedPanel>
  );
};



export default CoMultiColumnRow;
