import React from "react";

import {
  Typography,
  createStyles,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  REQUEST_STATE_LOADING,
  REQUEST_STATE_ERROR,
} from "../common/states/actions.request";
import { Error, EmptyCard, ErrorCard, CoErrorScreen } from "./EmptyScreen";
import { CoElevatedPanel } from "./CoPanel";

import { Shimmer } from "react-shimmer";
import ContainerDimensions from "react-container-dimensions";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      marginTop: "15px",
      fontFamily: "Brown-Bold",
      fontSize: "16px",
      color: "#ffffff",
      lineHeight: "1.5",
      alignSelf: "center",
      opacity: 1.0,
    },
    actionButtonLabel: {
      textTransform: "capitalize",
      color: "#ee2e24",
      fontSize: "16px",
      fontFamily: "Brown-Bold",
      marginTop: "3px",
      lineHeight: "1.2",
    },
    root: {
      position: "relative",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    top: {
      color: "#1a90ff",
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

const LoadingScreen = (props) => {
  const classes = useStyles();

  const { message = "Please wait" } = props;
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      }}
    >
      <div
        style={{
          flex: 1,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <CircularProgress
          thickness={2.5}
          size={46}
          style={{
            color: "#1ab64f",
            alignSelf: "center",
            opacity: 1.0,
          }}
        />
        <Typography variant="body1" className={classes.title}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

const ShimmerLoadingCard = (props) => {
  const { hasMargins = true, cards = [1] } = props;

  const ShimmerCard = () => {
    return (
      <CoElevatedPanel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerDimensions>
            {({ width }) => (
              <div>
                <Shimmer height={16} width={width / 2} />
                <div style={{ height: "16px" }} />
                <Shimmer height={16} width={width} />
                <div style={{ height: "16px" }} />
                <Shimmer height={120} width={width} />
              </div>
            )}
          </ContainerDimensions>
        </div>
      </CoElevatedPanel>
    );
  };

  return (
    <div style={{ margin: hasMargins ? "20px" : "0px" }}>
      {cards.map((card) => {
        return (
          <div key={card} style={{ marginBottom: "20px" }}>
            <ShimmerCard />
          </div>
        );
      })}
    </div>
  );
};

const Loading = (props) => {
  const {
    loading: { isLoading = true } = {},
    LoadingComponent = LoadingScreen,
    children,
  } = props;

  return isLoading ? <LoadingComponent {...props.loading} /> : children;
};

export const LoadingByRequestState = (props) => {
  const {
    message,
    requestState,
    LoadingComponent = ShimmerLoadingCard,
    children,
    cards,
  } = props;

  return (
    <>
      {requestState === REQUEST_STATE_LOADING ? (
        <LoadingComponent
          message={message}
          {...props.loading}
          cards={cards}
        ></LoadingComponent>
      ) : (
        children
      )}
    </>
  );
};

export const LoadingErrorEmpty = (props) => {
  const {
    api: {
      request_state: requestState,
      loading: { message: loadingMessage } = {},
      error: { message: errorMessage, code: errorCode } = {},
    },
    children,
    isListEmpty,
    emptyCards,
    empty: { title: emptyTitle, subtitle: emptySubtitle } = {},
  } = props;
  return (
    <LoadingByRequestState
      cards={emptyCards}
      message={loadingMessage}
      requestState={requestState}
    >
      <CoErrorScreen
        hasError={requestState === REQUEST_STATE_ERROR}
        isLoading={requestState === REQUEST_STATE_LOADING}
        message={errorMessage}
        statusCode={errorCode}
        {...props}
      >
        <EmptyCard
          isLoading={requestState === REQUEST_STATE_LOADING}
          isEmpty={isListEmpty}
          title={emptyTitle}
          subtitle={emptySubtitle}
        >
          {children}
        </EmptyCard>
      </CoErrorScreen>
    </LoadingByRequestState>
  );
};

export default Loading;
