export const UPDATE_STATE_UNDEFINED = undefined;
export const UPDATE_STATE_AVAILABLE = "AVAILABLE";
export const UPDATE_STATE_SUCCESS = "SUCCESS";
export const UPDATE_STATE_ERROR = "ERROR";

export const SET_UPDATE_STATE = "SET_UPDATE_STATE";
export const RESET_UPDATE_STATE = "RESET_UPDATE_STATE";

export default {
  STATE: {
    UNDEFINED: UPDATE_STATE_UNDEFINED,
    AVAILABLE: UPDATE_STATE_AVAILABLE,
    SUCCESS: UPDATE_STATE_SUCCESS,
    ERROR: UPDATE_STATE_ERROR,
  },
};
