import { parseDate } from "../../common/utils/RestUtils";

export const mapGetRecceByIdResponse = (response) => {
  const {
    receeId: recce_id,
    data: { pax },
    data: { dropReasonKey: drop_reason_key },
    data: { dropReasonValue: drop_reason_value },
    data: { eventDate: event_date },
    data: { leadName: lead_name },
    data: { potentialGmv: potential_gmv },
    data: { visitedDate: visited_date },
    data: { scheduledDate: visit_scheduled_date },
    data: { eventType: event_type },
  } = response;

  return {
    pax,
    recce_id,
    lead_name,
    drop_reason_key,
    drop_reason_value,
    event_date: parseDate({ dateString: event_date }),
    visited_date: parseDate({ dateString: visited_date }),
    visit_scheduled_date: parseDate({ dateString: visit_scheduled_date }),
    potential_gmv,
    event_type,
  };
};

export const mapRecceResponse = (response) => {
  const {
    pax,
    status,
    receeId: recce_id,
    dropReasonKey: drop_reason_key,
    dropReasonValue: drop_reason_value,
    eventDate: event_date,
    leadName: lead_name,
    visitedDate: visited_date,
    scheduledDate: visit_scheduled_date,
    potentialGmv: potential_gmv,
    vmNotes: vm_notes,
    voMessage: vo_message,
  } = response;

  return {
    pax,
    recce_id,
    drop_reason_key,
    drop_reason_value,
    event_date: parseDate({ dateString: event_date }),
    lead_name,
    visited_date: parseDate({ dateString: visited_date }),
    visit_scheduled_date: parseDate({ dateString: visit_scheduled_date }),
    potential_gmv,
    status,
    vm_notes,
    vo_message,
  };
};

export const mapRecceListResponse = (responseList = []) => {
  return responseList.map((recce) => mapRecceResponse(recce));
};

export const mapRecceSummaryResponse = (recceSummary) => {
  return Object.keys(recceSummary).map((key) => {
    return {
      date: parseDate({ dateString: key }),
      event_count: recceSummary[key],
    };
  });
};
