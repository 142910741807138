import * as firebase from "firebase/app";

const prodFirebaseConfig = {
  apiKey: "AIzaSyAacRHHZWYU_DG2pgeaNx_n44AFpwVX7NM",
  authDomain: "weddingz-in.firebaseapp.com",
  databaseURL: "https://weddingz-in.firebaseio.com",
  projectId: "weddingz-in",
  storageBucket: "weddingz-in.appspot.com",
  messagingSenderId: "263551220397",
  appId: "1:263551220397:web:4a7211079d482968032424",
  measurementId: "G-PFV8RYF69B",
};

const devFirebaseConfig = {
  apiKey: "AIzaSyD7BsmI1Xpe0BckCRGbBF2TD6L16B97ULw",
  authDomain: "wedz--player-sample.firebaseapp.com",
  databaseURL: "https://wedz--player-sample.firebaseio.com",
  projectId: "wedz-youtube-player-sample",
  storageBucket: "wedz-youtube-player-sample.appspot.com",
  messagingSenderId: "688473250286",
  appId: "1:688473250286:web:cd45878d21af8ecf9456ff",
  measurementId: "G-SLWR3EXF2Y",
};

const firebaseConfig =
  process.env.REACT_APP_ENV === "PRODUCTION" &&
  process.env.REACT_APP_FIREBASE_ENV === "PRODUCTION"
    ? prodFirebaseConfig
    : devFirebaseConfig;

const prodFirebaseVapidKey =
  "BCnKyWi1lbpDVXuOyxDRVPeHPk1CsOycYUQzlc8JzTRnxRnyMbqeAXisGLHmJTZRYGlpne45V1IrRhKNiXqogGs";
const devFirebaseVapidKey =
  "BDMxbC4wLa4njBuL3R5mAqExnKKBXKTYQD6J-N4-B40_GIS0xjyWMC1pmloNVjrqqlfaVa4TMNiMWcYZ4lFdpO0";

const firebaseVapidKey =
  process.env.REACT_APP_ENV === "PRODUCTION" &&
  process.env.REACT_APP_FIREBASE_ENV === "PRODUCTION"
    ? prodFirebaseVapidKey
    : devFirebaseVapidKey;

let firebaseApp;
function setup() {
  //    Firebase is not initialized. Initialise it first before
  //    initialising analytics.
  if (firebase.apps.length === 0) {
    console.log("FIREBASE : Initialising firebase from spectator");
    console.log(
      "FIREBASE : Firebase Environment : " + process.env.REACT_APP_FIREBASE_ENV
    );
    firebaseApp = firebase.initializeApp(firebaseConfig);
    console.log("FIREBASE : Firebase initialised : " + firebase.app().name);
  } else {
    console.log(
      "FIREBASE : Firebase already initialised : " + firebase.app().name
    );
  }
}

export default {
  setup: setup,
  firebaseApp: firebaseApp,
  firebaseConfig: firebaseConfig,
  firebaseVapidKey: firebaseVapidKey,
  firebaseEnv: process.env.REACT_APP_FIREBASE_ENV,
};
