export const INSTALL_STATE_UNDEFINED = undefined;
export const INSTALL_STATE_REQUESTED = "REQUESTED";
export const INSTALL_STATE_SUCCESS = "SUCCESS";
export const INSTALL_STATE_STANDBY = "STANDBY";

export const SET_INSTALL_STATE = "SET_INSTALL_STATE";
export const RESET_INSTALL_STATE = "RESET_INSTALL_STATE";

export default {
  STATE: {
    UNDEFINED: INSTALL_STATE_UNDEFINED,
    REQUESTED: INSTALL_STATE_REQUESTED,
    SUCCESS: INSTALL_STATE_SUCCESS,
    STANDBY: INSTALL_STATE_STANDBY,
  },
};
