import React from "react";

import * as firebase from "firebase/app";
import "firebase/messaging";

class FirebaseCloudMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registrationToken: "NA",
      notification: undefined,
    };
  }

  componentDidMount() {
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      const { notification } = payload;
      this.setState({ notification: notification });
      console.log("Message received. ", payload);
    });

    messaging.getToken().then((token) => {
      console.log("Device Registration Token", token);
      this.setState({ registrationToken: token });
    });
  }

  render() {
    const { children } = this.props;
    const { registrationToken, notification } = this.state;
    return (
      <>
        {children({ token: registrationToken })}
        <div style={{ margin: "20px" }}>
          <p>Notification Will show here : </p>
          {notification !== undefined ? (
            <p>{JSON.stringify(notification)}</p>
          ) : null}
        </div>
      </>
    );
  }
}

export default FirebaseCloudMessaging;
