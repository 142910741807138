export const swapiUrls = {
  people_api: "/api/people",
};

export const venueManagementUrls = {
  getRecce: "/recee/get_recee",
  getRecces: "/recee/get_recees",
  review: "/review",
  dashboard: "/dashboard",
  getOrders: "/order/get_orders",
  getMetrics: "/metrics/business_metrics",
  generateLoginOtp: "/login/generateLoginOtp",
  verifyLoginOtp: "/login/verifyLoginOtp",
  logout: "/login/logout",
  updateDeviceToken: "/token/update_token",
  allCalendarEvents: "/calendar/all",
  updateBookingStatus: "order/update_status",
  sendVoMessage: "/recee/send_vo_message"
};

const URL = {
  ...venueManagementUrls,
};

export default URL;
