import RequestAction, {
  setRequestStateFor,
  REQUEST_STATE_STAND_BY,
  REQUEST_STATE_UNDEFINED,
  REQUEST_ACTION_RESET_ALL_STATES,
} from "../../../common/states/actions.request";

import prodStaticResponse from "./venues.static.prod.js";
import stagingStaticResponse from "./venues.static.staging.js";

export const SET_USER_VENUE_LIST = "SET_USER_VENUE_LIST";
export const RESET_USER_VENUE_LIST = "RESET_USER_VENUE_LIST";

export const SET_SELECTED_VENUE = "SET_SELECTED_VENUE";
export const RESET_SELECTED_VENUE = "RESET_SELECTED_VENUE";

export const SET_USER_STATE = "SET_USER_STATE";
export const RESET_USER_STATE = "RESET_USER_STATE";

export const SET_DEVICE_TOKEN = "SET_DEVICE_TOKEN";
export const RESET_DEVICE_TOKEN = "RESET_DEVICE_TOKEN";

export const REQUEST_KEY_USER_VENUE_LIST = "rk_user_venue_list";

export const requestUserVenueList = (params) => async (dispatch) => {
  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_USER_VENUE_LIST,
    })
  );

  setTimeout(() => {
    const response =
      process.env.REACT_APP_ENV === "PRODUCTION"
        ? prodStaticResponse
        : stagingStaticResponse;

    dispatch({
      type: SET_SELECTED_VENUE,
      selectedVenue: response.selectedVenue,
    });

    dispatch({
      type: SET_USER_VENUE_LIST,
      userVenueList: response.userVenueList,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_USER_VENUE_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );
  }, 300);
};

export const updateSelectedVenue = (params) => async (dispatch) => {
  const { venue } = params;

  dispatch(
    RequestAction.setLoading({
      message: "Please Wait...",
      key: REQUEST_KEY_USER_VENUE_LIST,
    })
  );

  setTimeout(() => {
    dispatch({
      type: SET_SELECTED_VENUE,
      selectedVenue: venue,
    });

    dispatch(
      RequestAction.setRequestState({
        key: REQUEST_KEY_USER_VENUE_LIST,
        request_state: REQUEST_STATE_STAND_BY,
      })
    );

    dispatch(
      setRequestStateFor({
        request_state: REQUEST_STATE_UNDEFINED,
        update_for_except: [REQUEST_KEY_USER_VENUE_LIST],
      })
    );

    dispatch({
      type: REQUEST_ACTION_RESET_ALL_STATES,
    });
  }, 300);
};
