import VenueManagementUrls from "../sources/api/urls";
import * as VenueManagementClient from "../sources/api/clients/vendorManagement";
import {
  mapVenueDashboardApiResponse,
  mapBusinessMetricsMonthWiseApiResponse,
} from "../mappings/dashboard.mappings";

const getVenueDashboard = async ({ venueId }) => {
  // todo, change this before finishing the calendar feature
  const url = `${VenueManagementUrls.dashboard}/${venueId}/`;

  try {
    // return mapVenueDashboardApiResponse({
    //   data: {
    //     venueId: "5c9dbee7238f4c000193eb2d",
    //     businessMetrics: {
    //       venueId: "",
    //       leadsCount: 0,
    //       receesCount: 0,
    //       ordersCount: 0,
    //       month: "JUL, 2020",
    //       l2r: null,
    //       r2c: null,
    //     },
    //     upcomingRecees: {
    //       "2020-07-06": 1,
    //       "2020-07-12": 5,
    //     },
    //     upcomingOrders: {
    //       "2020-07-14": 1,
    //       "2020-07-15": 1,
    //       "2020-07-08": 1,
    //     },
    //     ratings: {
    //       average: 4.48,
    //       delights: 60.19,
    //       nonDelights: 1.85,
    //     },
    //   },
    // });

    const venueDashboard = await VenueManagementClient.getRequest(url)
      .then((response) => response.data)
      .then((response) => mapVenueDashboardApiResponse(response));
    console.log(
      "%c Venue Dashboard Response : ",
      "color: yellow",
      venueDashboard
    );

    return venueDashboard;
  } catch (err) {
    console.error("Venue Dashboard Error : ", err);
    throw err;
  }
};

const getBusinessMetricsByDateRange = async ({
  venueId,
  startDate,
  endDate,
}) => {
  const url = `${VenueManagementUrls.getMetrics}`;

  const body = {
    venueId: venueId,
    startDate: startDate,
    endDate: endDate,
  };

  try {
    const businessMetrics = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => mapBusinessMetricsMonthWiseApiResponse(response));

    console.log(
      "%c getBusinessMetricsByDateRange : ",
      "color: yellow",
      businessMetrics
    );

    return businessMetrics;
  } catch (err) {
    console.error("getBusinessMetricsByDateRange Error : ", err);
    throw err;
  }
};

export default {
  getVenueDashboard: getVenueDashboard,
  getBusinessMetricsByDateRange: getBusinessMetricsByDateRange,
};
