import VenueManagementUrls from "../sources/api/urls";
import * as VenueManagementClient from "../sources/api/clients/vendorManagement";
import {
  mapGetRecceByIdResponse,
  mapRecceListResponse,
} from "../mappings/recce.mappings";

const getRecceById = async (recceId) => {
  const url = `${VenueManagementUrls.getRecce}/${recceId}/`;

  try {
    const recce = await VenueManagementClient.getRequest(url)
      .then((response) => response.data)
      .then((response) => mapGetRecceByIdResponse(response));

    console.log("%c Get Recce : ", "color: yellow", recce);

    return recce;
  } catch (err) {
    console.error("Get Recce by Id Error : ", err);
  }
};

const getUpcomingRecceList = async ({ venueId }) => {
  const url = `${VenueManagementUrls.getRecces}`;
  const body = {
    venueId: venueId,
    tense: "FUTURE",
  };

  try {
    const recceList = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => response.data)
      .then((response) => response.recees)
      .then((response) => mapRecceListResponse(response));

    console.log("%c Get Upcoming Visits List : ", recceList);
    return recceList;
  } catch (err) {
    console.error("Get Upcoming Visits List: ", err);
    throw err;
  }
};

const getRecceListByDateRange = async ({ venueId, startDate, endDate }) => {
  const url = `${VenueManagementUrls.getRecces}`;
  const body = {
    venueId: venueId,
    startDate: startDate,
    endDate: endDate,
  };

  try {
    const recceList = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data)
      .then((response) => response.data)
      .then((response) => response.recees)
      .then((response) => mapRecceListResponse(response));

    console.log("%c Get recce list by date range : ", recceList);
    return recceList;
  } catch (err) {
    console.error("Get recce list by date range : ", err);
    throw err;
  }
};

const sendVoMessage = async ({ venueId, receeId, voMessage}) => {
  const url = `${VenueManagementUrls.sendVoMessage}`;

  const body = {
    venueId: venueId,
    receeId: receeId,
    voMessage: voMessage,
  };

  try {
    const messageResponse = await VenueManagementClient.postRequest(url, body)
      .then((response) => response.data);

    console.log(
      "%c  : updateBookingStatus ",
      "color: yellow",
      messageResponse
    );

    return messageResponse;
  } catch (err) {
    console.error("sendVoMessage Error : ", err);
    throw err;
  }
};

export default {
  getRecceById: getRecceById,
  getUpcomingRecceList: getUpcomingRecceList,
  getRecceListByDateRange: getRecceListByDateRange,
  sendVoMessage: sendVoMessage,
};
