import {
  mapVenueOverallRatingToTemplateOne,
  mapVenueOverallRatingToCustomerReviewCard,
} from "../../reviews/mappings/review.mappings";
import { mapBookingSummaryToBookingDashboardCard } from "../../events/mappings/order.mapping"
import {
  formatJsDate,
  getCurrentDate,
  formatINRCurrency,
} from "../../../common/utils";

const mapEventSummaryToTemplateOne = (eventSummary = []) => {
  return {
    data: eventSummary.map((summary) => {
      return {
        title: formatJsDate({ dateObject: summary.date, format: "DD MMMM YY" }),
        value: `${summary.event_count} Event${
          summary.event_count !== 1 ? "s" : ""
        }`,
      };
    }),
  };
};

const mapOccupancySummaryToTemplateOne = (occupancySummary = []) => {
  return {
    data: occupancySummary.map((summary) => {
      return {
        title: formatJsDate({ dateObject: summary.month, format: "MMMM YY" }),
        value: ` Event${
          summary.event_count !== 1 ? "s" : ""
        }`,
      };
    }),
  };
};

const mapOccupancyToTemplateSixRowItem = ({ id, name }, occupancy) => {
  return {
    id: id,
    name: name,
    data: {
      type: "template_six",
      data: {
        header: {
          title: formatJsDate({
            dateObject: occupancy.month,
            format: "MMMM YYYY",
          }),
          title2: `${formatINRCurrency(Math.round(occupancy["gmv_amount"]))}`,
          title3: `${occupancy.event_count} Event${
            occupancy.event_count !== 1 ? "s" : ""
          }`,
        },
      },
    },
  };
};

const mapOccupancyListToTemplateSix = (occupancyList = []) => {
  let index = 0;
  const templateData = occupancyList.map((occupancy) => {
    const templateSixRowItem = mapOccupancyToTemplateSixRowItem(
      {
        id: index,
        name: index,
      },
      occupancy
    );

    index += 1;
    return templateSixRowItem;
  });

  return {
    type: "template_six",
    data: templateData,
  };
};

const mapUpcomingReccesSummaryToTemplateOne = (reccesSummary = []) => {
  return {
    data: reccesSummary.map((summary) => {
      return {
        title: formatJsDate({
          dateObject: summary.date,
          format: "DD MMMM yyyy",
        }),
        value: `${summary.event_count} Visit${
          summary.event_count !== 1 ? "s" : ""
        }`,
      };
    }),
  };
};

export const mapBusinessMetricsSummaryToTemplateOne = (
  businessMetricsSummary = []
) => {
  return {
    data: [
      {
        title: "# Leads",
        value: `${businessMetricsSummary["lead_count"] ?? "NA"}`,
      },
      {
        title: "# Visits",
        value: `${businessMetricsSummary["recce_count"] ?? "NA"}`,
      },
      {
        title: "# Closures",
        value: `${businessMetricsSummary["closure_count"] ?? "NA"}`,
      },

      {
        title: "L2V (Lead to Visit)",
        value: `${
          businessMetricsSummary["lead_to_recce_percentage"] !== 0 &&
          businessMetricsSummary["lead_to_recce_percentage"] !== undefined &&
          businessMetricsSummary["lead_to_recce_percentage"] !== null
            ? businessMetricsSummary["lead_to_recce_percentage"]?.toFixed(1) +
              "%"
            : "NA" ?? "NA"
        }`,
      },
      {
        title: "V2C (Visit to Closure)",
        value: `${
          businessMetricsSummary["recce_to_closure_percentage"] !== 0 &&
          businessMetricsSummary["recce_to_closure_percentage"] !== undefined &&
          businessMetricsSummary["recce_to_closure_percentage"] !== null
            ? businessMetricsSummary["recce_to_closure_percentage"]?.toFixed(
                1
              ) + "%"
            : "NA" ?? "NA"
        }`,
      },
      {
        title: "GMV",
        value: `${
          businessMetricsSummary["gmv"] !== 0 &&
          businessMetricsSummary["gmv"] !== null
            ? formatINRCurrency(Math.round(businessMetricsSummary["gmv"])) ?? "NA"
            : "0"
        }`,
      },
    ],
  };
};

export const mapUpcomingEventSummary = (eventSummary = []) => {
  const eventList = mapEventSummaryToTemplateOne(eventSummary);

  return {
    sectionTitle: venueDashboardInitialState.upcomingEventSummary.sectionTitle,
    ...eventList,
  };
};

export const mapOccupancySummary = (occupancySummary = []) => {
  const templateData = mapOccupancyListToTemplateSix(occupancySummary);
  return {
    sectionTitle: venueDashboardInitialState.occupancySummary.sectionTitle,
    templateData,
  };
};

export const mapOccupancySummary2 = (occupancySummary = []) => {
  const summaryList = mapOccupancySummaryToTemplateOne(occupancySummary);

  return {
    sectionTitle: venueDashboardInitialState.occupancySummary2.sectionTitle,
    ...summaryList,
  };
};

export const mapUpcomingRecceSummary = (reccesSummary = []) => {
  const recceList = mapUpcomingReccesSummaryToTemplateOne(reccesSummary);
  return {
    sectionTitle: venueDashboardInitialState.upcomingRecceSummary.sectionTitle,
    ...recceList,
  };
};

export const mapBusinessMetricsSummary = (businessMetricsSummary = []) => {
  return {
    sectionTitle:
      venueDashboardInitialState.businessMetricsSummary.sectionTitle,
    ...mapBusinessMetricsSummaryToTemplateOne(businessMetricsSummary),
  };
};

export const mapCustomerReviewsData = (venueRating) => {
  const venueOverallRatings = mapVenueOverallRatingToTemplateOne(venueRating);

  return {
    sectionTitle: venueDashboardInitialState.customerReviews.sectionTitle,
    ...venueOverallRatings,
  };
};

export const mapCustomerReviewsDataV2 = (venueRating) => {
  const venueOverallRatings = mapVenueOverallRatingToCustomerReviewCard(
    venueRating
  );
  return {
    sectionTitle: venueDashboardInitialState.customerReviews.sectionTitle,
    ...venueOverallRatings,
  };
};

export const mapBookingSummaryData = (bookingSummary) =>{
  const booking_summary = mapBookingSummaryToBookingDashboardCard(bookingSummary);
  return{
    sectionTitle: venueDashboardInitialState.bookingSummary.sectionTitle,
    ...booking_summary,
  }
};

export const venueDashboardInitialState = {
  occupancySummary: {
    sectionTitle: `Event Calendar `,
  },
  occupancySummary2: {
    sectionTitle: `Occupancy 2`,
  },
  businessMetricsSummary: {
    sectionTitle: `Performance Report (${getCurrentDate("MMMM")})`,
  },
  customerReviews: {
    sectionTitle: "Customer Reviews",
  },
  upcomingEventSummary: {
    sectionTitle: "Upcoming Events",
  },
  upcomingRecceSummary: {
    sectionTitle: "Upcoming Visits",
  },
  bookingSummary: {
    sectionTitle: "Bookings Summary",
  },
};
