import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.7,
      lineHeight: 1.51,
      marginRight: 5,
    },
    value: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.51,
      textAlign: "right",
    },
    tableTitle: {
      fontFamily: "Brown-Bold",
      fontSize: "11px",
      color: "#000000",
      opacity: 0.54,
      lineHeight: 1.45,
      marginRight: 5,
    },
    tableValue: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.87,
      lineHeight: 1.71,
      alignSelf: "center",
      letterSpacing: "0.33px",
    },

    presentation1HeaderTitle: {
      opacity: 0.54,
      fontFamily: "Brown",
      fontSize: "16px",
      lineHeight: 1.25,
      textAlign: "center",
      color: "#000000",
    },
    presentation1HeaderValue: {
      opacity: 0.87,
      fontFamily: "Brown-Bold",
      fontSize: "24px",
      lineHeight: 1.17,
      textAlign: "center",
      color: "#000000",
    },
  })
);

const TwoColumnRowTitle = (props) => {
  const { classes, value } = props;

  return (
    <Typography variant="body1" className={classes.title}>
      {value}
    </Typography>
  );
};

const TwoColumnRowValue = (props) => {
  const { classes, value } = props;

  return (
    <Typography variant="body1" className={classes.value}>
      {value}
    </Typography>
  );
};

const FourColumnRowTitle = (props) => {
  const { classes, value } = props;

  return (
    <Typography variant="body1" className={classes.tableTitle}>
      {value}
    </Typography>
  );
};

const FourColumnRowValue = (props) => {
  const { classes, value } = props;

  return (
    <Typography variant="body1" className={classes.tableValue}>
      {value}
    </Typography>
  );
};

export const CoTwoColumnRow = (props) => {
  const { title, value } = props;
  const classes = useStyles();

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <TwoColumnRowTitle classes={classes} value={title ?? "NA"} />
      <TwoColumnRowValue classes={classes} value={value ?? "NA"} />
    </div>
  );
};

export const CoFourColumnItem = (props) => {
  const { title, value } = props;
  const classes = useStyles();

  return (
    <div style={{}}>
      <FourColumnRowValue classes={classes} value={value ?? "NA"} />
      <FourColumnRowTitle classes={classes} value={title ?? "NA"} />
    </div>
  );
};

export const CoFourColumnRow = (props) => {
  const { row = [] } = props;

  let index = 0;
  return (
    <CoFlatPanel>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {row.map((item) => {
          index += 1;
          return (
            <CoFourColumnItem
              key={index}
              title={item.title}
              value={item.value}
            />
          );
        })}
      </div>
    </CoFlatPanel>
  );
};

export const CoMultiColumnList = (props) => {
  const { items } = props;

  const lastIndex = items.length - 1;
  let index = 0;
  return items.map((row) => {
    if (index === lastIndex) {
      index += 1;
      return <CoFourColumnRow key={index} row={row} />;
    }
    index += 1;
    return (
      <div key={index}>
        <CoFourColumnRow row={row} />
        <div style={{ height: "16px" }} />
      </div>
    );
  });
};

export const CoTwoColumnList = (props) => {
  const { items = [] } = props;

  const lastIndex = items.length - 1;
  let index = 0;
  return items.map((item) => {
    if (index === lastIndex) {
      index += 1;
      return (
        <CoTwoColumnRow key={index} title={item.title} value={item.value} />
      );
    }
    index += 1;
    return (
      <div key={index}>
        <CoTwoColumnRow title={item.title} value={item.value} />
        <div style={{ height: "8px" }} />
      </div>
    );
  });
};

const CoDataPresentationTemplate1Header = (props) => {
  const { classes, value, title } = props;

  return (
    <div
      style={{
        backgroundColor: "#f3f5f7",
        width: "90%",
        borderRadius: "5px",
        paddingTop: "16px",
        paddingBottom: "16px",
        marginLeft: "15px",
      }}
    >
      <Typography variant="body1" className={classes.presentation1HeaderValue}>
        {value}
      </Typography>
      <div style={{ height: "8px" }}></div>
      <Typography variant="body1" className={classes.presentation1HeaderTitle}>
        {title}
      </Typography>
    </div>
  );
};

const CoDataPresentationTemplate1Item = (props) => {
  const { title, value1, value2 } = props;

  return (
    <div
      style={{
        marginTop: "15px",
        marginBottom: "15px",
        marginLeft: "15px",
        marginRight: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "4px",
        }}
      >
        <Typography
          variant="body1"
          style={{
            opacity: 0.87,
            fontFamily: "Brown",
            fontSize: "16px",
            lineHeight: 1.25,
            color: "#000000",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontFamily: "Brown-Bold",
            fontSize: "16px",
            lineHeight: 1.25,
            color: "#000000",
          }}
        >
          {value1}
        </Typography>
      </div>
      <Typography
        variant="body1"
        style={{
          opacity: 0.54,
          fontFamily: "Brown",
          fontSize: "14px",
          color: "#000000",
        }}
      >
        {value2}
      </Typography>
    </div>
  );
};

const Divider = () => (
  <div
    style={{
      height: "1px",
      opacity: 0.06,
      backgroundColor: "#000000",
    }}
  />
);

export const CoDataPresentationTemplate1 = (props) => {
  const classes = useStyles();

  const { title, value, items = [] } = props;

  const lastIndex = items.length - 1;
  let index = 0;
  return (
    <div
      style={{
        paddingTop: "15px",
        paddingBottom: "1px",
      }}
    >
      <CoDataPresentationTemplate1Header
        value={value}
        title={title}
        classes={classes}
      />
      {items.map((item) => {
        if (index === lastIndex) {
          index += 1;
          return (
            <CoDataPresentationTemplate1Item
              key={index}
              title={item.value1}
              value1={""}
              value2={item.value2}
              classes={classes}
            />
          );
        }
        index += 1;
        return (
          <div key={index}>
            <CoDataPresentationTemplate1Item
              title={item.value1}
              value1={""}
              value2={item.value2}
              classes={classes}
            />
            <Divider />
          </div>
        );
      })}
    </div>
  );
};

const CoPanel = (props) => {
  const { children, horizontalMargins = true, verticalMargins = true } = props;

  return (
    <div
      style={{
        width: "100%",
        borderRadius: "4px",
        paddingTop: verticalMargins ? "20px" : "0px",
        paddingBottom: verticalMargins ? "20px" : "0px",
        backgroundColor: "#ffffff",
        boxShadow: "0 5px 12px 0 rgba(217, 226, 233, 0.5)",
      }}
    >
      <div
        style={{
          marginLeft: horizontalMargins ? "15px" : "0px",
          marginRight: horizontalMargins ? "15px" : "0px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const CoFlatPanel = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        width: "100%",
        borderRadius: "4px",
        paddingTop: "16px",
        paddingBottom: "16px",
        border: "solid 1px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div
        style={{
          marginLeft: "16px",
          marginRight: "16px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CoPanel;

const styles = {
  elevatedPanel: {
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    boxShadow: "0 5px 12px 0 rgba(217, 226, 233, 0.5)",
  },
  flatPanel: {
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    border: "solid 1px rgba(0, 0, 0, 0.08)",
  },
};

const LoadingComponent = () => (
  <div>
    <p>Please wait....</p>
  </div>
);

export const CoElevatedPanel = (props) => {
  const { children, style: cssStyles = {}, isLoading = false, onClick } = props;
  const { hasHorizontalMargin = true, hasVerticalMargin = true } = props;

  return (
    <div
      onClick={onClick}
      style={Object.assign(
        {},
        styles.elevatedPanel,
        {
          width: "100%",
          paddingTop: hasVerticalMargin ? "20px" : "0px",
          paddingBottom: hasVerticalMargin ? "20px" : "0px",
        },
        cssStyles
      )}
    >
      <div
        style={{
          marginLeft: hasHorizontalMargin ? "15px" : "0px",
          marginRight: hasHorizontalMargin ? "15px" : "0px",
        }}
      >
        {isLoading ? <LoadingComponent /> : children}
      </div>
    </div>
  );
};

export const CoNonElevatedPanel = (props) => {
  const { children, style: cssStyles = {}, isLoading = false, onClick } = props;
  const { hasHorizontalMargin = true, hasVerticalMargin = true } = props;

  return (
    <div
      onClick={onClick}
      style={Object.assign(
        {},
        styles.flatPanel,
        {
          paddingTop: hasVerticalMargin ? "16px" : "0px",
          paddingBottom: hasVerticalMargin ? "16px" : "0px",
        },
        cssStyles
      )}
    >
      <div
        style={{
          marginLeft: isLoading || hasHorizontalMargin ? "16px" : "0px",
          marginRight: isLoading || hasHorizontalMargin ? "16px" : "0px",
        }}
      >
        {isLoading ? <LoadingComponent /> : children}
      </div>
    </div>
  );
};
