import React from "react";
import { CoElevatedPanel } from "../../../components/CoPanel";
import { Typography, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Brown",
      fontSize: "14px",
      color: "#000000",
      opacity: 0.7,
      lineHeight: 1.51,
      marginRight: 5,
    },
    value: {
      fontFamily: "Brown-Bold",
      fontSize: "22px",
      color: "#000000",
      opacity: 0.87,
      textAlign: "center",
    },
    delight: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      opacity: 0.87,
      textAlign: "center",
      color: "#4ec685",
    },
    unhappy: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      opacity: 0.87,
      textAlign: "center",
      color: "#ff8387",
    },
    rating: {
      fontFamily: "Brown-Bold",
      fontSize: "14px",
      opacity: 0.6,
      textAlign: "center",
      color: "#000000",
    },
  })
);

const Divider = (props) => {
  return (
    <div
      style={{
        width: "1px",
        opacity: 0.1,
        backgroundColor: "#000000",
      }}
    />
  );
};
const DelightBox = (props) => {
  const { classes, delight } = props;

  return (
    <div style={{ flex: 1 }}>
      <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
        {delight ?? "Na"}
      </Typography>
      <Typography
        style={{ flex: 1 }}
        variant="body1"
        className={classes.delight}
      >
        {"Delight"}
      </Typography>
    </div>
  );
};

const RatingBox = (props) => {
  const { classes, rating } = props;

  return (
    <div style={{ flex: 1 }}>
      <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
        {rating ?? "Na"}
      </Typography>
      <Typography
        style={{ flex: 1 }}
        variant="body1"
        className={classes.rating}
      >
        {"Rating"}
      </Typography>
    </div>
  );
};

const UnhappyBox = (props) => {
  const { classes, unhappy } = props;

  return (
    <div style={{ flex: 1 }}>
      <Typography style={{ flex: 1 }} variant="body1" className={classes.value}>
        {unhappy ?? "Na"}
      </Typography>
      <Typography
        style={{ flex: 1 }}
        variant="body1"
        className={classes.unhappy}
      >
        {"Unhappy"}
      </Typography>
    </div>
  );
};

const CoCustomerReviewsCard = (props) => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 2 }}>
        <CoElevatedPanel isLoading={false} hasHorizontalMargin={false}>
          <div style={{ display: "flex" }}>
            <DelightBox classes={classes} {...props} />
            <Divider />
            <UnhappyBox classes={classes} {...props} />
          </div>
        </CoElevatedPanel>
      </div>
      <div style={{ width: "14px" }} />
      <div style={{ flex: 1 }}>
        <CoElevatedPanel isLoading={false}>
          <RatingBox classes={classes} {...props} />
        </CoElevatedPanel>
      </div>
    </div>
  );
};

export default CoCustomerReviewsCard;
