import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const withHistoryObject = (WrappedComponent) => (props) => {
  let history = useHistory();
  let location = useLocation();

  const pushPath = (path, prop = undefined) => {
    history.push({ pathname: path, state: prop });
  };

  const replacePath = (path, prop = undefined) => {
    const { search = undefined, ...otherProp } = prop ?? {};
    history.replace({ pathname: path, state: otherProp, search: search });
  };

  return (
    <WrappedComponent
      {...props}
      location={location}
      pushPath={pushPath}
      replacePath={replacePath}
    />
  );
};

export default withHistoryObject;
